// General
export const LOADER = 'LOADER';
export const UPDATE_INDUSTRIES = 'UPDATE_INDUSTRIES';
export const UPDATE_SENIORITY = 'UPDATE_SENIORITY';
export const UPDATE_FUNCTIONS = 'UPDATE_FUNCTIONS';
export const UPDATE_LANGUAGES = 'UPDATE_LANGUAGES';
export const UPDATE_SERVICES = 'UPDATE_SERVICES';
export const UPDATE_CLIENTCOMMUNITYRESULTS = 'UPDATE_CLIENTCOMMUNITYRESULTS';
export const UPDATE_ALLAPPOINTMENTS = 'UPDATE_ALLAPPOINTMENTS';
export const UPDATE_CLIENTPAYMENTS = 'UPDATE_CLIENTPAYMENTS';
export const UPDATE_BALANCES = 'UPDATE_BALANCES';
export const UPDATE_COMMENTSANDRATES = 'UPDATE_COMMENTSANDRATES';
export const UPDATE_EXPERTURLSTRIPE = 'UPDATE_EXPERTURLSTRIPE';
export const UPDATE_SEARCHSKILLSARRAY = 'UPDATE_SEARCHSKILLSARRAY';
// Login
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_TYPE = 'LOGIN_TYPE';
export const USER_VALID = 'USER_VALID';
// Profile
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
// Client Find View
export const CLIENT_FIND_DATA = 'CLIENT_FIND_DATA';
// Client Search
export const SET_HISTORY_SEARCH = 'SET_HISTORY_SEARCH';
export const CLIENT_STRIPE_DATA = 'CLIENT_STRIPE_DATA';
// Billing form
export const BILLING = 'BILLING';
export const TAXES = 'TAXES';
// Services prices
export const EXPRESS_PRICE = 57;
export const MENTORING_PRICE = 107;
export const COACHING_PRICE = 107;
