import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

const useFormatDate = () => {
  const { i18n } = useTranslation();

  const convertToUTCDate = (date: string) => DateTime.fromJSDate(new Date(date)).toUTC().toISO();

  const convertDate = (date: string, convertFunction: number) => DateTime.fromJSDate(new Date(date))
    .plus({ minutes: convertFunction })
    .toISO();

  const formatIsoDate = (
    date: string,
    format: string = 'DD, hh:mm a',
    local: string = i18n.language,
  ) => DateTime.fromISO(date).setLocale(local).toFormat(format);

  return { formatIsoDate, convertToUTCDate, convertDate };
};

export default useFormatDate;
