/* eslint-disable camelcase */
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonRow,
} from '@ionic/react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createOutline, trashOutline } from 'ionicons/icons';
import { Educationhistory } from '../../../interfaces/clientInterfaces';
import { AppContextState } from '../../../state/AppContext';
import ModalWrapper from '../../../components/modalWrapper/ModalWrapper';
import useUpdateUser from '../../../hooks/useUpdateUser';
import EducationHistoryModal from './EducationHistoryModal';

const EducationHistory: React.FC = () => {
  const { t } = useTranslation();
  const contextState = useContext(AppContextState);
  const { user_profile } = contextState.profile;
  const { education_histories } = user_profile;

  const { updateClient } = useUpdateUser();

  const [showModal, setshowModal] = useState<boolean>(false);
  const [educationEdition, seteducationEdition] = useState<number | undefined>(undefined);
  const [showAlert, setShowAlert] = useState<string | undefined>(undefined);

  /* istanbul ignore next */
  const closeModal = () => {
    setshowModal(false);
    seteducationEdition(undefined);
  };

  const editEducation = (position: number) => {
    seteducationEdition(position + 1);
    setshowModal(true);
  };

  /* istanbul ignore next */
  const deleteEducation = (position: string) => {
    const newProfile = { ...user_profile };
    let newEducations: Educationhistory[] = [];
    if (newProfile.education_histories) newEducations = [...newProfile.education_histories];
    newEducations.splice(parseInt(position, 10), 1);
    newProfile.education_histories = newEducations;
    updateClient(newProfile);
  };

  const renderBody = () => (
    (education_histories && education_histories.length > 0)
      ? education_histories.map((history: Educationhistory, index: number) => {
        const from = new Date(history.date_start);
        const fromYear = from.getFullYear();
        const toDate = history.date_end !== 'Present' ? new Date(history.date_end) : history.date_end;
        const toYear = history.date_end !== 'Present' ? new Date(toDate).getFullYear() : toDate;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <IonCol key={history.date_end + index} size="12" sizeMd="6" sizeLg="4" className="infoCard">
            <IonCard className="ion-no-margin">
              <IonCardHeader className="ion-no-padding">
                <IonCardSubtitle>
                  <IonButtons className="ion-justify-content-end ion-no-padding">
                    <IonButton
                      data-testid={`test-edit-${index}`}
                      onClick={() => editEducation(index)}
                    >
                      <IonIcon
                        slot="icon-only"
                        icon={createOutline}
                      />
                    </IonButton>
                    <IonButton
                      data-testid={`test-delete-${index}`}
                      onClick={() => setShowAlert(index.toString())}
                    >
                      <IonIcon
                        slot="icon-only"
                        icon={trashOutline}
                      />
                    </IonButton>
                  </IonButtons>
                </IonCardSubtitle>
                <IonCardTitle className="ion-padding-horizontal ion-no-margin">
                  {history.studies}
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <h5>{history.degree_title}</h5>
                <p className="company">{history.institution_name}</p>
                <p className="timeLapse">{`${fromYear} - ${toYear}`}</p>
              </IonCardContent>
            </IonCard>
          </IonCol>
        );
      }) : (
        <div>
          <p>{t('EMP_L1')}</p>
        </div>
      )
  );

  return (
    <>
      <div className="section-title text-center">
        <h2>{t('profileEducation')}</h2>
        <span className="title-border" />
      </div>
      <IonCard className="ion-no-margin">
        <IonCardHeader style={{ textAlign: 'right', paddingBottom: '0' }}>
          <IonButton
            data-testid="test-lang-show-modal"
            onClick={() => setshowModal(true)}
            fill="clear"
            shape="round"
          >
            <IonIcon slot="icon-only" icon={createOutline} />
          </IonButton>
        </IonCardHeader>
        <IonCardContent>
          <IonRow>{renderBody()}</IonRow>
        </IonCardContent>
      </IonCard>
      <IonAlert
        isOpen={!!showAlert}
        onDidDismiss={() => setShowAlert(undefined)}
        mode="ios"
        header={t('confirm_delete')}
        message={t('confirm_delete_body')}
        buttons={[
          {
            text: t('cancel'),
            role: 'cancel',
            handler: () => setShowAlert(undefined),
          },
          {
            text: t('accept'),
            cssClass: 'secondary',
            handler: () => deleteEducation(showAlert || ''),
          },
        ]}
      />
      {showModal && (
        <ModalWrapper isOpen={showModal} setIsOpen={() => closeModal()}>
          <EducationHistoryModal edition={educationEdition} showModal={closeModal} />
        </ModalWrapper>
      )}
    </>
  );
};

export default EducationHistory;
