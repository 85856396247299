/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import urlSlug from 'url-slug';
// images
import {
  IonBadge,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';
import logo from '../../../assets/images/logo_vertical.svg';
import useFormatDate from '../../../hooks/useFormatDate';
import { PostContent } from '../../../interfaces/strapiInterfaces';

interface ComponentProps {
  content: PostContent;
  type: string;
}

const ContentViewer: React.FC<ComponentProps> = ({ content, type }) => {
  const { t, i18n } = useTranslation();
  const { formatIsoDate } = useFormatDate();
  const { language } = i18n;
  const {
    Title_en,
    Title_es,
    Main_image,
    updated_at,
    categories,
    Content_en,
    Content_es,
    created_by,
  } = content;
  const title = language === 'es' ? Title_es : Title_en;
  const text = language === 'es' ? Content_es : Content_en;
  const image = Main_image || undefined;
  const background = image
    ? process.env.REACT_APP_STRAPI_BASE + image.formats.large.url
    : logo;
  const creator = `${t('blog_5')} ${created_by.firstname} ${
    created_by.lastname
  }`;

  return (
    <IonCard className="blog-cards">
      <Link
        to={{
          pathname: `/user/library/${urlSlug(title)}`,
          state: [content.id, type],
        }}
      >
        <img src={background} alt="post related" />
        <IonCardHeader>
          <IonCardSubtitle>
            {formatIsoDate(updated_at)}
          </IonCardSubtitle>
          <IonCardTitle>{title}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          {categories && categories.length > 0
            ? categories.map((category) => (
              <IonBadge color="secondary" className="ion-margin-end" key={category.id}>
                {category.name}
              </IonBadge>
            ))
            : null}
          <div className="excerpt">
            <p>{text}</p>
          </div>
          <div className="footer">{creator}</div>
        </IonCardContent>
      </Link>
    </IonCard>
  );
};

export default ContentViewer;
