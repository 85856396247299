/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { es, enUS } from 'date-fns/locale';
import format from 'date-fns/format';
import { DateTime } from 'luxon';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { Calendar, dateFnsLocalizer, View } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import {
  IonButton,
  IonCol, IonItem, IonLabel, IonList, IonRow,
} from '@ionic/react';
import { getMonth, getYear } from 'date-fns';
import useLoader from '../../hooks/useLoader';
import { getExpertAvailabilitiesForAppointments } from '../../services/expert';
import { ExpertAvailabilities } from '../../interfaces';
import useFormatDate from '../../hooks/useFormatDate';

interface ComponentProps {
  eventExpert: number;
  // eslint-disable-next-line no-unused-vars
  selectedAvailabilitie: (value: string) => void;
}

const RequestExpertAvailabilities: React.FC<ComponentProps> = ({
  eventExpert,
  selectedAvailabilitie,
}) => {
  const jwt = sessionStorage.getItem('jwt') || '{}';
  const locales = {
    'en-us': enUS,
    es,
  };
  const scrollTo = new Date().setHours(11, 0, 0, 0);
  const { t, i18n } = useTranslation();
  const { setLoader } = useLoader();
  const { formatIsoDate } = useFormatDate();
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });
  const messages = {
    agenda: t('agenda'),
    allDay: t('allDay'),
    month: t('month'),
    day: t('day'),
    today: t('today'),
    previous: t('previous'),
    next: t('next'),
    date: t('date'),
    noEventsInRange: t('noEventsInRange'),
    time: t('time'),
    tomorrow: t('tomorrow'),
    week: t('week'),
    work_week: t('work_week'),
    yesterday: t('yesterday'),
  };
  const [availabilities, setAvailabilities] = useState<ExpertAvailabilities[]>(
    [],
  );
  const [calendarView, setCalendarView] = useState<View>('month');
  const [calendarDate, setCalendarDate] = useState({
    month: (getMonth(new Date()) + 1).toString(),
    year: getYear(new Date()).toString(),
  });
  const [expertAvailabilititesByDay, setExpertAvailabilititesByDay] = useState([]);
  const [paginatorData, setPaginatorData] = useState({
    itemsPerPage: 5,
    page: 0,
    totalPages: 0,
  });
  const [paginatedData, setPaginatedData] = useState([]);

  const requestAvailabilities = (
    month: string = calendarDate.month,
    year: string = calendarDate.year,
  ) => {
    setLoader(true);
    const requestDate = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      1,
    ).toISOString();
    getExpertAvailabilitiesForAppointments(
      jwt,
      eventExpert.toString(),
      'm',
      requestDate,
    )
      .then(({ data }) => {
        const newEventsData = data.map((element: any) => {
          const formatted = element.date.split('-').join(',');
          const eventDate: Date = new Date(formatted);
          const startDate = new Date(eventDate).setHours(0, 0, 0, 0);
          const endDate = new Date(eventDate).setHours(23, 59, 59, 999);
          return {
            start: new Date(startDate).toISOString(),
            end: new Date(endDate).toISOString(),
            allDay: true,
          };
        });
        setAvailabilities(newEventsData);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  /* istanbul ignore next */
  const validateEvent = (event: any) => {
    const date = DateTime.fromISO(event.end);
    const daysDifference = date.diff(DateTime.now(), 'day');
    if (daysDifference.days < 0) {
      setExpertAvailabilititesByDay([]);
    } else {
      getExpertAvailabilitiesDaysFunction(event.start);
    }
  };

  /* istanbul ignore next */
  const getExpertAvailabilitiesDaysFunction = (date: string) => {
    setLoader(true);
    getExpertAvailabilitiesForAppointments(jwt, eventExpert.toString(), 'd', new Date(date).toISOString())
      .then(({ data }) => {
        setExpertAvailabilititesByDay(data);
        setLoader(false);
      })
      .catch((() => {
        setLoader(false);
      }));
  };

  /* istanbul ignore next */
  const renderPages = () => {
    if (paginatorData.totalPages > 1) {
      const newAray : number[] = [];
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < paginatorData.totalPages; index++) newAray.push(index);
      return newAray.map((position) => (
        <IonButton
          key={position}
          className="button-circular ion-no-padding"
          shape="round"
          fill={paginatorData.page === position ? 'solid' : 'outline'}
          onClick={() => {
            setPaginatorData({
              ...paginatorData,
              page: position,
            });
          }}
        >
          {position + 1}
        </IonButton>
      ));
    }
    return null;
  };

  useEffect(() => {
    if (expertAvailabilititesByDay.length <= paginatorData.itemsPerPage) {
      setPaginatedData(expertAvailabilititesByDay);
    } else {
      const from = paginatorData.page * paginatorData.itemsPerPage;
      const newArray = [...expertAvailabilititesByDay];
      setPaginatedData(newArray.splice(from, paginatorData.itemsPerPage));
    }
  }, [expertAvailabilititesByDay, paginatorData]);

  useEffect(() => {
    const newTotalPages = Math
      .ceil(expertAvailabilititesByDay.length / paginatorData.itemsPerPage);
    setPaginatorData({
      ...paginatorData,
      totalPages: newTotalPages,
      page: 0,
    });
  }, [expertAvailabilititesByDay]);

  const renderAvailableTable = () => (
    <IonList>
      <IonItem>
        <IonLabel>
          {t('Day')}
        </IonLabel>
        <IonLabel slot="end" className="text-right">
          {t('Hour')}
        </IonLabel>
      </IonItem>
      {
          paginatedData
          && (
            paginatedData.map((item: any) => (
              <IonItem
                className="clickable"
                key={item.id}
                onClick={/* istanbul ignore next */() => selectedAvailabilitie(item.time)}
              >
                <IonLabel>
                  {formatIsoDate(item.time, 'DD')}
                </IonLabel>
                <IonLabel slot="end" className="text-right">
                  {formatIsoDate(item.time, 't', 'en')}
                </IonLabel>
              </IonItem>
            ))
          )
        }
      <IonRow className="ion-justify-content-center ion-margin-top">
        {renderPages()}
      </IonRow>
    </IonList>
  );

  useEffect(() => {
    requestAvailabilities();
  }, []);

  useEffect(() => {
    requestAvailabilities();
  }, [calendarDate]);

  return (
    <IonRow id="expert-appointments-update">
      <IonCol size="12" sizeLg="5">
        <Calendar
          style={{
            width: '100%',
            maxWidth: '350px',
            height: '350px',
            margin: '0 auto',
          }}
          localizer={localizer}
          culture={i18n.language}
          defaultDate={new Date()}
          view={calendarView}
          views={['month']}
          events={availabilities}
          selectable
          messages={messages}
          scrollToTime={new Date(scrollTo)}
          onView={/* istanbul ignore next */(e) => setCalendarView(e)}
          onSelectEvent={/* istanbul ignore next */(e) => validateEvent(e)}
          onNavigate={/* istanbul ignore next */(e) => setCalendarDate({
            month: (getMonth(e) + 1).toString(),
            year: getYear(e).toString(),
          })}
        />
      </IonCol>
      <IonCol size="12" sizeLg="7">
        {expertAvailabilititesByDay.length > 0 && renderAvailableTable() }
      </IonCol>
    </IonRow>
  );
};

export default RequestExpertAvailabilities;
