import axios from 'axios';

const proxyurl = process.env.REACT_APP_PROXY_URL || '';
const uri = process.env.REACT_APP_BACKEND_BASE_URL;

const AxiosInstance = axios.create({
  baseURL: `${proxyurl + uri}`,
});

export default AxiosInstance;
