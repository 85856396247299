import {
  IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonLabel,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Languagesskill, Languages as LangType } from '../../interfaces';

interface ComponentProps {
    languagesArray: Languagesskill | undefined;
}

const Languages: React.FC<ComponentProps> = ({ languagesArray }) => {
  const { t } = useTranslation();

  const renderLanguageCard = (langs: LangType) => {
    if (langs.constructor === Object) {
      return Object.entries(langs).sort().map((level) => {
        const header = `${level[0]}Header`;
        return (
          <IonCol size="12" sizeMd="6" sizeLg="4" key={header}>
            <h3 className="text-left">{t(header)}</h3>
            <IonCard className="ion-padding-vertical ion-no-margin ion-margin-vertical">
              <IonCardContent className="languagesContainer">
                {level[1].map((element: string) => <IonLabel key={element} className="ion-text-left">{element}</IonLabel>)}
              </IonCardContent>
            </IonCard>
          </IonCol>
        );
      });
    }
    return '';
  };

  return (
    <IonGrid>
      <div className="section-title text-center">
        <h2>{t('EMP_L9')}</h2>
        <span className="title-border" />
      </div>
      <IonRow>
        { languagesArray && languagesArray.languages
          ? renderLanguageCard(languagesArray.languages)
          : (
            <div>
              <p>{t('EMP_L2')}</p>
            </div>
          ) }
      </IonRow>
    </IonGrid>
  );
};

export default Languages;
