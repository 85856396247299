import { IonIcon } from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './successModal.scss';

export interface ComponentProps {
  message?: string;
}

const Success: React.FC<ComponentProps> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <>
      <IonIcon className="successModal--Icon" icon={checkmarkCircleOutline} />
      <p>{t('globalSuccess')}</p>
      {message && <p>{message}</p>}
    </>
  );
};

export default Success;
