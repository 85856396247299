/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { IonButton, IonRow } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useLoader from '../../../hooks/useLoader';
import { AppointmentDetail } from '../../../interfaces/clientInterfaces';
import { rateUser } from '../../../services/client';
import { AppContextState } from '../../../state/AppContext';

interface ComponentProps {
  service: AppointmentDetail;
  completed: () => void;
}

const NewComments: React.FC<ComponentProps> = ({ service, completed }) => {
  const { t } = useTranslation();
  const { setLoader } = useLoader();
  const jwt = sessionStorage.getItem('jwt') || '';

  const [starRate, setStarRate] = useState<number>(0);
  const [userComment, setUserComment] = useState<string>('');
  const [canSendComment, setCanSendComment] = useState<boolean>(false);
  const contextState = useContext(AppContextState);

  const { profile } = contextState;
  const { user_profile } = profile;

  useEffect(() => {
    if (userComment.length > 0 && starRate > 0) setCanSendComment(true);
    else setCanSendComment(false);
  }, [starRate, userComment]);

  const submitComments = () => {
    const reviewerID = user_profile.type === 'Client' ? service.user_id : service.expert_id;
    const reviewerName = user_profile.type === 'Client' ? service.client.first_name : service.expert.first_name;
    const ratedUserID = user_profile.type === 'Client' ? service.expert_id : service.user_id;
    const ratedUserName = user_profile.type === 'Client' ? service.expert.first_name : service.client.first_name;
    setLoader(true);
    const review = {
      user_id: ratedUserID,
      reviewer_id: reviewerID,
      user_name: ratedUserName,
      reviewer_name: reviewerName,
      appointment_id: service.id,
      rate: starRate,
      review: userComment,
    };
    rateUser(jwt, review)
      .then(() => {
        setLoader(false);
        completed();
      })
      .catch((() => {
        setLoader(false);
        completed();
      }));
  };

  return (
    <IonRow id="new-comments-form" className="ion-justify-conten-between ion-align-items-center">
      <div className="rating">
        <input
          className="rateInput"
          type="radio"
          id="star5"
          name="rating"
          onClick={() => setStarRate(5)}
          value="5"
        />
        <label htmlFor="star5" />
        <input
          className="rateInput"
          type="radio"
          id="star4"
          name="rating"
          onClick={() => setStarRate(4)}
          value="4"
        />
        <label htmlFor="star4" />
        <input
          className="rateInput"
          type="radio"
          id="star3"
          name="rating"
          onClick={() => setStarRate(3)}
          value="3"
        />
        <label htmlFor="star3" />
        <input
          className="rateInput"
          type="radio"
          id="star2"
          name="rating"
          onClick={() => setStarRate(2)}
          value="2"
        />
        <label htmlFor="star2" />
        <input
          className="rateInput"
          type="radio"
          id="star1"
          name="rating"
          onClick={() => setStarRate(1)}
          value="1"
        />
        <label htmlFor="star1" />
      </div>
      <div className="text-center">
        <textarea
          name="videochat_comment"
          placeholder={t('DC_L16')}
          id="videochat_comment"
          onChange={(e) => setUserComment(e.target.value)}
        />
      </div>
      <IonButton
        className="ion-margin-top"
        shape="round"
        onClick={() => submitComments()}
        disabled={!canSendComment}
      >
        {t('DC_L17')}
      </IonButton>
    </IonRow>
  );
};

export default NewComments;
