import {
  IonCard, IonIcon, IonItem, IonLabel, IonList,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './language.scss';

interface ComponentProps {
  isVisible: boolean;
  // eslint-disable-next-line no-unused-vars
  langSelected: (value: string) => void;
}

const LanguageSelector: React.FC<ComponentProps> = ({ isVisible, langSelected }) => {
  const { t } = useTranslation();
  return (
    <IonCard id="lang-card" className={isVisible ? 'show' : 'hide'}>
      <IonList lines="none">
        <IonItem onClick={/* istanbul ignore next */ () => langSelected('es')}>
          <IonIcon slot="start" className="flag" src="./assets/images/mx.svg" />
          <IonLabel>
            {t('ChangeLngEs')}
          </IonLabel>
        </IonItem>
        <IonItem onClick={/* istanbul ignore next */ () => langSelected('en')}>
          <IonIcon slot="start" className="flag" src="./assets/images/ca.svg" />
          <IonLabel>
            {t('ChangeLngEn')}
          </IonLabel>
        </IonItem>
      </IonList>
    </IonCard>
  );
};

export default LanguageSelector;
