/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import {
  IonButton, IonCol, IonContent, IonRow, IonText,
} from '@ionic/react';
import useUpdateUser from '../../../hooks/useUpdateUser';
import { AppContextState } from '../../../state/AppContext';
import { Careerhighlight } from '../../../interfaces/clientInterfaces';
import useFormatDate from '../../../hooks/useFormatDate';

interface ComponentProps {
  edition: number | undefined;
  showModal: () => void;
}

const HighLightsModal: React.FC<ComponentProps> = ({ edition, showModal }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const contextState = useContext(AppContextState);
  const { profile } = contextState;
  const { user_profile } = profile;
  const { career_highlights } = user_profile;
  const { status, updateClient } = useUpdateUser();
  const { formatIsoDate } = useFormatDate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const careerBase: Careerhighlight = {
    career_header: '',
    career_description: '',
    date_from: '',
    date_to: '',
  };
  const [state, setState] = useState({
    editCareer: false,
    currentCareer: false,
    presentCheck: true,
    textLenght: 0,
    career: careerBase,
  });

  useEffect(() => {
    if (
      edition !== undefined
      && career_highlights
      && career_highlights.length > 0
    ) {
      const editHigh = career_highlights[edition];
      const presentCheck = editHigh.date_to === null;
      const description = editHigh.career_description;
      const textLenght = description ? description.length : 0;
      setValue('career_header', editHigh.career_header);
      setValue('career_description', editHigh.career_description);
      setValue('date_from', editHigh.date_from);
      setValue('date_to', editHigh.date_to);
      setState({
        ...state,
        editCareer: !edition,
        textLenght,
        career: editHigh,
        presentCheck,
      });
    }
  }, [edition, career_highlights]);

  useEffect(() => {
    if (status || status === false) showModal();
  }, [status]);

  /* istanbul ignore next */
  const entryUpdateDate = (event: any, value: 'date_from' | 'date_to') => {
    const newState = state;
    if (value === 'date_to') {
      newState.presentCheck = false;
    }
    newState.career[value] = new Date(event).toISOString();
    setState({ ...newState });
  };

  /* istanbul ignore next */
  const onSubmit = (data: any) => {
    const newState = { ...data };
    newState.date_from = state.career.date_from || new Date().toISOString();
    if (state.presentCheck) {
      newState.date_to = null;
    } else {
      newState.date_to = state.career.date_to || new Date().toISOString();
    }
    const newProfile = { ...user_profile };
    const newHighs = [...(newProfile.career_highlights || [])];
    if (state.editCareer && edition !== undefined) {
      newHighs[edition] = { ...newState };
    } else newHighs.push(newState);
    newProfile.career_highlights = newHighs;
    updateClient(newProfile);
  };

  const { career } = state;
  const {
    career_description, career_header,
  } = career;
  return (
    <IonContent>
      <form
        className="stepComponent ion-padding"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <h2>{t('profileHighlights')}</h2>
        <IonRow>
          <IonCol size="12">
            <div className="form__group field ion-margin-top">
              <input
                type="text"
                className={`form__field ${
                  errors.career_header ? ' invalid ' : ''
                }`}
                {...register('career_header', { required: true })}
                aria-describedby="helpId"
                defaultValue={career_header}
              />
              <label className="form__label" htmlFor="career_header">
                {t('step7Header')}
              </label>
            </div>
            {errors.career_header
              && errors.career_header.type === 'required' && (
                <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
            )}
            <div className="form__group field ion-margin-top">
              <textarea
                className={`form__field ${
                  errors.career_description ? ' invalid ' : ''
                }`}
                {...register('career_description', {
                  required: true,
                  minLength: 250,
                })}
                rows={4}
                maxLength={500}
                defaultValue={career_description}
                onChange={(e) => {
                  setState({ ...state, textLenght: e.target.value.length });
                }}
              />
              <label className="form__label" htmlFor="career_description">
                {t('step7Text')}
              </label>
              {errors.career_description
                && errors.career_description.type === 'required' && (
                  <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
              )}
              {errors.career_description
                && errors.career_description.type === 'minLength' && (
                  <IonText color="danger">
                    250
                    {t('minimunChars')}
                  </IonText>
              )}
              <p className="text-right">
                <IonText color="primary">
                  250&nbsp;
                  {t('minimunChars')}
                </IonText>
                &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
                <IonText color="tertiary">
                  {state.textLenght}
                  &nbsp;
                  {t('charsOf')}
                  &nbsp;500
                </IonText>
              </p>
            </div>
            <IonRow>
              <IonCol size="12" sizeLg="6" className="col-12 col-md-5">
                <div className="form__group field ion-margin-top">
                  <input
                    type="text"
                    className={`form__field ${
                      errors.date_from ? ' invalid ' : ''
                    }`}
                    {...register('date_from', {
                      required: true,
                      minLength: 2,
                    })}
                    value={formatIsoDate(
                      state.career.date_from || new Date().toISOString(),
                      'LLLL yyyy',
                    )}
                  />
                  <Calendar
                    maxDate={new Date()}
                    locale={language}
                    maxDetail="month"
                    onChange={(e: any) => entryUpdateDate(e, 'date_from')}
                    defaultValue={
                        state.career.date_from
                          ? new Date(state.career.date_from)
                          : new Date()
                      }
                  />
                  <label className="form__label" htmlFor="date_from">
                    {t('step3From')}
                  </label>
                </div>
                {errors.date_from && errors.date_from.type === 'required' && (
                  <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
                )}
              </IonCol>
              <IonCol size="12" sizeLg="6" className="col-12 col-md-5">
                <div className="form__group field ion-margin-top">
                  <input
                    type="text"
                    className={`form__field ${
                      errors.date_to ? ' invalid ' : ''
                    }`}
                    {...register('date_to', {
                      required: !!state.presentCheck,
                    })}
                    value={formatIsoDate(
                      state.career.date_to || new Date().toISOString(),
                      'LLLL yyyy',
                    )}
                  />
                  <Calendar
                    maxDate={new Date()}
                    locale={language}
                    maxDetail="month"
                    onChange={(e: any) => entryUpdateDate(e, 'date_to')}
                    defaultValue={
                        state.career.date_to
                          ? new Date(state.career.date_to)
                          : new Date()
                      }
                  />
                  <label className="form__label" htmlFor="date_to">
                    {t('step3To')}
                  </label>
                </div>
              </IonCol>
              <IonCol size="12" className="form-check">
                <label htmlFor="present" className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="present"
                    id="present"
                    value="checkedValue"
                    onChange={() => {
                      setState({ ...state, presentCheck: !state.presentCheck });
                    }}
                    checked={state.presentCheck}
                  />
                  {t('presentText')}
                </label>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonButton
          data-testid="test-submit"
          type="submit"
          shape="round"
          fill="outline"
          className="ion-margin-vertical"
        >
          {t('stepSave')}
        </IonButton>
      </form>
    </IonContent>
  );
};

export default HighLightsModal;
