import { Userprofile } from '../interfaces';

const expertProfileComplete = (userProfile: Userprofile) => {
  let percent = 0;
  if (userProfile) {
    if (userProfile.about.aboutBody) percent += 1;
    if (userProfile.resume_url) percent += 1;
    if (
      userProfile.career_highlights
      && userProfile.career_highlights.length > 0
    ) percent += 1;
    if (
      userProfile.contact_information
      && userProfile.contact_information.location
    ) percent += 1;
    if (
      userProfile.education_histories
      && userProfile.education_histories.length > 0
    ) percent += 1;
    if (
      userProfile.languages_skill
      && userProfile.languages_skill.languages.native.length > 0
    ) percent += 1;
    if (userProfile.services && userProfile.services.length > 0) percent += 1;
    if (userProfile.skills && userProfile.skills.length > 0) percent += 1;
    if (userProfile.work_histories && userProfile.work_histories.length > 0) percent += 1;
  }
  return Math.floor((percent * 100) / 9);
};

export default expertProfileComplete;
