/* eslint-disable camelcase */
import { IonButton } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorModal from '../../../components/error/ErrorModal';
import ModalWrapper from '../../../components/modalWrapper/ModalWrapper';
import useUpdateUser from '../../../hooks/useUpdateUser';
import { AppContextState } from '../../../state/AppContext';
import SubmitModal from './SubmitModal';

const SubmitCompleted: React.FC = () => {
  const { t } = useTranslation();
  const contextState = useContext(AppContextState);
  const { user_profile } = contextState.profile;

  const [showModal, setshowModal] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const { status, updateClient } = useUpdateUser();

  const submitExpertProfile = () => {
    const newProfile = { ...user_profile };
    newProfile.completed_onboarding = true;
    updateClient(newProfile);
  };

  useEffect(() => {
    if (status) setshowModal(true);
    if (status === false) setShowError(true);
  }, [status]);

  return (
    <>
      {user_profile.completed_onboarding !== true ? (
        <IonButton
          data-testid="test-complete-button"
          style={{ margin: '1rem auto' }}
          shape="round"
          fill="solid"
          onClick={() => submitExpertProfile()}
        >
          {t('profileExpertSubmit')}
        </IonButton>
      ) : null}
      {showModal
        && (
        <ModalWrapper isOpen={showModal} setIsOpen={() => setshowModal(false)}>
          <SubmitModal name={user_profile.first_name} />
        </ModalWrapper>
        )}
      {showError
        && (
        <ModalWrapper wrapperClass="ErrorModal" isOpen={showError} setIsOpen={() => setShowError(false)}>
          <ErrorModal />
        </ModalWrapper>
        )}
    </>
  );
};

export default SubmitCompleted;
