/* eslint-disable no-param-reassign */
/* eslint-enable react/prop-types */
import { IonIcon, IonImg } from '@ionic/react';
import React from 'react';

export interface ImageProps {
  imageName: string;
  extension: string;
  slot?: string;
  classN?: string;
}

const ImageHandler: React.FC<ImageProps> = ({
  imageName, extension, classN = '', slot = 'start',
}) => {
  const errorImage = '../../assets/images/logo.png';
  const imageSrc = `./assets/images/${imageName}.${extension}`;
  const handleError = /* istanbul ignore next */ (event: any) => {
    event.target.src = errorImage;
  };
  if (extension === 'svg') {
    return <IonIcon className={classN} slot={slot} src={imageSrc} />;
  }
  return <IonImg data-testid="test-ion-image" className={classN} src={imageSrc} onIonError={/* istanbul ignore next */ (event) => handleError(event)} />;
};

export default ImageHandler;
