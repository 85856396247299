import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentDetail } from '../../../interfaces/clientInterfaces';

interface ComponentProps {
  service: AppointmentDetail;
}

const SessionRequests: React.FC<ComponentProps> = ({ service }) => {
  const { t } = useTranslation();
  const questions = service.service_request;
  let questionTitle = '';
  const questionLength = Object.entries(questions).length;
  let columnSizeLg: string;
  if (questionLength === 1) columnSizeLg = '12';
  else if (questionLength === 2) columnSizeLg = '6';
  else columnSizeLg = '4';
  return (
    <IonRow>
      {Object.entries(questions).reverse().map((element, index) => {
        if (element[0] === 'needMentory') questionTitle = 'needMentory';
        else if (element[0] === 'searchMentory') questionTitle = 'searchMentory';
        else if (element[0] === 'searchExpressAdvice') questionTitle = 'searchExpressAdvice';
        else if (element[0] === 'coachType') questionTitle = 'coachType';
        else if (element[0] === 'coachAchives') questionTitle = 'coachAchives';
        else if (element[0] === 'coachChanges') questionTitle = 'coachChanges';
        else if (element[0] === 'searchCoaching') questionTitle = 'searchCoaching';
        return (
        // eslint-disable-next-line react/no-array-index-key
          <IonCol size="12" sizeLg={columnSizeLg} key={index} className="ion-no-padding">
            <IonCard className="questions_answers ion-padding">
              <IonCardHeader className="section-title ion-text-start">
                <h2 style={{ padding: '0' }}>{t(questionTitle)}</h2>
                <span className="title-border" />
              </IonCardHeader>
              <IonCardContent className="ion-text-start">
                {element[1]}
              </IonCardContent>
            </IonCard>
          </IonCol>
        );
      })}
    </IonRow>
  );
};

export default SessionRequests;
