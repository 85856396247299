import { IonIcon } from '@ionic/react';
import React from 'react';
import { closeCircleOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import './errorModal.scss';

export interface ComponentProps {
  error?: string;
}

const ErrorModal: React.FC<ComponentProps> = ({ error }) => {
  const { t } = useTranslation();
  return (
    <>
      <IonIcon className="ErrorModal--Icon" icon={closeCircleOutline} />
      <h3>Error</h3>
      <p>{t('globalError')}</p>
      {error && <p>{error}</p>}
    </>
  );
};

export default ErrorModal;
