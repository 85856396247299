import {
  IonGrid, IonRow, IonCol,
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useBaseInformation from '../../hooks/useBaseInformation';
import About from './components/About';
import Billing from './components/Billing';
import EducationHistory from './components/EducationHistory';
import ExpertContactInfo from './components/ExpertContactInfo';
import HighLights from './components/HighLights';
import Languages from './components/Languages';
import ProfileHeader from './components/ProfileHeader';
import Resume from './components/Resume';
import Services from './components/Services';
import Skills from './components/Skills';
import Stripe from './components/Stripe';
import SubmitCompleted from './components/SubmitCompleted';
import WorkExperience from './components/WorkExperience';

const ExpertProfile: React.FC = () => {
  const { t } = useTranslation();

  const [validateBaseInformation] = useBaseInformation();

  useEffect(() => {
    validateBaseInformation();
  }, []);

  return (
    <div id="dashboard">
      <h1>{t('profileWelcome')}</h1>
      <ProfileHeader />
      <ExpertContactInfo />
      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeLg="5">
            <About />
          </IonCol>
          <IonCol size="12" sizeLg="7">
            <Services />
          </IonCol>
          <IonCol size="12" sizeLg="12">
            <Resume />
          </IonCol>
          <IonCol size="12" sizeLg="12">
            <EducationHistory />
          </IonCol>
          <IonCol size="12" sizeLg="12">
            <Languages />
          </IonCol>
          <IonCol size="12" sizeLg="12">
            <Skills />
          </IonCol>
          <IonCol size="12" sizeLg="12">
            <WorkExperience />
          </IonCol>
          <IonCol size="12" sizeLg="12">
            <HighLights />
          </IonCol>
          <IonCol size="12" sizeLg="6">
            <Billing />
          </IonCol>
          <IonCol size="12" sizeLg="6">
            <Stripe />
          </IonCol>
          <IonCol>
            <IonRow className="ion-justify-content-center ion-margin-top">
              <SubmitCompleted />
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ExpertProfile;
