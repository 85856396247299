/* eslint-disable camelcase */
import { IonIcon } from '@ionic/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import stripeImage from '../../assets/images/powered_by_stripe.svg';
import { AppContextState } from '../../state/AppContext';
import './lastPay.scss';

const LastPayInfo: React.FC = () => {
  const { t } = useTranslation();
  const contextState = useContext(AppContextState);
  const { last_payment_info } = contextState.profile.user_profile;

  if (!last_payment_info) return null;

  return (
    <div className="lastPaymentInformation">
      <IonIcon
        className="stripeLogo"
        icon={stripeImage}
      />
      <h5>{t('creditExtraInformation')}</h5>
      <p>
        <span>{t('cardBrand')}</span>
        {last_payment_info.brand}
      </p>
      <p>
        <span>{t('CardLast4')}</span>
        {last_payment_info.card_last4}
      </p>
      <p>
        <span>{t('CardExpMonth')}</span>
        {last_payment_info.exp_month}
      </p>
      <p>
        <span>{t('CardExpYear')}</span>
        {last_payment_info.exp_year}
      </p>
    </div>
  );
};

export default LastPayInfo;
