import { useContext, useState } from 'react';
import { LOADER, UPDATE_USER_PROFILE } from '../constants/constants';
import { General, Userprofile } from '../interfaces';
import { updateUserProfile as updateClientProfile } from '../services/client';
import { updateUserProfile as updateExpertProfile } from '../services/expert';
import { AppContextDispatch, AppContextState } from '../state/AppContext';

const useUpdateUser = () => {
  const contextDispatch = useContext(AppContextDispatch);
  const contextState = useContext(AppContextState);
  const [status, setStatus] = useState<boolean | undefined>(undefined);

  const { type } = contextState.profile.user_profile;

  const dispatchFunction = (value: Userprofile) => {
    const payload = {
      profile: {
        user_profile: value,
      },
    };
    contextDispatch({
      type: UPDATE_USER_PROFILE,
      payload,
    });
  };

  const dispatchLoader = (value: boolean) => {
    const payload: General = {
      loader: value,
    };
    contextDispatch({
      type: LOADER,
      payload,
    });
  };

  const updateClient = async (profile: Userprofile) => {
    setStatus(undefined);
    dispatchLoader(true);
    const jwt = sessionStorage.getItem('jwt') || '';
    if (type === 'Client') {
      await updateClientProfile(profile, jwt).then(({ data }) => {
        dispatchFunction(data);
        setStatus(true);
        dispatchLoader(false);
      }).catch(() => {
        setStatus(false);
        dispatchLoader(false);
      });
    } else {
      await updateExpertProfile(profile, jwt).then(({ data }) => {
        dispatchFunction(data);
        setStatus(true);
        dispatchLoader(false);
      }).catch(() => {
        setStatus(false);
        dispatchLoader(false);
      });
    }
  };

  return { status, updateClient };
};

export default useUpdateUser;
