import React, { useState, useEffect } from 'react';
// hooks
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// services
import {
  retiveGeneralById,
  retiveContentByUserById,
} from '../../../services/strapi';
// components
import BlogContent from './BlogContent';
import { PostContent } from '../../../interfaces/strapiInterfaces';

const BlogView: React.FC = () => {
  const location = useLocation();
  const [find, setFind] = useState<any[] | undefined>(undefined);
  const { t } = useTranslation();
  const [strapicontent, setStrapiContent] = useState<PostContent | undefined>(undefined);
  const [error, setError] = useState(undefined);

  const validateLocationState = () => {
    if (location.state) {
      setFind(location.state as any[]);
    }
  };

  const findContent = () => {
    if (find && find.length > 1) {
      if (find[1] === 'general') {
        retiveGeneralById(find[0])
          .then(({ data }) => {
            setStrapiContent(data);
          })
          .catch((err) => console.log(err));
      } else {
        retiveContentByUserById(find[1], find[0])
          .then(({ data }) => {
            setStrapiContent(data);
          })
          .catch((err) => console.log(err));
      }
    } else {
      setError(t('DC_L22'));
    }
  };

  useEffect(() => {
    if (!find) validateLocationState();
  }, [find]);

  if (find && strapicontent === undefined) {
    findContent();
  }

  return (
    <div className="blog-view">
      {error && error}
      {strapicontent && <BlogContent content={strapicontent} />}
    </div>
  );
};

export default BlogView;
