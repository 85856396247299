import { State } from '../interfaces';

const initialState: State = {
  searchSkillsArray: undefined,
  industries: undefined,
  seniority: undefined,
  functions: undefined,
  languages: undefined,
  services: undefined,
  allAppointments: undefined,
  clientCommunityResults: undefined,
  clientPayments: undefined,
  commentsAndRates: undefined,
  expertUrlStripe: undefined,
  login: {
    user_auth: '',
    login: false,
    login_type: {
      work_histories: {},
      active: false,
      provider: '',
      facebook_uid: null,
      linkedin_uid: null,
      first_name: '',
      last_name: '',
      email: '',
      id: '',
      picture: {
        height: 0,
        is_silhouette: false,
        url: '',
        width: 0,
      },
      token: '',
      user_type: '',
    },
  },
  profile: {
    user_profile: {
      id: 0,
      uuid: '',
      mwp_uuid: '',
      type: '',
      facebook_uid: '',
      linkedin_uid: null,
      first_name: '',
      last_name: '',
      active: false,
      email: '',
      job: null,
      company: null,
      avatar: {
        url: '',
      },
      provider: '',
      review_rate: 0,
      about: {
      },
      languages_skill: {
        languages: {
          fluent: [],
          native: [],
          learning: [],
        },
      },
      last_payment_info: {
        brand: '',
        card_last4: '',
        exp_month: '',
        exp_year: '',
      },
      created_at: '',
      updated_at: '',
      billing_info: {
        id: 0,
        name: '',
        email: '',
        address_line1: '',
        address_line2: '',
        city: '',
        country: '',
        state: '',
        zip_code: '',
        rfc_nas: '',
        phone: '',
        user_id: 0,
        created_at: '',
        updated_at: '',
      },
      time_zone: '',
      timezone_id: 0,
      work_histories: [
        {
          to: '',
          from: '',
          title: '',
          company: '',
          location: '',
          job_title: '',
          seniority: '',
          company_size: '',
          industry_field: '',
          company_website: '',
        },
      ],
      completed_onboarding: false,
    },
  },
  general: {
    loader: false,
  },
  billing: {
    billing: {
      id: 0,
      name: '',
      email: '',
      address_line1: '',
      address_line2: '',
      city: '',
      country: '',
      state: '',
      zip_code: '',
      rfc_nas: '',
      phone: '',
      user_id: 0,
      created_at: '',
      updated_at: '',
    },
  },
  client: {
    client_find_data: {
      job_function: ['Marketing Tools and Automation'],
      skills: ['UX/UI Design'],
      industry: ['Accounting'],
      seniority: '0',
      language: 'en',
      service_name: 'ExpressAdvice',
      service: 'expressCheck',
      searchExpressAdvice: '',
    },
    set_history_search: {
      id: 0,
      user_id: 0,
      created_at: '',
      updated_at: '',
      first_available: null,
      service: '',
      functions: [],
      skills: [],
      seniority_levels: [],
      coaching_type: '',
      industry: [],
      description: '',
      achieve_experiences: '',
      what_changes: '',
    },
  },
  taxes: {},
  form: {
    billing: {
      values: {
        id: 0,
        name: '',
        email: '',
        address_line1: '',
        address_line2: '',
        city: '',
        country: '',
        state: '',
        zip_code: '',
        rfc_nas: '',
        phone: '',
        user_id: 0,
        created_at: '',
        updated_at: '',
      },
      initial: {
        id: 0,
        name: '',
        email: '',
        address_line1: '',
        address_line2: '',
        city: '',
        country: '',
        state: '',
        zip_code: '',
        rfc_nas: '',
        phone: '',
        user_id: 0,
        created_at: '',
        updated_at: '',
      },
      registeredFields: {
        name: {
          name: 'name',
          type: 'Field',
          count: 1,
        },
        phone: {
          name: 'phone',
          type: 'Field',
          count: 1,
        },
        email: {
          name: 'email',
          type: 'Field',
          count: 1,
        },
        rfc_nas: {
          name: 'rfc_nas',
          type: 'Field',
          count: 1,
        },
        country: {
          name: 'country',
          type: 'Field',
          count: 1,
        },
        state: {
          name: 'state',
          type: 'Field',
          count: 1,
        },
        city: {
          name: 'city',
          type: 'Field',
          count: 1,
        },
        address_line1: {
          name: 'address_line1',
          type: 'Field',
          count: 1,
        },
        address_line2: {
          name: 'address_line2',
          type: 'Field',
          count: 1,
        },
        zip_code: {
          name: 'zip_code',
          type: 'Field',
          count: 1,
        },
      },
    },
  },
};

export default initialState;
