/* eslint-disable camelcase */
import { useContext } from 'react';
import { ClientFindData, Sethistorysearch } from '../interfaces';
import { searchExpert, setSearchHistories } from '../services/client';
import { AppContextDispatch } from '../state/AppContext';

const useClientSearch = () => {
  const jwt: string = sessionStorage.getItem('jwt') || '';
  const contextDispatch = useContext(AppContextDispatch);

  const dispatchFunction = (type: string, value: any) => {
    const payload: object = {
      [type]: value,
    };
    contextDispatch({
      type: type.toUpperCase(),
      payload,
    });
  };

  const newSearch = async (search: ClientFindData) => searchExpert(search, jwt)
    .then(({ data }) => {
      dispatchFunction('client_find_data', search);
      return data;
    }).catch(() => []);

  const newHistorySearch = async (searchHistory: Sethistorysearch) => (
    setSearchHistories(jwt, searchHistory)
      .then(({ data }) => {
        dispatchFunction('set_history_search', searchHistory);
        return data;
      }).catch(() => [])
  );

  return { newSearch, newHistorySearch };
};

export default useClientSearch;
