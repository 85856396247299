export const industries = [
  'Accounting',
  'Advanced Materials',
  'Agriculture',
  'Airlines/Aviation',
  'Apparel & Fashion',
  'Architecture & Planning',
  'Automotive',
  'Aviation & Aerospace',
  'Banking & Financial Services',
  'Biotechnology',
  'Broadcast Media',
  'Capital Markets',
  'Chemicals',
  'Civic & Social Organization',
  'Civil Engineering',
  'Coaching',
  'Commercial Real Estate',
  'Computer & Network Security',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Construction',
  'Consulting Services',
  'Consumer Electronics',
  'Consumer Products & Goods',
  'Consumer Services',
  'Cosmetics',
  'Crypto-Currency',
  'Cybersecurity',
  'Data Analytics & Performance Technology',
  'Defense & Space',
  'Design',
  'Distribution and Logistics',
  'eCommerce',
  'Education',
  'E-Learning',
  'e-Marketing',
  'Energy (Non-renewable)',
  'Energy (Renewable)',
  'Engineering',
  'Enterprise Software',
  'Entertainment',
  'Environmental Services',
  'Financial Services',
  'Food & Beverages',
  'Fundraising',
  'Furniture',
  'Government Administration',
  'Government Relations',
  'Graphic Design',
  'Green Buildings',
  'Health, Wellness and Fitness',
  'Healthcare',
  'Human Resources',
  'Industrial Automation',
  'Information Services',
  'Information Technology and Services',
  'Insurance',
  'International Affairs',
  'International Trade and Development',
  'Internet',
  'Investment Banking',
  'Investment Management',
  'Legal Services',
  'Leisure, Travel & Tourism',
  'Logistics and Supply Chain',
  'Machinery',
  'Management Consulting',
  'Manufacturing',
  'Market Research',
  'Marketing and Advertising',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Devices & Diagnostics',
  'Medical Practice',
  'Military',
  'Mining, Minerals & Metals',
  'Mobile Commerce',
  'Music',
  'Nanotechnology',
  'Newspapers',
  'Non-Profit Organization',
  'Not-For-Profit',
  'Online Media',
  'Packaging and Containers',
  'Paper & Forest Products',
  'Payments',
  'Performing Arts',
  'Petrochemical',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Private Equity',
  'Professional Training',
  'Program Development',
  'Public Policy',
  'Public Relations and Communications',
  'Public Safety',
  'Publishing',
  'Real Estate',
  'Recreational Facilities and Services',
  'Recycling & Waste',
  'Religious Institutions',
  'Remittance',
  'Research',
  'Restaurants',
  'Retail',
  'Retail Enterprise Technology',
  'Security and Investigations',
  'Sporting Goods',
  'Sports',
  'Staffing and RecruitingA226',
  'Supermarkets',
  'Technology (Biotech)',
  'Technology (Hardware)',
  'Technology (Services)',
  'Technology (Software)',
  'Telecommunications',
  'Venture Capital & Private Equity',
  'Warehousing',
  'Water & Wastewater',
  'Wealth Management',
  'Wholesale',
  'Wine and Spirits',
  'Wireless',
  'Writing and Editing',
];

export const industrieSizes = [
  'Small 1 to 100 Employees',
  'Medium 101 to 1000 Employees',
  'Large 1001 to 5000 Employees',
  'Global 5001 and presence in a at least 2 continents',
];

export const studies = [
  'GED',
  'High School',
  'Associates',
  'Bachelors',
  'Postgradute',
  'Masters',
  'Doctorate',
  'Diploma',
  'Nor Specified',
];
