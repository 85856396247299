/* eslint-disable no-restricted-globals */
export const capitalizeString = (string: string): string => {
  // eslint-disable-next-line no-param-reassign
  string = string.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const userProfileImage = (type: string, userUid: string | number) => `https://s3.us-east-2.amazonaws.com/${
  process.env.REACT_APP_S3_BUCKET
}/uploads/${type.toLowerCase()}/avatar/${userUid}/0.jpeg`;

export const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      const response = c.substring(name.length, c.length);
      return response;
    }
  }
  return undefined;
};

export const validateUserBrowser = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  // const isIOSChrome = winNav.userAgent.match('CriOS');

  return (
    isChromium !== null
    && typeof isChromium !== 'undefined'
    && vendorName === 'Google Inc.'
    && isOpera === false
    && isIEedge === false
  );
};

export const scrollToElement = (target: string) => {
  const targetElement = document.getElementById(target);
  targetElement?.scrollIntoView({ block: 'start', behavior: 'smooth' });
};

export const slugifyFilter = (indus: string, skll: string) => `/user/${indus.replace(/\//g, '_').replace(/\s+/g, '-')}/${skll
  .replace(/\//g, '_')
  .replace(/\s+/g, '-')}`;

export const welcomeUser = (lang: string) => {
  if (lang === 'en') location.replace('https://mindswithpurpose.net/welcome.html');
  else location.replace('https://mindswithpurpose.net/bienvenido.html');
};
