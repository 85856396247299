import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  Categories,
  HomePage,
  PostContent,
  ServicesExperts,
} from '../interfaces/strapiInterfaces';

const proxyurl = '';
const uri = process.env.REACT_APP_STRAPI_BASE;

const AxiosInstance = axios.create({
  baseURL: `${proxyurl + uri}`,
});

export const retiveCategories = async (): Promise<
  AxiosResponse<Categories[]>
> => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/categories', fetchInformation);
};

export const retiveGenerals = async (): Promise<
  AxiosResponse<PostContent[]>
> => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/generals', fetchInformation);
};

export const retiveGeneralById = async (
  id: string | number,
): Promise<AxiosResponse<PostContent>> => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(`/generals/${id}`, fetchInformation);
};

export const retiveContentByUser = async (
  userType: string,
): Promise<AxiosResponse<PostContent[]>> => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(`/${userType}`, fetchInformation);
};

export const retiveContentByUserById = async (
  userType: string,
  id: string | number,
): Promise<AxiosResponse<PostContent>> => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(`/${userType}/${id}`, fetchInformation);
};

export const retivePages = async () => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/pages', fetchInformation);
};

export const retriveServices = async (): Promise<AxiosResponse<ServicesExperts[]>> => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/services-experts', fetchInformation);
};

export const retivePageById = async (
  id: string | number,
): Promise<AxiosResponse<HomePage>> => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(`/pages/${id}`, fetchInformation);
};
