/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Calendar from 'react-calendar';
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonRow,
  IonText,
} from '@ionic/react';
import useUpdateUser from '../../../hooks/useUpdateUser';
import { AppContextState } from '../../../state/AppContext';
import { capitalizeString } from '../../../utils';
import { studies as studiesArray } from '../../../constants/industries';
import useFormatDate from '../../../hooks/useFormatDate';

interface ComponentProps {
  edition: number | undefined;
  showModal: () => void;
}

interface StateProps {
  editStudie: undefined | number;
  presentCheck: boolean;
  studiesObject: {
    studies: undefined | string;
    degree_title: undefined | string;
    institution_name: undefined | string;
    date_start: undefined | string;
    date_end: undefined | string;
  };
}

const EducationHistoryModal: React.FC<ComponentProps> = ({
  edition,
  showModal,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const contextState = useContext(AppContextState);

  const { status, updateClient } = useUpdateUser();
  const { profile } = contextState;
  const { user_profile } = profile;
  const { education_histories } = user_profile;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { formatIsoDate } = useFormatDate();

  const [studiesdata, setStudies] = useState<StateProps>({
    editStudie: undefined,
    presentCheck: true,
    studiesObject: {
      studies: undefined,
      degree_title: undefined,
      institution_name: undefined,
      date_start: undefined,
      date_end: undefined,
    },
  });

  useEffect(() => {
    if (edition && education_histories) {
      const educationToEdit = education_histories[edition - 1];
      const presentCheck = educationToEdit.date_end === 'Present';
      setStudies({
        ...studiesdata,
        editStudie: edition,
        studiesObject: educationToEdit,
        presentCheck,
      });
      setValue(
        'date_end',
        formatIsoDate(educationToEdit.date_end, 'LLLL yyyy'),
      );
      setValue(
        'date_start',
        formatIsoDate(educationToEdit.date_start, 'LLLL yyyy'),
      );
      setValue('degree_title', educationToEdit.degree_title);
      setValue('institution_name', educationToEdit.institution_name);
      setValue('studies', educationToEdit.studies);
    }
  }, [edition, education_histories]);

  useEffect(() => {
    if (status || status === false) showModal();
  }, [status]);

  const renderUserStudies = () => (
    <>
      <option value="">{t('selectDefault')}</option>
      {studiesArray.map((element) => (
        <option key={element} value={element}>
          {capitalizeString(element)}
        </option>
      ))}
    </>
  );

  /* istanbul ignore next */
  const entryUpdateDate = (event: Date, value: 'date_end' | 'date_start') => {
    const newState = { ...studiesdata };
    if (value === 'date_end') {
      newState.presentCheck = false;
    }
    newState.studiesObject[value] = new Date(event).toISOString();
    setStudies({ ...newState });
  };

  const onSubmit = (data: any) => {
    const {
      degree_title,
      institution_name,
      studies,
    } = data;
    const start = studiesdata.studiesObject.date_start || new Date().toISOString();
    let end;
    if (studiesdata.presentCheck) {
      end = 'Present';
    } else {
      end = studiesdata.studiesObject.date_end || new Date().toISOString();
    }
    const newState = {
      date_start: start,
      date_end: end,
      degree_title,
      institution_name,
      studies,
    };
    const newProfile = { ...user_profile };
    const newEducations = [...(newProfile.education_histories || [])];
    if (studiesdata.editStudie !== undefined && edition) {
      newEducations[edition - 1] = { ...newState };
    } else newEducations.push(newState);
    newProfile.education_histories = newEducations;
    updateClient(newProfile);
  };

  const { studiesObject } = studiesdata;

  return (
    <IonCard
      className="ion-padding ion-no-margin"
      style={{ width: '100%', height: '100%' }}
    >
      <IonContent>
        <IonCardHeader>
          <IonCardTitle>
            <h2>{t('profileEducation')}</h2>
          </IonCardTitle>
        </IonCardHeader>
        <form
          className="stepComponent"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <IonRow>
            <IonCol size="12">
              <div className="form__group field ion-margin-top">
                <select
                  className={`form__field ${errors.studies ? ' invalid ' : ''}`}
                  {...register('studies', { required: true })}
                  defaultValue={studiesObject.studies}
                >
                  {renderUserStudies()}
                </select>
                <label className="form__label" htmlFor="studies">
                  {t('step3Studies')}
                </label>
                {errors.studies && errors.studies.type === 'required' && (
                  <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
                )}
              </div>
              <div className="form__group field ion-margin-top">
                <input
                  type="text"
                  className={`form__field ${
                    errors.degree_title ? ' invalid ' : ''
                  }`}
                  {...register('degree_title', {
                    required: true,
                    minLength: 2,
                  })}
                  name="degree_title"
                  defaultValue={studiesObject.degree_title}
                />
                <label className="form__label" htmlFor="degree_title">
                  {t('step3Degree')}
                </label>
                {errors.degree_title
                  && errors.degree_title.type === 'required' && (
                    <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
                )}
                {errors.degree_title
                  && errors.degree_title.type === 'minLength' && (
                    <IonText color="danger">
                      2
                      {t('minimunChars')}
                    </IonText>
                )}
              </div>
              <div className="form__group field ion-margin-top">
                <input
                  type="text"
                  className={`form__field ${
                    errors.institution_name ? ' invalid ' : ''
                  }`}
                  {...register('institution_name', {
                    required: true,
                    minLength: 2,
                  })}
                  name="institution_name"
                  defaultValue={studiesObject.institution_name}
                />
                <label className="form__label" htmlFor="institution_name">
                  {t('step3College')}
                </label>
                {errors.institution_name
                  && errors.institution_name.type === 'required' && (
                    <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
                )}
                {errors.institution_name
                  && errors.institution_name.type === 'minLength' && (
                    <IonText color="danger">
                      2
                      {t('minimunChars')}
                    </IonText>
                )}
              </div>
              <h6>{t('step3Dates')}</h6>
              <IonRow>
                <IonCol size="12" sizeLg="6">
                  <div className="form__group field ion-margin-top">
                    <input
                      type="text"
                      className={`form__field ${
                        errors.institution_name ? ' invalid ' : ''
                      }`}
                      {...register('date_start', {
                        required: true,
                      })}
                      value={formatIsoDate(studiesObject.date_start || new Date().toISOString(), 'LLLL yyyy')}
                    />
                    <Calendar
                      maxDate={new Date()}
                      locale={language}
                      maxDetail="month"
                      onChange={(e: any) => entryUpdateDate(e, 'date_start')}
                      defaultValue={
                        studiesObject.date_start
                          ? new Date(studiesObject.date_start)
                          : new Date()
                      }
                    />
                    <label className="form__label" htmlFor="date_start">
                      {t('step3From')}
                    </label>
                  </div>
                  {errors.date_start
                    && errors.date_start.type === 'required' && (
                      <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
                  )}
                </IonCol>
                <IonCol size="12" sizeLg="6">
                  <div className="form__group field ion-margin-top">
                    <input
                      type="text"
                      className={`form__field ${
                        errors.institution_name ? ' invalid ' : ''
                      }`}
                      {...register('date_end', {
                        required: true,
                      })}
                      value={(studiesObject.date_end
                        && studiesObject.date_end !== 'Present')
                        ? formatIsoDate(studiesObject.date_end, 'LLLL yyyy')
                        : formatIsoDate(new Date().toISOString(), 'LLLL yyyy')}
                    />
                    <Calendar
                      maxDate={new Date()}
                      maxDetail="month"
                      locale={language}
                      onChange={(e: any) => entryUpdateDate(e, 'date_end')}
                      defaultValue={
                        (studiesObject.date_end
                        && studiesObject.date_end !== 'Present')
                          ? new Date(studiesObject.date_end)
                          : new Date()
                      }
                    />
                    <label className="form__label" htmlFor="date_end">
                      {t('step3To')}
                    </label>
                  </div>
                  {errors.date_end && errors.date_end.type === 'required' && (
                    <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
                  )}
                </IonCol>
                <IonCol sizeLg="12" size="12">
                  <label
                    className="form__label ion-margin-top"
                    htmlFor="isPresent"
                  >
                    <input
                      type="checkbox"
                      {...register('isPresent')}
                      className="form-check-input"
                      checked={studiesdata.presentCheck}
                      onChange={() => {
                        setStudies({
                          ...studiesdata,
                          presentCheck: !studiesdata.presentCheck,
                        });
                      }}
                    />
                    &nbsp;&nbsp;
                    {t('presentText')}
                  </label>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonButton data-testid="test-submit" type="submit" fill="outline" shape="round">
            {' '}
            {t('stepSave')}
          </IonButton>
        </form>
      </IonContent>
    </IonCard>
  );
};

export default EducationHistoryModal;
