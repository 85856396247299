import {
  IonButton,
  IonCard,
  IonCardContent,
  IonChip,
  IonText,
} from '@ionic/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContextState } from '../../../../state/AppContext';

const Earnings: React.FC = () => {
  const { t } = useTranslation();

  const contextState = useContext(AppContextState);
  const { balances, expertUrlStripe } = contextState;

  const showStripeDashboard = () => {
    window.open(expertUrlStripe, '_blank');
  };

  const sendToStripe = () => {
    const token = sessionStorage.getItem('jwt') || '';
    localStorage.setItem('jwt', token);
    window.open(
      `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${process.env.REACT_APP_STRIPE_CALLBACK}&client_id=${process.env.REACT_APP_STRIPE_ID}&state=${token}`,
      '_blank',
    );
  };

  return (
    <IonCard id="expert_profile_earnings" className="ion-no-margin">
      <IonCardContent>
        {balances && balances.total_amount > 0 ? (
          <p className="description">
            <span>
              $
              {balances.total_amount}
            </span>
            {' '}
            <span className="currency">USD</span>
            {' '}
            <span className="message">{t('edEarnings')}</span>
          </p>
        ) : (
          <p className="description">
            <span>$0</span>
            {' '}
            <span className="currency">USD</span>
            {' '}
            <span className="message">{t('edNoEarnings')}</span>
          </p>
        )}
        {balances && balances.total_amount > 0 && (
        <IonChip className="ion-padding ion-hide-sm-down">
          <IonText>{t('SA_L25')}</IonText>
        </IonChip>
        )}
        {expertUrlStripe === undefined ? (
          <IonButton data-testid="test-earning-dashbtn" className="earnings_button" onClick={() => sendToStripe()}>
            {t('edEarningsRegisterButton')}
          </IonButton>
        ) : (
          <IonButton
            data-testid="test-earning-requestbtn"
            className="earnings_button"
            onClick={() => showStripeDashboard()}
          >
            {t('edEarningsButton')}
          </IonButton>
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default Earnings;
