/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import {
  IonButton, IonCard, IonCardContent, IonChip, IonCol, IonIcon, IonRow, IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { checkmarkCircleOutline, alertCircleOutline } from 'ionicons/icons';
import { LOADER } from '../../../constants/constants';
import { General, Userprofile } from '../../../interfaces';
import { AppContextState, AppContextDispatch } from '../../../state/AppContext';
import location from '../../../constants/location';
import { getTimeZone } from '../../../services/client';
import useUpdateUser from '../../../hooks/useUpdateUser';

interface FormProps {
  first_name: string;
  last_name: string;
  time_zone: string;
}

const ClientContactInfo: React.FC = () => {
  const { t } = useTranslation();

  const contextState = useContext(AppContextState);
  const contextDispatch = useContext(AppContextDispatch);

  const { user_profile } = contextState.profile;

  const { status, updateClient } = useUpdateUser();
  const [newProfile, setNewProfile] = useState<Userprofile>({
    ...user_profile,
  });
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormProps>();

  const dispatchLoader = (value: boolean) => {
    const payload: General = {
      loader: value,
    };
    contextDispatch({
      type: LOADER,
      payload,
    });
  };

  const requestUserTimeZone = async (event: any) => {
    dispatchLoader(true);
    const jwt = sessionStorage.getItem('jwt') || '';
    await getTimeZone(jwt, event.target.value).then(({ data }) => {
      const newTimeZone = {
        id: data.id,
        continent: data.continent,
        name: data.name,
        utc_difference: data.utc_difference,
      };
      setNewProfile({
        ...newProfile,
        time_zone: JSON.stringify(newTimeZone),
        timezone_id: data.id,
      });
      dispatchLoader(false);
    })
      .catch(() => {
        setNewProfile({
          ...newProfile,
          time_zone: undefined,
          timezone_id: undefined,
        });
        dispatchLoader(false);
      });
  };

  useEffect(() => {
    if (user_profile) {
      setValue('first_name', user_profile.first_name);
      setValue('last_name', user_profile.last_name);
      setValue(
        'time_zone',
        user_profile.time_zone && JSON.parse(user_profile.time_zone).name,
      );
    }
  }, [user_profile]);

  useEffect(() => {
    if (
      newProfile.first_name
      && newProfile.last_name
      && newProfile.time_zone
      && newProfile.timezone_id
    ) {
      setCanUpdate(true);
    }
  }, [newProfile]);

  useEffect(() => {
    if (submit && status === undefined) {
      dispatchLoader(true);
      setSuccess(false);
      setError(false);
      updateClient(newProfile);
    }
    if (status !== undefined) {
      handleRequestResponse(status);
    }
  }, [submit, status]);

  const onSubmit = async (formData: FormProps) => {
    dispatchLoader(true);
    setNewProfile({
      ...newProfile,
      first_name: formData.first_name,
      last_name: formData.last_name,
    });
    setSubmit(true);
    dispatchLoader(false);
  };

  const handleRequestResponse = (type: boolean) => {
    setError(type === false);
    setSuccess(type !== false);
    setCanUpdate(false);
    dispatchLoader(false);
    setSubmit(false);
    setTimeout(() => {
      setError(false);
      setSuccess(false);
    }, 6000);
  };

  return (
    <form
      className="ion-margin-vertical"
      id="contact-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <IonCard>
        <IonCardContent className="ion-padding-top">
          <IonRow>
            <IonCol size="12" sizeLg="6">
              <div className="form__group field">
                <input
                  className={`form__field ${errors.first_name && 'invalid'}`}
                  placeholder="first_name"
                  type="text"
                  id="first_name"
                  {...register('first_name', { required: true })}
                />
                <label htmlFor="first_name" className="form__label">
                  {t('ciUsername')}
                </label>
                {errors.first_name && errors.first_name.type === 'required' && (
                <IonText color="danger">
                  {t('required')}
                </IonText>
                )}
              </div>
            </IonCol>
            <IonCol size="12" sizeLg="6">
              <div className="form__group field">
                <input
                  className={`form__field ${errors.last_name && 'invalid'}`}
                  placeholder="last_name"
                  type="text"
                  id="last_name"
                  {...register('last_name', { required: true })}
                />
                <label htmlFor="last_name" className="form__label">
                  {t('ciUsername')}
                </label>
                {errors.last_name && errors.last_name.type === 'required' && (
                <IonText color="danger">
                  {t('required')}
                </IonText>
                )}
              </div>
            </IonCol>
            <IonCol size="12" sizeLg="6">
              <div className="form__group field">
                <select
                  data-testid="test-CCI-timezone"
                  className={`form__field ${errors.time_zone && 'invalid'}`}
                  id="time_zone"
                  {...register('time_zone', { required: true })}
                  onChange={(e) => requestUserTimeZone(e)}
                >
                  {location.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <label htmlFor="time_zone" className="form__label">
                  {t('ciUserCountry')}
                </label>
                {errors.time_zone && errors.time_zone.type === 'required' && (
                <IonText color="danger">
                  {t('required')}
                </IonText>
                )}
              </div>
            </IonCol>
            <IonCol size="12" className="ion-no-padding">
              {canUpdate && (
              <IonButton
                data-testid="test-button-submit"
                shape="round"
                fill="outline"
                type="submit"
              >
                {t('ciSaveInfo')}
              </IonButton>
              )}
              {
            success
            && (
            <IonChip color="success ion-margin-top">
              <IonIcon icon={checkmarkCircleOutline} className="ion-margin-end" />
              {t('globalSuccess')}
            </IonChip>
            )
          }
              { error
            && (
            <IonChip color="warning ion-margin-top">
              <IonIcon icon={alertCircleOutline} className="ion-margin-end" />
              {t('globalError')}
            </IonChip>
            )}
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>
    </form>
  );
};

export default ClientContactInfo;
