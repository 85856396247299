import {
  IonRow,
  IonButton,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
} from '@ionic/react';
import { arrowBackOutline, peopleCircleOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ServicesBase } from '../../../interfaces';

interface ComponentProps {
  sessions: string;
  service: ServicesBase;
  returnText: string;
  // eslint-disable-next-line no-unused-vars
  returnStep: () => void;
}

const Step2Header: React.FC<ComponentProps> = ({
  sessions,
  service,
  returnText,
  returnStep,
}) => {
  const { t } = useTranslation();
  return (
    <IonRow className="ion-justify-content-around">
      <IonButton
        color="secondary"
        shape="round"
        fill="outline"
        onClick={() => returnStep()}
      >
        <IonIcon icon={arrowBackOutline} className="ion-margin-end" />
        {t(returnText)}
      </IonButton>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>
            <IonRow className="ion-align-items-center ion-justify-content-center">
              <IonIcon
                size="large"
                icon={peopleCircleOutline}
                className="ion-margin-end"
              />
              {service?.type}
            </IonRow>
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList>
            <IonItem>
              <IonLabel>
                {t('timeDuration', {
                  minutes: service?.duration_in_min,
                })}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                {t('SA_L20')}
                :&nbsp;
                {`$${service?.price}.00 USD`}
              </IonLabel>
            </IonItem>
            {parseInt(sessions, 10) > 1 && service && (
              <IonItem>
                <IonLabel color="tertiary">
                  {t('SA_L20_3')}
                  :&nbsp;
                  {`$${service.price * parseInt(sessions, 10)}.00 USD`}
                </IonLabel>
              </IonItem>
            )}
          </IonList>
        </IonCardContent>
      </IonCard>
    </IonRow>
  );
};

export default Step2Header;
