/* eslint-disable camelcase */
import { IonButton, IonContent, IonRange } from '@ionic/react';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import Cropper from 'react-easy-crop';
import { UPDATE_USER_PROFILE, LOADER } from '../../constants/constants';
import { Userprofile, General } from '../../interfaces';
import { updateUserAvatar } from '../../services/expert';
import { AppContextDispatch, AppContextState } from '../../state/AppContext';
import ModalWrapper from '../modalWrapper/ModalWrapper';
import getCroppedImg from './cropImage';
import './updateAvatar.scss';

interface ComponentProps {
    showModalFn: boolean;
    // eslint-disable-next-line no-unused-vars
    setShowAvatarModal: (val: boolean) => void;
}

const UpdateAvatar: React.FC<ComponentProps> = ({ showModalFn, setShowAvatarModal }) => {
  const contextState = useContext(AppContextState);
  const contextDispatch = useContext(AppContextDispatch);

  const { user_profile } = contextState.profile;
  const [showModal, setShowModal] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropSize, setCropSize] = useState<any>(undefined);
  const [croppedAreaPixelsFn, setCroppedAreaPixelsFn] = useState(null);
  const [ImageFile, setImageFile] = useState<string | null>(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixelsFn(croppedAreaPixels);
  }, []);

  const dispatchFunction = (value: Userprofile) => {
    const payload = {
      profile: {
        user_profile: value,
      },
    };
    contextDispatch({
      type: UPDATE_USER_PROFILE,
      payload,
    });
  };

  const dispatchLoader = (value: boolean) => {
    const payload: General = {
      loader: value,
    };
    contextDispatch({
      type: LOADER,
      payload,
    });
  };

  const openFileSelector = (event: any) => {
    event.stopPropagation();
    const input_file = document.getElementById('avatarInput');
    input_file?.click();
  };

  const validateFile = (data: any) => {
    if (data.length > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const file = reader.result || '';
        setImageFile(file as string);
      };
      reader.readAsDataURL(data[0]);
    }
  };

  const updateAvatar = async (newAvatar: FormData) => {
    dispatchLoader(true);
    const jwtToken = sessionStorage.getItem('jwt') || '';
    if (newAvatar) {
      await updateUserAvatar(newAvatar, jwtToken)
        .then(({ data }) => {
          dispatchFunction(data);
          dispatchLoader(false);
          setShowAvatarModal(false);
        })
        .catch((error) => {
          console.log(error);
          dispatchLoader(false);
          setShowAvatarModal(false);
        });
    }
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const newImage = await getCroppedImg(
        ImageFile,
        croppedAreaPixelsFn,
        0,
      );
      const dataFile = new FormData();
      dataFile.append('avatar', newImage as string);
      updateAvatar(dataFile);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixelsFn]);

  useEffect(() => {
    setShowModal(showModalFn);
  }, [showModalFn]);

  useEffect(() => {
    setShowAvatarModal(showModal);
  }, [showModal]);

  useEffect(() => {
    if (user_profile.id > 0) {
      fetch(`${process.env.REACT_APP_PROXY_URL || ''}${user_profile.avatar.url}`)
        .then((response) => response.blob())
        .then((imageBlob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const file = reader.result || '';
            setImageFile(file as string);
          };
          reader.readAsDataURL(imageBlob);
        });
    }
  }, [user_profile]);

  if (!showModal) return null;

  return (
    <ModalWrapper
      isOpen={showModal}
      setIsOpen={() => setShowModal(false)}
    >
      <IonContent>
        <div className="crop-container">
          {showModal && ImageFile && (
            <Cropper
              image={ImageFile}
              crop={crop}
              zoom={zoom}
              cropShape="round"
              aspect={1}
              showGrid={false}
              cropSize={cropSize}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          )}
        </div>
        <div className="controls">
          <div className="actions ion-margin-vertical">
            <IonButton onClick={(e) => openFileSelector(e)}>select</IonButton>
            <IonButton disabled={!cropSize} onClick={showCroppedImage}>Set avatar</IonButton>
          </div>
          <IonRange
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            style={{ width: '100%' }}
            onIonChange={(e) => {
              setZoom(Number(e.detail.value));
              setCropSize({
                width: 250,
                height: 250,
              });
            }}
          />
          <input
            data-testid="test-file-input"
            type="file"
            className="ion-hide"
            name="avatarInput"
            id="avatarInput"
            placeholder=""
            onChange={(e) => validateFile(e.target.files)}
            accept="image/png, image/gif, image/jpeg"
          />
        </div>
      </IonContent>
    </ModalWrapper>
  );
};

export default UpdateAvatar;
