/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ellipse, ellipseOutline } from 'ionicons/icons';
import ImageHandler from '../ImageHandler/ImageHandler';
import { AppContextState } from '../../state/AppContext';
import { EXPRESS_PRICE, MENTORING_PRICE, COACHING_PRICE } from '../../constants/constants';

type ServicesOptions = string | undefined;

interface ComponentProps {
  expertServices: string[] | undefined;
  // eslint-disable-next-line no-unused-vars
  selected: (value: any) => void;
}

const ServiceCard: React.FC<ComponentProps> = ({ expertServices, selected }) => {
  const { t } = useTranslation();

  const contextState = useContext(AppContextState);

  const { services } = contextState;

  const [serviceSelected, setServiceSelected] = useState<ServicesOptions>(
    undefined,
  );

  const isOfferPrice = (service: string, price: number) => {
    const listPrice = service.includes('express')
      ? EXPRESS_PRICE : service.includes('mentoring')
        ? MENTORING_PRICE : COACHING_PRICE;
    if (price < listPrice) {
      return (
        <p className="offer-price">
          {t('offerPrice')}
          {' '}
          <span>{`$${listPrice}.00 USD`}</span>
        </p>
      );
    }
    return null;
  };

  useEffect(() => {
    selected(serviceSelected);
  }, [serviceSelected]);

  return (
    <IonGrid>
      {/* TODO: refactor to return only rendered card and not map each service in here */}
      <IonRow>
        {expertServices
          && expertServices.map((service: string, index: number) => {
            const findService = service.replace('Check', '');
            const newService = services && services.filter((item) => item.type
              .toLowerCase().includes(findService))[0];
            const serviceIcon = service.includes('xpres')
              ? 'express'
              : service.includes('entorin')
                ? 'mentoring'
                : 'coaching';
            if (service !== 'ProjectInMind' && newService) {
              return (
                <IonCol key={newService.id} size="12" sizeMd="4">
                  <IonCard
                    data-testid={`service-card${index}`}
                    className={`serviceCard ion-padding ${
                      service === serviceSelected ? 'active' : ''
                    }`}
                    onClick={() => setServiceSelected(service)}
                  >
                    <IonCardHeader className="ion-text-center">
                      <IonIcon
                        color="secondary"
                        className="serviceStatus"
                        src={
                          service === serviceSelected ? ellipse : ellipseOutline
                        }
                      />
                      <div>
                        {ImageHandler({
                          imageName: serviceIcon,
                          extension: 'svg',
                          classN: 'serviceIcon',
                          slot: 'icon-only',
                        })}
                      </div>
                      <IonCardSubtitle>
                        {serviceIcon.includes('xpress')
                          ? t('express_service')
                          : t(`${serviceIcon.toLowerCase()}_service`)}
                      </IonCardSubtitle>
                      <IonCardTitle>
                        {`$${newService.price}.00 USD`}
                        {isOfferPrice(service, newService.price)}
                      </IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                </IonCol>
              );
            }
            return null;
          })}
      </IonRow>
    </IonGrid>
  );
};

export default ServiceCard;
