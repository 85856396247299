import { useContext } from 'react';
import { getExpertsCommunity, requestClientPayments } from '../services/client';
import { AppContextDispatch, AppContextState } from '../state/AppContext';

const useClientDashboard = () => {
  const contextState = useContext(AppContextState);
  const contextDispatch = useContext(AppContextDispatch);

  const { clientCommunityResults, clientPayments } = contextState;

  const dispatchFunction = (type: string, value: boolean) => {
    const payload: object = {
      [type]: value,
    };
    contextDispatch({
      type: `UPDATE_${type.toUpperCase()}`,
      payload,
    });
  };

  const validateClientDashboard = async () => {
    const jwt: string = sessionStorage.getItem('jwt') || '';
    try {
      if (!clientCommunityResults) await getExpertsCommunity(jwt).then(({ data }) => dispatchFunction('clientCommunityResults', data));
      if (!clientPayments) await requestClientPayments(jwt).then(({ data }) => dispatchFunction('clientPayments', data));
    } catch (error) {
      console.log(error);
    }
  };

  return [validateClientDashboard];
};

export default useClientDashboard;
