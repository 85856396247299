import React, { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonFooter,
  IonIcon,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { lockClosedOutline, briefcaseOutline } from 'ionicons/icons';
import './Footer.scss';
import SocialNetworks from '../socialNetworks/SocialNetworks';
import ModalWrapper from '../modalWrapper/ModalWrapper';
import PrivacyModal from '../privacyModal/PrivacyModal';
import TermsModal from '../termsModal/TermsModal';

const Footer: React.FC = () => {
  const [privacyShow, setPrivacyShow] = useState(false);
  const [termsModal, setTermsModal] = useState(false);

  const { t } = useTranslation();
  return (
    <IonFooter className="ion-no-border" id="application-footer">
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton data-testid="test-show-privacy" onClick={() => setPrivacyShow(true)}>
            <IonIcon slot="start" icon={lockClosedOutline} />
            <IonText className="ion-hide-sm-down">{t('Privacy')}</IonText>
          </IonButton>
        </IonButtons>
        <SocialNetworks />
        <IonButtons slot="end">
          <IonButton data-testid="test-show-terms" onClick={() => setTermsModal(true)}>
            <IonIcon slot="start" icon={briefcaseOutline} />
            <IonText className="ion-hide-sm-down">{t('Terms')}</IonText>
          </IonButton>
        </IonButtons>
      </IonToolbar>
      {termsModal && (
      <ModalWrapper wrapperClass="borderModal" isOpen={termsModal} setIsOpen={/* istanbul ignore next */ () => setTermsModal(false)}>
        <TermsModal />
      </ModalWrapper>
      )}
      {privacyShow && (
      <ModalWrapper wrapperClass="borderModal" isOpen={privacyShow} setIsOpen={/* istanbul ignore next */ () => setPrivacyShow(false)}>
        <PrivacyModal />
      </ModalWrapper>
      )}
    </IonFooter>
  );
};

export default Footer;
