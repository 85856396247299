import {
  IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonButton,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const Billing: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <div className="section-title ion-margin-top">
        <h2 className="pr-3">{t('billingHeader')}</h2>
        <span className="title-border" />
      </div>
      <IonCard className="ion-no-margin">
        <IonCardHeader>
          <IonCardSubtitle>
            <p>{t('billingText')}</p>
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonButton
            fill="outline"
            shape="round"
            onClick={() => history.push('/user/billing')}
          >
            {t('US_L10')}
          </IonButton>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default Billing;
