/* eslint-disable camelcase */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import gfm from 'remark-gfm';
// Images
import { IonAvatar, IonButton, IonIcon } from '@ionic/react';
import { arrowBackCircleOutline } from 'ionicons/icons';
import banner from '../../../assets/images/dashboardMenu.png';
import logo from '../../../assets/images/logo.png';
import { Category } from '../../../interfaces/strapiInterfaces';

interface ComponentProps {
    [key: string]: any;
}

const BlogContent: React.FC<ComponentProps> = ({ content }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const {
    Content_en,
    Content_es,
    Media_en,
    Media_es,
    Title_en,
    Title_es,
    Video_en,
    Video_es,
    Main_image,
    Youtube_id,
    Vimeo_id,
    Description_es,
    Descriptopn_en,
    Logo_image,
    categories,
    created_by,
  } = content;
  const title = language === 'es' ? Title_es : Title_en;
  const description = language === 'es' ? Content_es : Content_en;
  const conent = language === 'es' ? Description_es : Descriptopn_en;
  const finalData = description || conent;
  const media = language === 'es' ? Media_es : Media_en;
  const video = language === 'es' ? Video_es : Video_en;
  const finalMedia = media && media && media;
  const finalVideo = video && video && video;
  const image = Main_image || undefined;
  const bannerImage = image
    ? process.env.REACT_APP_STRAPI_BASE + image.url
    : banner;
  const creator = `${created_by.firstname} ${created_by.lastname}`;

  const goBack = () => {
    history.goBack();
  };
  return (
    <>
      <div
        className="blogHero"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div className="blogData">
          {Logo_image && (
            <img
              alt="post cover"
              className="serviceSvg"
              src={process.env.REACT_APP_STRAPI_BASE + Logo_image.url}
            />
          )}
          <div className="categories">
            {categories && categories.length > 0
              ? categories.map((category: Category) => (
                <div key={category.id} className="categorieName">
                  {category.name}
                </div>
              ))
              : null}
          </div>
          <h1>{title}</h1>
        </div>
      </div>
      <div className="blog-body">
        <ReactMarkdown
          remarkPlugins={[gfm]}
          className="rich-text-content"
          transformImageUri={(src) => process.env.REACT_APP_STRAPI_BASE + src}
        >
          {finalData}
        </ReactMarkdown>
        {Youtube_id && (
          <iframe
            title="Youtube video embed"
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${Youtube_id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
        {Vimeo_id && (
          <iframe
            title="Vimeo video"
            src={`https://player.vimeo.com/video/${Vimeo_id}`}
            width="560"
            height="315"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        )}
        {finalMedia && (
          <img
            className="image-media"
            src={
              process.env.REACT_APP_STRAPI_BASE + finalMedia.formats.medium.url
            }
            alt={finalMedia.alternativeText}
          />
        )}
        {finalVideo && (
          <video className="video-media" controls>
            <source
              src={process.env.REACT_APP_STRAPI_BASE + finalVideo.url}
              type={finalVideo.mime}
            />
            <track src="captions_en.vtt" kind="captions" label="english_captions" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className="author">
        <div className="title">
          <h5>
            <span className="back" />
            {t('blog_6')}
          </h5>
          <span className="title-border" />
        </div>
        <IonAvatar style={{
          margin: 'auto',
          border: '1px solid var(--ion-color-primary)',
          padding: '0.15rem',
          width: '70px',
          height: '70px',
        }}
        >
          <img
            src={logo}
            alt="user"
          />
        </IonAvatar>
        <p className="author-name">{creator}</p>
      </div>
      <IonButton slot="end" color="secondary" fill="outline" shape="round" size="small" onClick={goBack}>
        <IonIcon slot="icon-only" icon={arrowBackCircleOutline} />
        &nbsp;&nbsp;
        {t('blog_4')}
      </IonButton>
    </>
  );
};

export default BlogContent;
