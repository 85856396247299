const location = [
  'Accra',
  'Addis Ababa',
  'Adelaide',
  'Algiers',
  'Almaty',
  'Amman',
  'Amsterdam',
  'Anadyr',
  'Anchorage',
  'Ankara',
  'Antananarivo',
  'Apia',
  'Asuncion',
  'Athens',
  'Atlanta',
  'Auckland',
  'Azores',
  'Baghdad',
  'Baku',
  'Bangalore',
  'Bangkok',
  'Beirut',
  'Belgrade',
  'Berlin',
  'Bogota',
  'Boston',
  'Brasilia',
  'Bratislava',
  'Brisbane',
  'Brussels',
  'Bucharest',
  'Budapest',
  'Buenos Aires, Argentina',
  'Cairo',
  'Calgary',
  'Canberra',
  'Cape Towm',
  'Cape Verde',
  'Caracas',
  'Casablanca',
  'Chatham',
  'Chicago',
  'Chihuahua',
  'Chongqing',
  'Colombo',
  'Copenhagen',
  'Dallas',
  'Darwin',
  'Denver',
  'Detroit',
  'Dhaka',
  'Doha',
  'Dubai',
  'Dublin',
  'Edmonton',
  'Fakaofo',
  'Fiji',
  'Frankfurt',
  'GMT+12',
  'Godthab',
  'Guadalcanal',
  'Guam',
  'Guatemala',
  'Guyana',
  'Halifax',
  'Hanoi',
  'Harare',
  'Havana',
  'Helsinki',
  'Hobart',
  'Hong Kong',
  'Honolulu',
  'Houston',
  'Indianapolis, Indiana',
  'Irkutsk',
  'Islamabad',
  'Istanbul',
  'Jakarta',
  'Jerusalem',
  'Johannesburg',
  'Juneau',
  'Kabul',
  'Kaliningrad',
  'Kamchatka',
  'Karachi',
  'Kathmandu',
  'Khartoum',
  'Kiev',
  'Kingston',
  'Kinshasa',
  'Kolkata',
  'Krasnoyarsk',
  'Kuala Lumpur',
  'Kuwait',
  'La Paz',
  'Lagos',
  'Lahore',
  'Las Vegas',
  'Lima',
  'Lisbon',
  'Ljubljana',
  'London',
  'Los Angeles',
  'Madrid',
  'Magadan',
  'Majuro',
  'Managua',
  'Manila',
  'Mazatlan',
  'Melbourne',
  'Mexico City',
  'Miami',
  'Midway',
  'Minneapolis',
  'Minsk',
  'Monrovia',
  'Monterrey',
  'Montevideo',
  'Montreal',
  'Moscow',
  'Mumbai',
  'Muscat',
  'Nairobi',
  'Nassau',
  'New Delhi',
  'New Orleans',
  'New York',
  'Noumea',
  'Novosibirsk',
  'Oslo',
  'Ottawa',
  'Pago Pago',
  'Paris',
  'Perth',
  'Phoenix',
  'Port Moresby',
  'Prague',
  'Puerto Rico',
  'Rangoon',
  'Regina',
  'Riga',
  'Rio de Janeiro',
  'Riyadh',
  'Rome',
  'Salt Lake City',
  'Samara',
  'San Francisco',
  'San Juan',
  'San Salvador',
  'Santiago',
  'Sao Paulo',
  'Sarajevo',
  'Seattle',
  'Seoul',
  'Shanghai',
  'Singapore',
  'Skopje',
  'Sofia',
  'South Georgia',
  'Srednekolymsk',
  'St Johns',
  'Stockholm',
  'Suva',
  'Sydney',
  'Taipei',
  'Tallinn',
  'Tashkent',
  'Tbilisi',
  'Tegucigalpa',
  'Tehran',
  'Tijuana',
  'Tokyo',
  'Tongatapu',
  'Toronto',
  'UTC',
  'Ulaanbaatar',
  'Urumqi',
  'Vancouver',
  'Vienna',
  'Vilnius',
  'Vladivostok',
  'Volgograd',
  'Warsaw',
  'Washington DC',
  'Winnipeg',
  'Yakutsk',
  'Yangon',
  'Yekaterinburg',
  'Yerevan',
  'Zagreb',
  'Zurich',
];

export default location;
