/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useMemo, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {
  IonChip,
  IonIcon,
  IonRow,
  IonButton,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { alertCircleOutline } from 'ionicons/icons';
import stripeImage from '../../../../assets/images/powered_by_stripe.svg';
import { AppContextState } from '../../../../state/AppContext';
import useLoader from '../../../../hooks/useLoader';

interface ComponentProps {
    // eslint-disable-next-line no-unused-vars
    clientStripe: (data: any) => void;
}

const NewCheckoutForm: React.FC<ComponentProps> = ({ clientStripe }) => {
  const { t } = useTranslation();
  const { setLoader } = useLoader();
  const contextState = useContext(AppContextState);
  const { user_profile } = contextState.profile;
  const stripe = useStripe();
  const elements = useElements();
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          fontSize: '18px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }), [],
  );
  const [error, setError] = useState(false);
  const [errormessage, setErrorMessage] = useState('');

  const errorMessage = () => {
    if (!errormessage) return null;
    return (
      <IonChip color="warning ion-margin-top">
        <IonIcon icon={alertCircleOutline} className="ion-margin-end" />
        {errormessage}
      </IonChip>
    );
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const CardElementFromStripe = elements.getElement(CardElement);

    if (CardElementFromStripe) {
      setLoader(true);
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: CardElementFromStripe,
      });
      const token = await stripe.createToken(CardElementFromStripe).then((tok) => tok);

      if (payload.error && payload.error.message) {
        setError(true);
        setLoader(false);
        setErrorMessage(payload.error.message);
        return;
      }
      if (token.error && token.error.message) {
        setError(true);
        setLoader(false);
        setErrorMessage(token.error.message);
        return;
      }

      setError(false);
      setErrorMessage('');

      const card = token.token?.card;
      const newID = user_profile && user_profile.id;
      if (card) {
        const newData = {
          stripeToken: token.token?.id,
          user_id: newID,
          stripe_card_uid: card.id,
          stripe_card_brand: card.brand,
          stripe_card_last4: card.last4,
          stripe_card_exp_month: card.exp_month,
          stripe_card_exp_year: card.exp_year,
        };
        clientStripe(newData);
        setLoader(false);
      }
    }
  };

  return (
    <IonRow className="checkout">
      <IonIcon
        className="stripeLogo"
        icon={stripeImage}
      />
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <div className="form__group field">
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label className="stripe-label">
            <h5>{t('CHF_L1')}</h5>
            <CardElement options={options} />
          </label>
        </div>
        {error && errorMessage()}
        <br />
        <IonButton
          type="submit"
          shape="round"
          fill="outline"
          className="ion-margin-top"
        >
          {t('Continue')}
        </IonButton>
      </form>
    </IonRow>
  );
};

export default NewCheckoutForm;
