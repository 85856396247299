/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IonContent } from '@ionic/react';
import { AppointmentDetail } from '../../interfaces/clientInterfaces';
import { getSingleAppointment, updateAppointment } from '../../services/client';
import './serviceMeeting.scss';
import SMHeader from './components/SMHeader';
import ModalWrapper from '../../components/modalWrapper/ModalWrapper';
import VideoChat from './components/VideoChat';
import SessionRequests from './components/SessionRequests';
import RateService from './components/RateService';
import useLoader from '../../hooks/useLoader';
import ServiceDetails from './components/ServiceDetails';

interface VCOptions {
  error: any;
  audio: boolean;
  suscribeAudio: boolean;
  video: boolean;
  screen: undefined | string;
}

const ServiceMeeting: React.FC = () => {
  const jwt: string = sessionStorage.getItem('jwt') || '';
  const { setLoader } = useLoader();

  const { id } = useParams<{ id: string }>();
  const [activeService, setActiveService] = useState<AppointmentDetail>();
  const [serviceError, setServiceError] = useState<boolean>();
  const [serviceId] = useState(parseInt(id, 10));
  const [showCommentsModal, setShowCommentsModal] = useState<boolean>(false);
  const [VCoptions, setVCoptions] = useState<VCOptions>({
    error: null,
    audio: true,
    suscribeAudio: true,
    video: true,
    screen: undefined,
  });

  const getCurrentService = async () => {
    await getSingleAppointment(jwt, parseInt(id, 10))
      .then(({ data }) => {
        setActiveService(data);
        setServiceError(false);
      })
      .catch(() => {
        setActiveService(undefined);
        setServiceError(true);
      });
  };

  const enlargeVideoChat = () => {
    const body_dom = document.body.classList;
    body_dom.toggle('full_chat');
  };

  const endSession = () => {
    setVCoptions({
      ...VCoptions,
      video: false,
      audio: false,
    });
    completeCurrentAppointment();
  };

  const willCloseSession = () => {
    setShowCommentsModal(true);
    setVCoptions({
      ...VCoptions,
      video: false,
      audio: false,
    });
  };

  const completeCurrentAppointment = () => {
    setLoader(true);
    const event = { ...activeService };
    const newEventObject = {
      id: event.id,
      type: 'ClosedAppointment',
    };
    updateAppointment(jwt, newEventObject)
      .then(({ data }) => {
        setActiveService(data);
        setLoader(false);
      })
      .catch((() => {
        setLoader(false);
      }));
  };

  useEffect(() => {
    if (serviceId && !activeService && Number.isInteger(serviceId)) getCurrentService();
  }, [serviceId, activeService]);

  if (serviceError && !activeService) {
    return <div>error</div>;
  }

  if (!serviceError && activeService) {
    if (activeService.type === 'InProgressAppointment') {
      return (
        <div
          id="chat-container"
          className={`
                    ${VCoptions.screen === 'screen' ? 'sharing-mode' : ''}
                    `}
        >
          <SMHeader
            service={activeService}
            fullScreen={enlargeVideoChat}
            showComments={() => willCloseSession()}
          />
          <VideoChat
            VCoptions={VCoptions}
            setVCoptions={(options) => setVCoptions({ ...options })}
            activeService={activeService}
          />
          <SessionRequests service={activeService} />
          {showCommentsModal && (
          <ModalWrapper
            wrapperClass="borderModal"
            isOpen={showCommentsModal}
            setIsOpen={() => setShowCommentsModal(false)}
          >
            <IonContent>
              <RateService
                service={activeService}
                close={() => setShowCommentsModal(false)}
                valid={() => endSession()}
              />
            </IonContent>
          </ModalWrapper>
          )}
        </div>
      );
    }

    return (
      <ServiceDetails service={activeService} />
    );
  }
  return null;
};

export default ServiceMeeting;
