/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import urlSlug from 'url-slug';
import { IonCard, IonCardContent } from '@ionic/react';
import { ServicesExperts } from '../../../interfaces/strapiInterfaces';
// images
import logo from '../../../assets/images/logo_vertical.svg';

interface Content extends ServicesExperts {}

interface ComponentProps {
  content: Content;
}

const ServicesCards: React.FC<ComponentProps> = ({ content }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const { Title_en, Title_es, Logo_image } = content;
  const title = language === 'es' ? Title_es : Title_en;
  const image = Logo_image || undefined;
  const background = image ? process.env.REACT_APP_STRAPI_BASE + image.url : logo;

  return (
    <IonCard className="service">
      <IonCardContent>
        <Link className="" to={{ pathname: `/user/library/${urlSlug(title)}`, state: [content.id, 'services-experts'] }}>
          <img className="service-image" src={background} alt="card" />
          <h3>{title}</h3>
        </Link>
      </IonCardContent>
    </IonCard>
  );
};

export default ServicesCards;
