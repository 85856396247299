/* eslint-disable camelcase */
import {
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonGrid,
  IonButton,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LastPayInfo from '../../../components/lastPay/LastPayInfo';
import useClientSearch from '../../../hooks/useClientSearch';
import { Sethistorysearch } from '../../../interfaces';
import { AppContextState } from '../../../state/AppContext';
import StripeContainer from './clientStripe/StripeContainer';

type SelectableSegments = string | undefined;

type FormData = {
  coachType?: string;
  searchCoaching?: string;
  coachChanges?: string;
  coachAchives?: string;
  searchExpressAdvice?: string;
  searchMentory?: string;
  needMentory?: string;
};

interface ComponentProps {
  // eslint-disable-next-line no-unused-vars
  payToken: (token: string) => void;
  // eslint-disable-next-line no-unused-vars
  history: (token: Sethistorysearch) => void;
  clientResponse: FormData | undefined;
  service: string | undefined;
}

const Step3: React.FC<ComponentProps> = ({
  payToken,
  history,
  clientResponse,
  service,
}) => {
  const { t } = useTranslation();
  const { newHistorySearch } = useClientSearch();
  const contextState = useContext(AppContextState);
  const { client } = contextState;
  const { set_history_search, client_find_data } = client;
  const { last_payment_info } = contextState.profile.user_profile;
  const [activeSegment, setActiveSegment] = useState<SelectableSegments>('newPay');
  const [stripeToken, setStripeToken] = useState<string>();

  const createSearchHistory = async (token: string) => {
    try {
      const newHS = { ...set_history_search };
      delete newHS.created_at;
      delete newHS.updated_at;
      delete newHS.id;
      delete newHS.user_id;
      delete newHS.first_available;
      newHS.achieve_experiences = clientResponse?.coachAchives || '';
      newHS.coaching_type = clientResponse?.coachType || '';
      newHS.what_changes = clientResponse?.coachChanges || '';
      newHS.description = clientResponse?.searchCoaching
        || clientResponse?.searchExpressAdvice
        || clientResponse?.searchMentory;
      newHS.mentoring_purpose = clientResponse?.needMentory || '';
      newHS.industry = client_find_data.industry;
      newHS.functions = client_find_data.job_function;
      newHS.seniority_levels = [client_find_data.seniority];
      newHS.skills = client_find_data.skills;
      newHS.service = service || '';
      const response: Sethistorysearch = await newHistorySearch(newHS);
      payToken(token);
      history(response);
    // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  useEffect(() => {
    if (typeof stripeToken === 'string') createSearchHistory(stripeToken);
  }, [stripeToken]);

  return (
    <>
      <IonSegment
        scrollable
        value={activeSegment}
        className="ion-text-center"
        onIonChange={(e) => setActiveSegment(e.detail.value)}
      >
        <IonSegmentButton data-testid="newPay" value="newPay">
          <IonLabel>{t('newPay')}</IonLabel>
        </IonSegmentButton>
        {last_payment_info.brand && (
          <IonSegmentButton data-testid="lastPay" value="lastPay">
            <IonLabel>{t('usePay')}</IonLabel>
          </IonSegmentButton>
        )}
      </IonSegment>
      <IonGrid>
        <div
          className={`collapsable-container ${
            activeSegment === 'newPay' ? 'expand' : 'collapse'
          }`}
        >
          <StripeContainer callBack={(token) => setStripeToken(token)} />
        </div>
        <div
          className={`collapsable-container ${
            activeSegment === 'lastPay' ? 'expand' : 'collapse'
          }`}
        >
          <LastPayInfo />
          <IonButton
            type="submit"
            shape="round"
            fill="outline"
            className="ion-margin-top"
            onClick={() => setStripeToken('0')}
          >
            {t('Continue')}
          </IonButton>
        </div>
      </IonGrid>
    </>
  );
};

export default Step3;
