/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonChip,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
} from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import ResultCard from '../../components/clientSearch/ResultCard';
import CommunityRequest from '../../components/communityRequest/CommunityRequest';
import useBaseInformation from '../../hooks/useBaseInformation';
import useClientSearch from '../../hooks/useClientSearch';
import useLoader from '../../hooks/useLoader';
import useUpdateState from '../../hooks/useUpdateState';
import { ClientFindData, FunctionsBase } from '../../interfaces';
import { ExpertDetail } from '../../interfaces/clientInterfaces';
import { AppContextState } from '../../state/AppContext';
import { slugifyFilter } from '../../utils';
import './SearchFilters.scss';

const SearchExpertFilters: React.FC = () => {
  const { setLoader } = useLoader();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const contextState = useContext(AppContextState);
  const [validateBaseInformation] = useBaseInformation();
  const { updateState } = useUpdateState();
  const { newSearch } = useClientSearch();

  const {
    client, functions, industries, searchSkillsArray,
  } = contextState;
  const { client_find_data } = client;

  const { functionSearched, skillSearched } = useParams<{
    functionSearched: string;
    skillSearched: string;
  }>();

  const [fixedFunction, setFixedFunction] = useState<string>();
  const [fixedSkill, setFixedSkill] = useState<string>();
  const [functionObject, setFunctionObject] = useState<
    FunctionsBase | undefined
  >();
  const [searchResults, setSearchResults] = useState<ExpertDetail[]>([]);
  const [currentSearch, setCurrentSearch] = useState<
    ClientFindData | undefined
  >(undefined);
  const [searched, setSearched] = useState<boolean>(false);
  const [searchCompleted, setSearchCompleted] = useState<boolean>(false);
  const [skillsArrayUpdated, setSkillsArrayUpdated] = useState<boolean>(false);

  const navigateTo = (indus: string, skl: string) => {
    history.push(slugifyFilter(indus, skl));
  };

  const getFunctionAndSkill = () => {
    const currentFunction = functionSearched
      .replace(/_/g, '/')
      .replace(/-/g, ' ');
    const fullFunction = functions?.filter(
      (item) => item.name === currentFunction,
    );
    setFunctionObject(fullFunction ? fullFunction[0] : undefined);
    setFixedFunction(currentFunction);
    setFixedSkill(skillSearched.replace(/_/g, '/').replace(/-/g, ' '));
    setSearched(false);
  };

  const updateIndustryParam = (parameter: any, value: any) => {
    setSearched(false);
    if (currentSearch) {
      setCurrentSearch({
        ...currentSearch,
        [parameter]: [value],
      });
    }
  };

  const requestNewSearch = async () => {
    setSearchResults([]);
    if (currentSearch) {
      setLoader(true);
      setSearchCompleted(false);
      const results = await newSearch(currentSearch);
      setSearchResults(results);
      setSearchCompleted(true);
      setLoader(false);
    }
  };

  const updateSkillsArray = (skill: string) => {
    if (currentSearch) {
      const newSearchParams = { ...currentSearch };
      if (
        newSearchParams.skills.includes(skill)
        && newSearchParams.skills.length > 1
      ) {
        const index = newSearchParams.skills.indexOf(skill);
        if (index > -1) {
          newSearchParams.skills.splice(index, 1);
        }
      } else if (!newSearchParams.skills.includes(skill)) {
        newSearchParams.skills.push(skill);
      }
      setCurrentSearch(newSearchParams);
      updateState(newSearchParams.skills, 'searchSkillsArray');
    }
  };

  useEffect(() => {
    validateBaseInformation();
  }, []);

  useEffect(() => {
    if (searchSkillsArray && currentSearch && !skillsArrayUpdated) {
      const newSearchParams = { ...currentSearch };
      newSearchParams.skills = searchSkillsArray;
      setCurrentSearch(newSearchParams);
      setSkillsArrayUpdated(true);
    }
  }, [searchSkillsArray, currentSearch, skillsArrayUpdated]);

  useEffect(() => {
    getFunctionAndSkill();
  }, [functionSearched, skillSearched, functions]);

  useEffect(() => {
    requestNewSearch();
  }, [currentSearch]);

  useEffect(() => {
    if (fixedFunction
      && fixedFunction.length > 0
      && fixedSkill
      && fixedSkill.length > 0
      && !searched
    ) {
      setSearched(true);
      if (client_find_data) {
        setCurrentSearch({
          ...client_find_data,
          job_function: [fixedFunction],
          skills: searchSkillsArray || [fixedSkill],
          language: i18n.language,
        });
      }
    }
  }, [fixedFunction, fixedSkill, searched, searchSkillsArray]);

  return (
    <IonGrid id="search-expert-filters">
      <IonButton
        color="secondary"
        fill="clear"
        shape="round"
        onClick={() => history.push('/user/search')}
      >
        <IonIcon icon={arrowBackOutline} />
        &nbsp;
        {t('errorBtn')}
      </IonButton>
      <h1 className="ion-no-margin">
        {fixedFunction}
        <span className="ion-hide-lg-down" style={{ fontSize: '1.4rem' }}>
          , &nbsp;
          {fixedSkill}
        </span>
      </h1>
      <IonRow className="ion-margin-vertical">
        {functionObject?.skills
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
          .map((skill) => (
            <IonChip
              key={skill.id}
              color={
                currentSearch?.skills.includes(skill.name)
                  ? 'tertiary'
                  : 'primary'
              }
              disabled={
                currentSearch
                && currentSearch.skills.length >= 3
                && !currentSearch?.skills.includes(skill.name)
              }
              className="clickable"
              onClick={() => updateSkillsArray(skill.name)}
            >
              <IonLabel>{skill.name}</IonLabel>
            </IonChip>
          ))}
        {/* TODO: remove ion-hide to show selectors */}
        <select
          className="ion-hide-lg-up special-select ion-margin-top ion-hide"
          value={fixedSkill}
          onChange={(e) => navigateTo(functionSearched, e.target.value)}
        >
          {functionObject?.skills.map((skill) => (
            <option key={skill.id}>{skill.name}</option>
          ))}
        </select>
        <select
          className="special-select ion-margin-top ion-hide"
          value={currentSearch?.industry[0]}
          onChange={(e) => updateIndustryParam('industry', e.target.value)}
        >
          {industries?.map((industry) => (
            <option key={industry.id} value={industry.name}>
              {industry.name}
            </option>
          ))}
        </select>
      </IonRow>
      {searchResults.length > 1 && (
        <>
          <CommunityRequest />
          <br />
        </>
      )}
      {searchResults.length === 0 && searchCompleted && (
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>{t('globalNoData')}</IonCardTitle>
          </IonCardHeader>
        </IonCard>
      )}
      {searchResults?.map((expert) => (
        <ResultCard key={expert.id} expert={expert} />
      ))}
    </IonGrid>
  );
};

export default SearchExpertFilters;
