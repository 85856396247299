/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonButton, IonContent } from '@ionic/react';
import { AppContextState } from '../../state/AppContext';
import useClientSearch from '../../hooks/useClientSearch';
import { ClientFindData, ServicesBase } from '../../interfaces';
import { ExpertDetail } from '../../interfaces/clientInterfaces';
import ModalWrapper from '../modalWrapper/ModalWrapper';
import ModalBody from './ModalBody';

interface ICommunityRequest {}

const CommunityRequest: React.FC<ICommunityRequest> = () => {
  const { t, i18n } = useTranslation();
  const contextState = useContext(AppContextState);
  const { newSearch } = useClientSearch();

  const { client, services } = contextState;
  const { client_find_data } = client;

  const [currentSearch, setCurrentSearch] = useState<
    ClientFindData | undefined
  >(undefined);
  const [searchCompleted, setSearchCompleted] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<ExpertDetail[]>([]);
  const [communityModal, setCommunityModal] = useState<boolean>(false);
  const [expressService, setExpressService] = useState<ServicesBase | undefined>(undefined);

  useEffect(() => {
    if (client_find_data) {
      setCurrentSearch({
        ...client_find_data,
        language: i18n.language,
      });
    }
  }, [client_find_data]);

  const requestNewSearch = async () => {
    setSearchResults([]);
    if (currentSearch) {
      setSearchCompleted(false);
      const results = await newSearch(currentSearch);
      setSearchResults(results);
      setSearchCompleted(true);
    }
  };

  useEffect(() => {
    if (searchCompleted && searchResults) setCommunityModal(true);
  }, [searchCompleted, searchResults]);

  useEffect(() => {
    if (services) {
      const filteredServices = services.filter(
        (service) => service.type === 'ExpressAdvice',
      );
      setExpressService(filteredServices[0]);
    }
  }, [services]);

  return (
    <>
      <IonButton color="secondary" style={{ maxWidth: '200%', whiteSpace: 'normal' }} shape="round" onClick={requestNewSearch}>
        {t('CF_L3_1')}
      </IonButton>
      <ModalWrapper
        wrapperClass="bigModal borderModal"
        isOpen={communityModal}
        setIsOpen={/* istanbul ignore next */ () => setCommunityModal(false)}
      >
        <IonContent className="ion-margin ion-padding">
          <ModalBody expertsResults={searchResults} serviceData={expressService} />
        </IonContent>
      </ModalWrapper>
    </>
  );
};

export default CommunityRequest;
