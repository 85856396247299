/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  IonButton,
  IonCol,
  IonRow,
  IonText,
} from '@ionic/react';

type FormData = {
    searchMentory: string;
    needMentory: string;
};

interface ComponentProps {
  // eslint-disable-next-line no-unused-vars
  response: (form: FormData) => void;
}

const MentoringQuestions: React.FC<ComponentProps> = ({ response }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  return (
    <form onSubmit={handleSubmit(response)} style={{ width: '100%' }}>
      <IonRow className="row">
        <IonCol size="12">
          <h5>{t('mentoringQT1')}</h5>
          <div className="form__group field">
            <textarea
              className={`form__field ${errors.searchMentory && 'invalid'}`}
              id="searchMentory"
              rows={3}
              maxLength={1500}
              placeholder={t('mentoringQT1_placeholder')}
              {...register('searchMentory', { required: true })}
            />
            {errors.searchMentory && (
            <IonText color="danger">{t('required')}</IonText>
            )}
          </div>
        </IonCol>
        <IonCol size="12">
          <h5>{t('mentoringQT2')}</h5>
          <div className="form__group field">
            <textarea
              className={`form__field ${errors.needMentory && 'invalid'}`}
              id="needMentory"
              rows={3}
              maxLength={1500}
              placeholder={t('mentoringQT2_placeholder')}
              {...register('needMentory', { required: true })}
            />
            {errors.needMentory && (
            <IonText color="danger">{t('required')}</IonText>
            )}
          </div>
        </IonCol>
        <IonButton
          shape="round"
          fill="outline"
          color="secondary"
          type="submit"
          expand="block"
        >
          {t('Continue')}
        </IonButton>
      </IonRow>
    </form>
  );
};

export default MentoringQuestions;
