/* eslint-disable camelcase */
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonText,
} from '@ionic/react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createOutline } from 'ionicons/icons';
import { AppContextState } from '../../../state/AppContext';
import ModalWrapper from '../../../components/modalWrapper/ModalWrapper';
import AboutModal from './AboutModal';

const About: React.FC = () => {
  const { t } = useTranslation();

  const contextState = useContext(AppContextState);

  const { user_profile } = contextState.profile;
  const { about } = user_profile;

  const [showModal, setshowModal] = useState<boolean>(false);

  return (
    <>
      <div className="section-title">
        <h2>{t('profileAbout')}</h2>
        <span className="title-border" />
      </div>
      <IonCard className="ion-no-margin">
        <IonCardHeader style={{ textAlign: 'right', paddingBottom: '0' }}>
          <IonButton
            data-testid="test-about-show-modal"
            onClick={() => setshowModal(true)}
            fill="clear"
            shape="round"
          >
            <IonIcon slot="icon-only" icon={createOutline} />
          </IonButton>
        </IonCardHeader>
        <IonCardContent>
          {about && about.aboutBody && <IonText>{about.aboutBody}</IonText>}
        </IonCardContent>
      </IonCard>
      {showModal
        && (
        <ModalWrapper isOpen={showModal} setIsOpen={() => setshowModal(false)}>
          <AboutModal showModal={() => setshowModal(false)} />
        </ModalWrapper>
        )}
    </>
  );
};

export default About;
