/* eslint-disable camelcase */
import {
  IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonButton,
} from '@ionic/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContextState } from '../../../state/AppContext';

const Stripe: React.FC = () => {
  const { t } = useTranslation();

  const contextState = useContext(AppContextState);
  const { expertUrlStripe } = contextState;

  const sendToStripe = () => {
    const token = sessionStorage.getItem('jwt') || '';
    localStorage.setItem('jwt', token);
    window.open(`https://connect.stripe.com/express/oauth/authorize?redirect_uri=${process.env.REACT_APP_STRIPE_CALLBACK}&client_id=${process.env.REACT_APP_STRIPE_ID}&state=${token}`, '_blank');
  };

  const showStripeDashboard = () => {
    window.open(expertUrlStripe, '_blank');
  };

  return (
    <>
      <div className="section-title ion-margin-top">
        {expertUrlStripe === undefined
          ? <h2 className="px-3">{t('signinToStripeTitle')}</h2>
          : <h2 className="px-3">{t('goStripeDashTitle')}</h2>}
        <span className="title-border" />
      </div>
      <IonCard className="ion-no-margin">
        <IonCardHeader>
          <IonCardSubtitle>
            <p>{t(expertUrlStripe === undefined ? 'signinToStripe' : 'goStripeDash')}</p>
            <p>{t(expertUrlStripe === undefined ? 'signinToStripe1' : '')}</p>
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonButton
            data-testid="test-user-button"
            fill="outline"
            shape="round"
            onClick={() => (expertUrlStripe === undefined ? sendToStripe() : showStripeDashboard())}
          >
            {t('profileExpertStripe')}
          </IonButton>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default Stripe;
