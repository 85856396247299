/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  IonAlert,
  IonButton,
  IonChip,
  IonIcon,
  IonRow,
  IonText,
} from '@ionic/react';
import { expandOutline, timeOutline } from 'ionicons/icons';
import * as luxon from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentDetail } from '../../../interfaces/clientInterfaces';
import { AppContextState } from '../../../state/AppContext';

interface ComponentProps {
  service: AppointmentDetail;
  fullScreen: () => void;
  showComments: () => void;
}

const SMHeader: React.FC<ComponentProps> = ({
  service,
  fullScreen,
  showComments,
}) => {
  let countDown: any;
  const { t } = useTranslation();
  const contextState = useContext(AppContextState);
  const [timerClasslist, setTimerClasslist] = useState<string[]>([]);
  const [remainingTime, setRemainingTime] = useState<string>('00:00:00');
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { user_profile } = contextState.profile;
  const userToShow = user_profile.type === 'Client' ? service.expert : service.client;

  useEffect(() => {
    countDown = setInterval(() => {
      const end = luxon.DateTime.fromISO(service.end_date);
      const final = end.diff(luxon.DateTime.now());
      const remaining = luxon.Duration.fromObject(final.toObject()).toFormat(
        'hh:mm:ss',
      );
      setRemainingTime(remaining);
      if (final.milliseconds >= 0) {
        if (final.milliseconds > 600000) {
          setTimerClasslist([]);
        }
        if (final.milliseconds <= 600000 && final.milliseconds >= 300000) {
          setTimerClasslist([
            'timerOut',
            'animate__animated',
            'animate__pulse',
            'animate__infinite',
          ]);
        }
        if (final.milliseconds <= 300000 && final.milliseconds >= 0) {
          setTimerClasslist([
            'timerOut5',
            'animate__animated',
            'animate__pulse',
            'animate__infinite',
          ]);
        }
      } else {
        setTimerClasslist(['timerOut5']);
        setRemainingTime('00:00:00');
        clearInterval(countDown);
        countDown = false;
        showComments();
      }
    }, 1000);
    return () => {
      clearInterval(countDown);
    };
  }, []);

  return (
    <>
      <IonRow className="ion-justify-content-between ion-align-items-center">
        <IonText color="tertiary">
          <h3>
            {t('chatWith')}
            {' '}
            {userToShow.first_name}
          </h3>
        </IonText>
        <IonButton
          expand="block"
          color="primary"
          shape="round"
          onClick={() => setShowAlert(true)}
        >
          {t('endCall')}
        </IonButton>
        <IonButton
          className="ion-no-padding  action-button"
          expand="block"
          color="secondary"
          fill="outline"
          onClick={() => fullScreen()}
        >
          <IonIcon slot="icon-only" icon={expandOutline} />
        </IonButton>
      </IonRow>
      <IonRow className="ion-justify-content-end">
        <IonChip
          className={timerClasslist.join(' ')}
          color={
            timerClasslist.includes('timerOut')
              ? 'warning'
              : timerClasslist.includes('timerOut5')
                ? 'danger'
                : 'primary'
          }
        >
          <IonIcon icon={timeOutline} className="ion-margin-end" />
          {remainingTime}
        </IonChip>
      </IonRow>
      <IonAlert
        isOpen={!!showAlert}
        onDidDismiss={() => setShowAlert(false)}
        mode="ios"
        header={t('endCall')}
        message={t('endCallMessage')}
        buttons={[
          {
            text: t('cancel'),
            role: 'cancel',
            handler: () => setShowAlert(false),
          },
          {
            text: t('accept'),
            cssClass: 'secondary',
            handler: () => showComments(),
          },
        ]}
      />
    </>
  );
};

export default SMHeader;
