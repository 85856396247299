import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';
import './social.scss';

const SocialNetworks = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const shareUrl = language === 'es'
    ? 'https://mindswithpurpose.com/es/lp/'
    : 'https://mindswithpurpose.com/lp/';
  return (
    <div id="social-media-share">
      <h4 className="ion-hide">{t('ss_title')}</h4>
      <p className="ion-hide">{t('ss_body')}</p>
      <div className="buttons">
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon />
        </LinkedinShareButton>
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon />
        </TwitterShareButton>
        <WhatsappShareButton url={shareUrl}>
          <WhatsappIcon />
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default SocialNetworks;
