import {
  IonCard, IonCardContent, IonCol, IonGrid, IonRow,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Workhistory } from '../../interfaces';

interface ComponentProps {
    works: Workhistory[] | undefined;
}

const WorkExperience: React.FC<ComponentProps> = ({ works }) => {
  const { t } = useTranslation();
  return (
    <IonGrid>
      <div className="section-title text-center">
        <h2>{t('EMP_L10')}</h2>
        <span className="title-border" />
      </div>
      <IonRow>
        {
            works ? works.map((industrie) => {
              const from = new Date(industrie.from);
              const fromYear = from.getFullYear();
              const toDate = industrie.to !== 'present' ? new Date(industrie.to) : industrie.to;
              const toYear = industrie.to !== 'present' ? new Date(toDate).getFullYear() : toDate;
              return (
                <IonCol key={`${industrie.company}${industrie.from}`} size="12" sizeMd="6" sizeLg="4" className="industrieCard">
                  <IonCard>
                    <IonCardContent className="ion-text-left">
                      <h3>{industrie.job_title}</h3>
                      <h5>{industrie.company}</h5>
                      <p className="company">{industrie.location}</p>
                      <p className="timeLapse">
                        {fromYear}
                        {' '}
                        -
                        {' '}
                        {toYear}
                      </p>
                      <p className="field">{industrie.industry_field}</p>
                      <p className="website"><a href={`https://${industrie.company_website}`} rel="noopener noreferrer" target="_blank">{industrie.company_website}</a></p>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              );
            }) : (
              <div>
                <p>{t('EMP_L3')}</p>
              </div>
            )
          }
      </IonRow>
    </IonGrid>
  );
};

export default WorkExperience;
