import {
  IonButton, IonChip, IonCol, IonGrid, IonRow,
} from '@ionic/react';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import AppointmentsList from '../../components/userProfile/AppointmentsList';
import PaymentsTable from '../../components/userProfile/PaymentsTable';
import useBaseInformation from '../../hooks/useBaseInformation';
import useClientDashboard from '../../hooks/useClientDashboard';
import useGetAllAppointments from '../../hooks/useGetAllAppointments';
import { AppContextState } from '../../state/AppContext';
import ClientCommunity from './components/client/ClientCommunity';
import './dashboard.scss';

const ClientDashboard: React.FC = () => {
  const { t } = useTranslation();

  const [validateBaseInformation] = useBaseInformation();
  const [validateClientDashboard] = useClientDashboard();
  const { compareAppointments } = useGetAllAppointments();
  const contextState = useContext(AppContextState);
  const { allAppointments } = contextState;
  let appointmentsInterval: any;
  const history = useHistory();

  useEffect(() => {
    validateBaseInformation();
    validateClientDashboard();
  }, []);

  useEffect(() => {
    if (allAppointments && !appointmentsInterval) {
      appointmentsInterval = setInterval(() => compareAppointments(allAppointments), 25000);
    }
    return () => clearInterval(appointmentsInterval);
  }, [allAppointments]);

  return (
    <div id="dashboard">
      <h1>{t('clTitle')}</h1>
      <IonGrid>
        <IonRow>
          <IonCol>
            <div className="filtersContainer ion-padding">
              <IonChip className="header" onClick={() => history.push('./search')}>
                <h2 className="my-3">{t('findExpert')}</h2>
                <IonButton data-testid="to-search-btn" color="light" shape="round" onClick={() => history.push('./search')}>{t('newSearch')}</IonButton>
                <br />
              </IonChip>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <ClientCommunity />
      <AppointmentsList />
      <PaymentsTable />
    </div>
  );
};

export default ClientDashboard;
