import React from 'react';
import { IonContent } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const TermsModal: React.FC = () => {
  const { t } = useTranslation();
  return (
    <IonContent data-testid="test-terms-modal-container">
      <div className="ion-padding">
        <h3>{t('Terms')}</h3>
        <a href="#nav-experts">{t('Expert')}</a>
        {' | '}
        <a href="#nav-clients">{t('SearchExpert')}</a>
        <div id="nav-experts">
          <div dangerouslySetInnerHTML={{ __html: t('ExpertTermsText') }} />
        </div>
        <div id="nav-clients">
          <div dangerouslySetInnerHTML={{ __html: t('ClientTermsText') }} />
        </div>
      </div>
    </IonContent>
  );
};

export default TermsModal;
