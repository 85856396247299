/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentDetail } from '../interfaces/clientInterfaces';
import {
  getAllAppointments,
  getAppointmentsStatus,
  getSingleAppointment,
} from '../services/client';
import { AppContextDispatch, AppContextState } from '../state/AppContext';
import useNotifications from './useNotifications';

const useGetAllAppointments = () => {
  const { t } = useTranslation();
  const jwt: string = sessionStorage.getItem('jwt') || '';
  const contextState = useContext(AppContextState);
  const contextDispatch = useContext(AppContextDispatch);
  const { validNotifications, canNotify } = useNotifications();

  const { profile } = contextState;

  const { user_profile } = profile;
  const userAppointmentType = user_profile.type === 'Client' ? 'c' : 'e';

  const dispatchFunction = (type: string, value: any) => {
    const payload: object = {
      [type]: value,
    };
    contextDispatch({
      type: `UPDATE_${type.toUpperCase()}`,
      payload,
    });
  };

  useEffect(() => {
    validNotifications();
  }, []);

  const getAppointments = async () => {
    try {
      await getAllAppointments(jwt, user_profile.id, userAppointmentType).then(
        ({ data }) => {
          if (data && data.length > 0) {
            dispatchFunction('allAppointments', data);
          }
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  const compareAppointments = async (appList: AppointmentDetail[]) => {
    try {
      await getAppointmentsStatus(jwt).then(({ data }) => {
        validateAppointmentsChanges(data, appList);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const validateAppointmentsChanges = async (
    toCompare: AppointmentDetail[],
    listed: AppointmentDetail[],
  ) => {
    let forceRegetAppointments = false;
    listed.forEach((element) => {
      if (!toCompare.some((e) => e.id === element.id)) {
        forceRegetAppointments = true;
      }
    });
    if (forceRegetAppointments) {
      getAppointments();
    }
    if (toCompare.length > 0 && listed) {
      toCompare.forEach(async (element) => {
        const currentAppointment = listed.find((o) => o.id === element.id);
        // TODO: validar cuando hay reschedule y el cliente cambia la fecha otra vez
        const isValid = currentAppointment
          ? currentAppointment.type === element.type : 'No existe';
        // const isRescheduled = (currentAppointment && currentAppointment.is_reschedule)
        //   && currentAppointment.reschedule_date === element.reschedule_date;
        // console.log(isRescheduled);
        if (isValid === 'No existe') {
          getAppointments().then(() => {
            if (canNotify) NotifyToUser('notifyNew');
          });
        } else if (!isValid) {
          /* istanbul ignore next */
          await getSingleAppointment(jwt, element.id)
            .then(({ data }) => {
              const newData = [...listed];
              const index = newData.findIndex((item) => item.id === data.id);
              newData.splice(index, 1, data);
              dispatchFunction('allAppointments', newData);
              if (canNotify) NotifyToUser('notifyUpdate');
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }
  };

  /* istanbul ignore next */
  const NotifyToUser = (message: string) => {
    if (canNotify) {
      const notify = new Notification(t('notifyTitle'), {
        body: t(message),
        icon: '../assets/images/logo.png',
      });
      notify.onclick = () => notify.close();
    }
  };

  return { getAppointments, compareAppointments };
};

export default useGetAllAppointments;
