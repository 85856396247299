/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonIcon,
  IonRow,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tippy';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { createOutline } from 'ionicons/icons';
import { AppContextState } from '../../../state/AppContext';
import ModalWrapper from '../../../components/modalWrapper/ModalWrapper';
import ServicesModal from './ServicesModal';

const Services: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const contextState = useContext(AppContextState);

  const { services } = contextState;
  const { user_profile } = contextState.profile;

  const [showModal, setshowModal] = useState<boolean>(false);
  const [expertServices, setExpertServices] = useState<any>({
    expressCheck: false,
    mentoringCheck: false,
    coachingCheck: false,
  });

  const handleServiceClick = () => {
    if (user_profile.type === 'Client') {
      history.push('./search');
    } else {
      setshowModal(true);
    }
  };

  useEffect(() => {
    const baseData: any = {};
    if (user_profile.services && user_profile.services.length > 0) {
      user_profile.services.forEach((element: string) => {
        baseData[element] = true;
      });
      setExpertServices({ ...baseData });
    }
  }, [user_profile.services]);

  return (
    <>
      <div className="section-title text-right">
        <h2>
          {t(
            user_profile.type === 'Client'
              ? 'clientMyServices'
              : 'expertMyServices',
          )}
        </h2>
        <span className="title-border" />
      </div>
      <IonCard className="request-services ion-no-margin">
        <IonCardHeader style={{ textAlign: 'right', paddingBottom: '0' }}>
          <IonButton
            data-testid="test-modal-button"
            onClick={() => handleServiceClick()}
            fill="clear"
            shape="round"
          >
            <IonIcon slot="icon-only" icon={createOutline} />
          </IonButton>
        </IonCardHeader>
        <IonCardContent>
          <IonRow>
            {services
              && services.map((service) => {
                const serviceIcon = service.type.includes('xpres')
                  ? 'express'
                  : service.type.includes('entorin')
                    ? 'mentoring'
                    : 'coaching';
                if (
                  service.type !== 'ProjectInMind'
                  && ((expertServices[`${serviceIcon}Check`] && user_profile.type === 'Expert')
                    || user_profile.type === 'Client')
                ) {
                  return (
                    <IonCol key={service.id} size="12" sizeLg="4">
                      {/* @ts-ignore */}
                      <Tooltip
                        title={
                          serviceIcon.includes('xpress')
                            ? t('expres_descripton')
                            : t(`${serviceIcon.toLowerCase()}_description`)
                        }
                      >
                        <IonCard
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleServiceClick()}
                          data-testid={`service-card-${service.type}`}
                          className="ion-padding text-center ion-no-margin"
                        >
                          <IonIcon
                            color="secondary"
                            className="services-card-icon"
                            src={`./assets/images/${serviceIcon}.svg`}
                          />
                          <IonCardHeader>
                            <IonCardTitle>
                              <h4 className="service-title">
                                {serviceIcon.includes('xpress')
                                  ? t('express_service')
                                  : t(`${serviceIcon.toLowerCase()}_service`)}
                              </h4>
                            </IonCardTitle>
                          </IonCardHeader>
                        </IonCard>
                      </Tooltip>
                    </IonCol>
                  );
                }
                return null;
              })}
          </IonRow>
        </IonCardContent>
      </IonCard>
      {
        showModal
      && (
      <ModalWrapper
        wrapperClass="bigModal"
        isOpen={showModal}
        setIsOpen={() => setshowModal(false)}
      >
        <IonContent>
          <ServicesModal showModal={() => setshowModal(false)} />
        </IonContent>
      </ModalWrapper>
      )
      }
    </>
  );
};

export default Services;
