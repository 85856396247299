/* eslint-disable camelcase */
import { useContext } from 'react';
import {
  getAllAppointments,
  getIndustries,
  getJobFunctions,
  getLanguages,
  getSeniority,
  getServices,
} from '../services/client';
import { AppContextDispatch, AppContextState } from '../state/AppContext';

const useBaseInformation = () => {
  const contextState = useContext(AppContextState);
  const contextDispatch = useContext(AppContextDispatch);

  const {
    industries,
    seniority,
    functions,
    languages,
    services,
    allAppointments,
    profile,
  } = contextState;

  const { user_profile } = profile;
  const userAppointmentType = user_profile.type === 'Client' ? 'c' : 'e';

  const dispatchFunction = (type: string, value: any) => {
    const payload: object = {
      [type]: value,
    };
    contextDispatch({
      type: `UPDATE_${type.toUpperCase()}`,
      payload,
    });
  };

  const validateBaseInformation = async () => {
    const jwt: string = sessionStorage.getItem('jwt') || '';
    try {
      if (!industries) { await getIndustries(jwt).then(({ data }) => dispatchFunction('industries', data)); }
      if (!seniority) { await getSeniority(jwt).then(({ data }) => dispatchFunction('seniority', data)); }
      if (!functions) { await getJobFunctions(jwt).then(({ data }) => dispatchFunction('functions', data)); }
      if (!languages) { await getLanguages(jwt).then(({ data }) => dispatchFunction('languages', data)); }
      if (!services) { await getServices(jwt).then(({ data }) => dispatchFunction('services', data)); }
      if (!allAppointments && user_profile.id > 0) {
        await getAllAppointments(jwt, user_profile.id, userAppointmentType).then(
          ({ data }) => dispatchFunction('allAppointments', data),
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return [validateBaseInformation];
};

export default useBaseInformation;
