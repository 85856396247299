import { useContext } from 'react';
import { State } from '../interfaces';
import { AppContextDispatch } from '../state/AppContext';

const useUpdateState = () => {
  const contextDispatch = useContext(AppContextDispatch);

  const updateState = (data: unknown, key: keyof State) => {
    const payload = {
      [key]: data,
    };
    contextDispatch({
      type: `UPDATE_${key.toUpperCase()}`,
      payload,
    });
  };

  return { updateState };
};

export default useUpdateState;
