import React, { useEffect, useState } from 'react';
import ResultCard from '../../components/clientSearch/ResultCard';
import CommunityRequest from '../../components/communityRequest/CommunityRequest';
import useBaseInformation from '../../hooks/useBaseInformation';
import { ExpertDetail } from '../../interfaces/clientInterfaces';
import MainFilters from './components/MainFilters';
import './SearchExperts.scss';

const SearchExpert: React.FC = () => {
  const [validateBaseInformation] = useBaseInformation();

  const [searchResults, setSearchResults] = useState<ExpertDetail[]>([]);

  useEffect(() => {
    validateBaseInformation();
  }, []);

  return (
    <>
      {searchResults && (
        <MainFilters searchResults={setSearchResults} />
      )}
      {searchResults.length > 1 && (
      <>
        <br />
        <CommunityRequest />
      </>
      )}
      <br />
      {searchResults.length > 0 && searchResults?.map((expert) => (
        <ResultCard key={expert.id} expert={expert} />
      ))}
    </>
  );
};

export default SearchExpert;
