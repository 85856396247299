/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, {
  useContext, useState, useEffect, useRef, Ref,
} from 'react';
import {
  IonIcon,
  IonRow,
  IonSlides,
  IonSlide,
  IonCol,
  IonText,
  // IonCard,
  IonCardHeader,
  IonCardContent,
  IonBadge,
} from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useClientSearch from '../../../hooks/useClientSearch';
import { ClientFindData, FunctionsBase } from '../../../interfaces';
import { ExpertDetail } from '../../../interfaces/clientInterfaces';
import { AppContextState } from '../../../state/AppContext';
import { slugifyFilter } from '../../../utils';
import { ReactComponent as MktTools } from '../../../assets/images/services/mkt.svg';
import { ReactComponent as Operations } from '../../../assets/images/services/operations.svg';
import { ReactComponent as Strategy } from '../../../assets/images/services/strategy.svg';
import { ReactComponent as Leader } from '../../../assets/images/services/leader.svg';
import { ReactComponent as Legal } from '../../../assets/images/services/legal.svg';
import { ReactComponent as Accounting } from '../../../assets/images/services/accounting.svg';
import { ReactComponent as Compliant } from '../../../assets/images/services/compliant.svg';
import { ReactComponent as Technology } from '../../../assets/images/services/tech.svg';
import { ReactComponent as HumanR } from '../../../assets/images/services/rh.svg';
import { ReactComponent as Sales } from '../../../assets/images/services/sales.svg';
import { ReactComponent as Coaching } from '../../../assets/images/services/coaching.svg';
import useUpdateState from '../../../hooks/useUpdateState';

interface ComponentProps {
  // eslint-disable-next-line no-unused-vars
  searchResults: (data: ExpertDetail[]) => void;
}

const MainFilters: React.FC<ComponentProps> = ({ searchResults }) => {
  const { updateState } = useUpdateState();
  const { i18n } = useTranslation();
  const history = useHistory();
  const contextState = useContext(AppContextState);
  const { newSearch } = useClientSearch();

  const { client, functions, industries } = contextState;
  const { client_find_data } = client;

  const [searchData, setSearchData] = useState<ClientFindData>();
  const [searchCompleted, setSearchCompleted] = useState<boolean>(false);
  const [sortedFunctions, setSortedFunctions] = useState<FunctionsBase[]>();

  const [slideOpts, setSlideOpts] = useState({
    initialSlide: 0,
    speed: 400,
    slidesPerView:
      window.innerWidth >= 1000 ? '6' : window.innerWidth >= 760 ? '4' : '2',
    spaceBetween: 5,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  const updateSlides = () => {
    const perView = window.innerWidth >= 1000 ? '6' : window.innerWidth >= 760 ? '4' : '2';
    setSlideOpts({
      ...slideOpts,
      slidesPerView: perView,
    });
  };

  // eslint-disable-next-line no-undef
  const slidesRef = useRef() as Ref<HTMLIonSlidesElement>;
  const handleNext = () => (slidesRef as any).current?.slideNext();
  const handlePrev = () => (slidesRef as any).current?.slidePrev();

  const navigateTo = (indus: string, skl: string) => {
    history.push(slugifyFilter(indus, skl));
  };

  const requestExperts = async () => {
    if (searchData) {
      setSearchCompleted(true);
      const results = await newSearch(searchData);
      searchResults(results);
    }
  };

  const returnIcon = (appName: string) => {
    let Icon;
    if (appName === 'Operations') Icon = Operations;
    else if (appName === 'Marketing Tools and Automation') Icon = MktTools;
    else if (appName === 'Strategy And Planning') Icon = Strategy;
    else if (appName === 'Company Leader') Icon = Leader;
    else if (appName === 'Legal') Icon = Legal;
    else if (appName === 'Finance Accounting') Icon = Accounting;
    else if (appName === 'Risk compliance') Icon = Compliant;
    else if (appName === 'Technology Software') Icon = Technology;
    else if (appName === 'Human resources') Icon = HumanR;
    else if (appName === 'Sales and business development') Icon = Sales;
    else if (appName === 'Coaching') Icon = Coaching;
    else Icon = Operations;
    return <Icon style={{ width: '50px', height: 'auto' }} />;
  };

  useEffect(() => {
    if (industries && sortedFunctions && client_find_data) {
      const newSearchData = { ...client_find_data };
      // if (!newSearchData.industry.includes('0')) newSearchData.industry.push('0');
      // if (!newSearchData.job_function.includes(sortedFunctions[0].name)) {
      //   newSearchData.job_function.push(sortedFunctions[0].name);
      // }
      // if (!newSearchData.skills.includes(sortedFunctions[0].skills[0].name)) {
      //   newSearchData.skills.push(sortedFunctions[0].skills[0].name);
      // }
      newSearchData.industry = ['0'];
      newSearchData.job_function = [sortedFunctions[0].name];
      newSearchData.skills = [sortedFunctions[0].skills[0].name];
      newSearchData.language = i18n.language;
      newSearchData.seniority = '0';
      newSearchData.service = 'expressCheck';
      newSearchData.service_name = 'ExpressAdvice';
      setSearchData(newSearchData);
    }
  }, [client_find_data, industries, sortedFunctions]);

  useEffect(() => {
    if (searchData && searchData.industry.length > 0 && !searchCompleted) {
      requestExperts();
    }
  }, [searchData, searchCompleted]);

  useEffect(() => {
    window.addEventListener('resize', updateSlides);
    updateSlides();
    return () => window.removeEventListener('resize', updateSlides);
  }, []);

  useEffect(() => {
    if (functions) {
      const sorted = functions.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      setSortedFunctions(sorted);
    }
  }, [functions]);

  if (!sortedFunctions) return null;

  return (
    <>
      <IonRow id="main-search-filters">
        <IonSlides ref={slidesRef} id="functions-filters" options={slideOpts}>
          {sortedFunctions?.map(
            (item) => item.skills.length > 0
              && item.name !== 'Coaching' && (
                <IonSlide
                  onClick={() => {
                    updateState(undefined, 'searchSkillsArray');
                    navigateTo(item.name, item.skills[item.skills.length - 1].name);
                  }}
                  key={item.id}
                >
                  <IonCol>
                    <div className="filter-card" style={{ minHeight: '120px' }}>
                      <IonCardHeader>{returnIcon(item.name)}</IonCardHeader>
                      <IonCardContent>
                        <IonText color="secondary">
                          <h5>{item.name}</h5>
                        </IonText>
                      </IonCardContent>
                    </div>
                  </IonCol>
                </IonSlide>
            ),
          )}
        </IonSlides>
        <IonBadge className="nav-prev" onClick={handlePrev}>
          <IonIcon icon={chevronBack} />
        </IonBadge>
        <IonBadge className="nav-next" onClick={handleNext}>
          <IonIcon icon={chevronForward} />
        </IonBadge>
      </IonRow>
    </>
  );
};

export default MainFilters;
