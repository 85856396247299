/* eslint-disable camelcase */
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonRow,
  IonCol,
  IonText,
  IonItem,
  IonList,
} from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../../hooks/useFormatDate';

interface ServicesRequest {
  start_date: string;
  end_date: string;
  type: string;
}

interface ComponentProps {
  sessions: ServicesRequest[];
  // eslint-disable-next-line no-unused-vars
  deleteSession: (id: number) => void;
}

const SessionCards: React.FC<ComponentProps> = ({ sessions, deleteSession }) => {
  const { t } = useTranslation();
  const { formatIsoDate } = useFormatDate();

  return (
    <>
      <h5>{t('SA_L3')}</h5>
      <IonRow className="ion-justify-content-around">
        {sessions.map((session, index) => (
          <IonCol key={session.start_date} size="12" sizeLg="5">
            <IonCard>
              <IonCardHeader>
                <IonList lines="none">
                  <IonItem className="ion-no-border">
                    <IonText>{t('packDateDay', { date: formatIsoDate(session.start_date) })}</IonText>
                    <IonIcon
                      className="clickable"
                      slot="end"
                      icon={trashOutline}
                      onClick={() => deleteSession(index)}
                    />
                  </IonItem>
                </IonList>
              </IonCardHeader>
              <IonCardContent>
                <p>{t('packDateStart', { date: formatIsoDate(session.start_date) })}</p>
                <p>{t('packDateEnd', { date: formatIsoDate(session.end_date) })}</p>
                <p>{t('packDateStatus', { message: t('payOpen') })}</p>
              </IonCardContent>
            </IonCard>
          </IonCol>
        ))}
      </IonRow>
    </>
  );
};

export default SessionCards;
