import {
  IonButton, IonCol, IonGrid, IonRow,
} from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ErrorModal from '../../components/error/ErrorModal';
import LastPayInfo from '../../components/lastPay/LastPayInfo';
import ModalWrapper from '../../components/modalWrapper/ModalWrapper';
import Success from '../../components/success/Success';
import BillingForm from './BillingForm';
import './billing.scss';

const BillingInformation = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [updateError, setUpdateError] = useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  const navigateToProfile = () => {
    history.push('/user/profile');
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="12" sizeLg="8" style={{ margin: 'auto' }}>
          <div className="section-title">
            <h2 className="pr-3">{t('US_L10')}</h2>
            <span className="title-border" />
          </div>
          <BillingForm
            setError={() => setUpdateError(true)}
            setSuccess={() => setUpdateSuccess(true)}
          />
          <LastPayInfo />
        </IonCol>
      </IonRow>
      {updateError && (
        <ModalWrapper
          wrapperClass="ErrorModal"
          isOpen={updateError}
          setIsOpen={() => setUpdateError(false)}
        >
          <ErrorModal />
        </ModalWrapper>
      )}
      {updateSuccess && (
        <ModalWrapper
          wrapperClass="successModal"
          isOpen={updateSuccess}
          setIsOpen={() => setUpdateSuccess(false)}
        >
          <Success message={t('billingDataUpdateSuccess')} />
          <IonButton
            color="secondary"
            onClick={navigateToProfile}
          >
            {t('profileModal')}
          </IonButton>
        </ModalWrapper>
      )}
    </IonGrid>
  );
};

export default BillingInformation;
