/* istanbul ignore file */
import { IonModal, IonButton } from '@ionic/react';
import React from 'react';
import ImageHandler from '../ImageHandler/ImageHandler';

export interface CloseButtonInterface {
  slot?: string;
  fill?: 'clear' | 'outline' | 'solid' | 'default' | undefined;
  color?: string;
  className?: string;
}

export interface ComponentProps {
  isOpen: boolean;
  setIsOpen: () => void;
  children: any;
  wrapperClass?: string;
  closeBtnProps?: CloseButtonInterface;
}

const defaultCloseBtnProps: CloseButtonInterface = {
  slot: 'icon-only',
  fill: 'clear',
  className: 'closeBtn',
  color: 'primary',
};

const ModalWrapper: React.FC<ComponentProps> = ({
  isOpen, setIsOpen, children, wrapperClass, closeBtnProps = { ...defaultCloseBtnProps },
}) => {
  const {
    slot, fill, className, color,
  } = closeBtnProps;
  return (
    <IonModal
      cssClass={wrapperClass}
      isOpen={isOpen}
      onDidDismiss={() => setIsOpen()}
    >
      <IonButton
        data-testid="test-close-modal"
        slot={slot}
        fill={fill}
        className={`ion-no-padding ${className}`}
        color={color}
        onClick={() => setIsOpen()}
      >
        {ImageHandler({ imageName: 'cancel', extension: 'svg', slot: 'icon-only' })}
      </IonButton>
      {children}
    </IonModal>
  );
};

export default ModalWrapper;
