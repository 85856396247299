/* eslint-disable no-unused-vars */
import {
  IonButton,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
} from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeKeeper from 'react-timekeeper';
import useFormatDate from '../../../hooks/useFormatDate';
import useLoader from '../../../hooks/useLoader';
import { NewEventType, ExpertAvailabilities } from '../../../interfaces';
import {
  createNewAvailability,
  deleteNewAvailability,
} from '../../../services/expert';

type dataEvent = NewEventType & ExpertAvailabilities;

interface ComponentProps {
  edition: boolean;
  showModal: () => void;
  event: dataEvent;
}

const AvailabilitiesModal: React.FC<ComponentProps> = ({
  edition,
  showModal,
  event,
}) => {
  const { t } = useTranslation();
  const jwt = sessionStorage.getItem('jwt') || '';
  const { formatIsoDate } = useFormatDate();
  const { setLoader } = useLoader();

  const [displayStart, setdisplayStart] = useState(false);
  const [displayEnd, setdisplayEnd] = useState(false);
  const [newEvent, setNewEvent] = useState<dataEvent>(event);
  const [canDelete, setCanDelete] = useState(false);
  const [deleteFrequency, setDeleteFrequency] = useState<string>('');
  const [isValidDate, setIsValidDate] = useState(false);

  const NewStart = new Date(newEvent.start).toISOString();
  const NewEnd = new Date(newEvent.end).toISOString();

  const updateEvent = () => {
    setLoader(true);
    const finalEvent = {
      title: newEvent.title,
      date_time_start: newEvent.start,
      date_time_end: newEvent.end,
      frequency: newEvent.frequency,
    };
    finalEvent.title = `${formatIsoDate(
      new Date(newEvent.start).toISOString(),
      'hh:mm a',
      'en',
    )} - ${formatIsoDate(
      new Date(newEvent.end).toISOString(),
      'hh:mm a',
      'en',
    )}`;
    if (edition) {
      const { frequency } = finalEvent;
      deleteNewAvailability(newEvent.id, frequency, jwt)
        .then((response) => {
          /* istanbul ignore next */
          if (response.status === 200) {
            createNewAvailability(finalEvent, jwt)
              .then(() => {
                showModal();
              })
              .catch(() => showModal());
          }
        })
        .catch(() => showModal());
    } else {
      createNewAvailability(finalEvent, jwt)
        .then(/* istanbul ignore next */() => {
          showModal();
        })
        .catch(() => showModal());
    }
  };

  const deleteConfirm = () => {
    setLoader(true);
    deleteNewAvailability(newEvent.id, deleteFrequency, jwt)
      .then(() => showModal())
      .catch(() => showModal());
  };

  const deleteEvent = (option: string) => {
    if (option.toLowerCase() === 'select one') {
      setCanDelete(false);
      setDeleteFrequency('');
      return;
    }
    setCanDelete(true);
    setDeleteFrequency(option);
  };

  useEffect(() => {
    const validStart = DateTime.fromISO(NewStart);
    const validEnd = DateTime.fromISO(NewEnd);
    const endControl = validEnd.diff(validStart);
    setIsValidDate(endControl.milliseconds > 0);
  }, [newEvent]);

  return (
    <div className="modalInformation ion-padding" style={{ width: '100%' }}>
      <h3>
        {t('EA_L1')}
        {' '}
        {formatIsoDate(NewStart, 'dd LLL y', 'en')}
      </h3>
      {!isValidDate && (
        <IonChip color="warning ion-margin-top">
          <IonIcon icon={alertCircleOutline} className="ion-margin-end" />
          {t('expertAvailabilitieInvalid')}
        </IonChip>
      )}
      <IonGrid>
        <form onSubmit={(e) => e.preventDefault()}>
          <IonRow>
            <IonCol size="12" sizeLg="6" className="form__group field">
              <input
                type="text"
                className="form__field"
                name="time-start"
                id="time-start"
                placeholder="Time Zone"
                value={formatIsoDate(NewStart, 'hh:mm a', 'en')}
                onClick={() => setdisplayStart(true)}
                readOnly
              />
              <label htmlFor="time-start" className="form__label">
                {t('EA_L2')}
              </label>
              {displayStart && (
                <TimeKeeper
                  time={formatIsoDate(NewStart, 't', 'en')}
                  onChange={/* istanbul ignore next */(e) => {
                    const newState = { ...newEvent };
                    const hour = e.formatted24.split(':');
                    const newHour = DateTime.fromISO(
                      new Date(newState.start).toISOString(),
                    ).set({
                      hour: parseInt(hour[0], 10),
                      minute: parseInt(hour[1], 10),
                    });
                    newState.start = newHour.toISO();
                    setNewEvent(newState);
                  }}
                  onDoneClick={() => {
                    setdisplayStart(false);
                  }}
                />
              )}
            </IonCol>
            <IonCol size="12" sizeLg="6" className="form__group field">
              <input
                type="text"
                className="form__field"
                name="time-end"
                id="time-end"
                placeholder="Time Zone"
                value={formatIsoDate(NewEnd, 't', 'en')}
                onClick={() => setdisplayEnd(true)}
                readOnly
              />
              <label htmlFor="time-end" className="form__label">
                {t('EA_L3')}
              </label>
              {displayEnd && (
                <TimeKeeper
                  time={formatIsoDate(NewEnd, 'hh:mm a', 'en')}
                  onChange={/* istanbul ignore next */(e) => {
                    const newState = { ...newEvent };
                    const hour = e.formatted24.split(':');
                    const newHour = DateTime.fromISO(
                      new Date(newState.end).toISOString(),
                    ).set({
                      hour: parseInt(hour[0], 10),
                      minute: parseInt(hour[1], 10),
                    });
                    newState.end = newHour.toISO();
                    setNewEvent(newState);
                  }}
                  onDoneClick={() => {
                    setdisplayEnd(false);
                  }}
                />
              )}
            </IonCol>
            <IonCol size="12" sizeLg="6" className="form__group field">
              <select
                className="form__field"
                id="eventFrequency"
                onChange={(e) => setNewEvent({
                  ...newEvent,
                  frequency: e.target.value,
                })}
                value={newEvent.frequency}
              >
                <option value="once">{t('EA_L5')}</option>
                <option value="weekly">{t('EA_L6')}</option>
                <option value="daily">{t('EA_L7')}</option>
              </select>
              <label htmlFor="eventFrequency" className="form__label">
                {t('EA_L4')}
              </label>
            </IonCol>
            {edition && (
              <IonCol
                size="12"
                sizeLg="6"
                className="form__group field text-left"
              >
                <select
                  className="form__field"
                  id="deleteFrequency"
                  data-testid="delete-selector-btn"
                  onChange={(e) => deleteEvent(e.target.value)}
                >
                  <option value="select one">{t('selectDefault')}</option>
                  <option value="once">{t('EA_L8')}</option>
                  {newEvent.frequency !== 'once' && (
                    <option value="frequency">{t('EA_L9')}</option>
                  )}
                </select>
                <label htmlFor="eventFrequency" className="form__label">
                  {t('EA_L10')}
                </label>
                <IonButton
                  data-testid="delete-app-btn"
                  shape="round"
                  fill="outline"
                  color="warning"
                  onClick={() => deleteConfirm()}
                  disabled={!canDelete}
                >
                  {t('EA_L10')}
                </IonButton>
              </IonCol>
            )}
            <IonCol size="12" className="text-left">
              <IonButton
                shape="round"
                fill="outline"
                disabled={!isValidDate}
                onClick={() => updateEvent()}
              >
                {t('Update')}
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonGrid>
    </div>
  );
};

export default AvailabilitiesModal;
