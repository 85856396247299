import {
  IonCard, IonCardContent, IonCardHeader, IonCol, IonGrid, IonRow,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Careerhighlight } from '../../interfaces/clientInterfaces';

interface ComponentProps {
    highlightsArray: Careerhighlight[] | undefined;
}

const Highlights: React.FC<ComponentProps> = ({ highlightsArray }) => {
  const { t } = useTranslation();
  return (
    <IonGrid>
      <div className="section-title text-center">
        <h2>{t('EMP_L11')}</h2>
        <span className="title-border" />
      </div>
      <IonRow>
        {
            (highlightsArray && highlightsArray.length > 0)
              ? highlightsArray.map((career: Careerhighlight) => (
                <IonCol key={`${career.date_to}${career.date_from}`} size="12" sizeMd="6" sizeLg="4" className="careerHighlights">
                  <IonCard className="ion-text-left">
                    <IonCardHeader>
                      <h2 className="ion-padding-horizontal">{career.career_header}</h2>
                    </IonCardHeader>
                    <IonCardContent>
                      <p className="body-description">{career.career_description}</p>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              )) : (
                <div>
                  <p>{t('EMP_L4')}</p>
                </div>
              )
          }
      </IonRow>
    </IonGrid>
  );
};

export default Highlights;
