/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
} from '@ionic/react';
import { DateTime } from 'luxon';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import TimeKeeper from 'react-timekeeper';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../hooks/useFormatDate';
import { AppointmentDetail } from '../../interfaces/clientInterfaces';
import useLoader from '../../hooks/useLoader';
import { updateAppointment as updateNewAppointment } from '../../services/client';
import useGetAllAppointments from '../../hooks/useGetAllAppointments';
import { AppContextState } from '../../state/AppContext';
import RequestExpertAvailabilities from './RequestExpertAvailabilities';

type ValidEvents = 'accept' | 'reject' | 'reschedule';
type ValidRescheduleTypes = string | undefined;

interface ComponentProps {
  appoinment: AppointmentDetail;
  eventType: ValidEvents;
  closeModal: () => void;
  // eslint-disable-next-line no-unused-vars
  setEventType: (type: ValidEvents) => void;
}

const UpdateAppointment: React.FC<ComponentProps> = ({
  appoinment,
  eventType,
  closeModal,
  setEventType,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const { formatIsoDate } = useFormatDate();
  const { setLoader } = useLoader();
  const { getAppointments } = useGetAllAppointments();
  const [appoinmentUpdate, setAppoinmentUpdate] = useState(appoinment);
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<boolean>(false);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showTimePeeker, setShowTimePeeker] = useState(false);
  const [rescheduleDate, setRescheduleDate] = useState(
    appoinmentUpdate.reschedule_date || new Date().toISOString(),
  );
  const [newRescheduleMotive, setNewRescheduleMotive] = useState<string>('');
  const [clientRescheduleDate, setClientRescheduleDate] = useState<any>(undefined);
  const [activeSegment, setActiveSegment] = useState<ValidRescheduleTypes>('acceptReschedule');
  const [canSetNewReschedule, setCanSetNewReschedule] = useState(false);

  const contextState = useContext(AppContextState);
  const { profile, services } = contextState;
  const { user_profile } = profile;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  let statusMessage = 'Rescheduled';
  if (eventType === 'reject') statusMessage = 'cancelledApp';
  else if (eventType === 'accept') statusMessage = 'Accepted';

  const onSubmit = (data: any) => {
    if (eventType === 'accept') {
      setCanUpdate(true);
    }
    if (eventType === 'reject') {
      const cancelledByUser = user_profile.type === 'Client'
        ? appoinmentUpdate.user_id : appoinmentUpdate.expert_id;
      setAppoinmentUpdate({
        ...appoinmentUpdate,
        cancel_reason: data.cancel_reason,
        cancelled_by: cancelledByUser.toString(),
        cancelled_on: DateTime.now().toUTC().toString(),
      });
      setCanUpdate(true);
    }
    if (eventType === 'reschedule') {
      setAppoinmentUpdate({
        ...appoinmentUpdate,
        is_reschedule: true,
        reschedule_date: rescheduleDate,
        reschedule_motive: data.reschedule_motive,
      });
      setCanUpdate(true);
    }
  };

  /* istanbul ignore next */
  const clientAcceptReschedule = () => {
    const currentService = services?.find((service) => service.id === appoinmentUpdate.service_id);
    const newEndDate = DateTime.fromISO(appoinmentUpdate.reschedule_date)
      .plus({ minutes: currentService?.duration_in_min }).toString();
    setAppoinmentUpdate({
      ...appoinmentUpdate,
      start_date: appoinmentUpdate.reschedule_date,
      end_date: new Date(newEndDate).toISOString(),
    });
    setCanUpdate(true);
  };

  /* istanbul ignore next */
  const clientSetNewReschedule = (motive: string) => {
    const currentService = services?.find((service) => service.id === appoinmentUpdate.service_id);
    const newEndDate = DateTime.fromISO(clientRescheduleDate)
      .plus({ minutes: currentService?.duration_in_min }).toString();
    setAppoinmentUpdate({
      ...appoinmentUpdate,
      start_date: clientRescheduleDate,
      end_date: new Date(newEndDate).toISOString(),
      is_reschedule: true,
      reschedule_date: clientRescheduleDate,
      reschedule_motive: motive,
    });
    setCanUpdate(true);
  };

  const updateEditedAppointment = () => {
    setLoader(true);
    const appointmentEdited = {
      id: appoinmentUpdate.id,
      type:
        // eslint-disable-next-line no-nested-ternary
        eventType === 'reject'
          ? 'CancelledAppointment'
          : eventType === 'accept'
            ? 'ScheduledAppointment'
            : 'OpenAppointment',
      cancelled_on: appoinmentUpdate.cancelled_on,
      cancelled_by: appoinmentUpdate.cancelled_by,
      cancel_reason: appoinmentUpdate.cancel_reason,
      is_reschedule: appoinmentUpdate.is_reschedule,
      reschedule_motive: appoinmentUpdate.reschedule_motive,
      reschedule_date: appoinmentUpdate.reschedule_date,
      start_date: appoinmentUpdate.start_date,
      end_date: appoinmentUpdate.end_date,
    };
    const jwt = sessionStorage.getItem('jwt') || '';
    /* istanbul ignore next */
    updateNewAppointment(jwt, appointmentEdited)
      .then(() => {
        getAppointments();
        setUpdateSuccess(true);
        setUpdateError(false);
        setLoader(false);
      })
      .catch(() => {
        setUpdateSuccess(false);
        setUpdateError(true);
        setLoader(false);
      });
    setCanUpdate(false);
  };

  useEffect(() => {
    /* istanbul ignore next */
    if (appoinmentUpdate && canUpdate) updateEditedAppointment();
  }, [appoinmentUpdate, canUpdate]);

  useEffect(() => {
    /* istanbul ignore next */
    if (clientRescheduleDate) setValue('new_reschedule', formatIsoDate(clientRescheduleDate));
  }, [clientRescheduleDate]);

  useEffect(() => {
    /* istanbul ignore next */
    if (clientRescheduleDate && newRescheduleMotive.length > 3) setCanSetNewReschedule(true);
    else setCanSetNewReschedule(false);
  }, [clientRescheduleDate, newRescheduleMotive]);

  const AppointmetDetailBody = () => (
    <IonList lines="none">
      <IonListHeader>
        <h4 style={{ margin: 'auto' }}>{t('DC_L8')}</h4>
      </IonListHeader>
      <IonItem>
        <IonText slot="start">{t('DC_L20')}</IonText>
        <IonText slot="end">{appoinmentUpdate.client.first_name}</IonText>
      </IonItem>
      <IonItem>
        <IonText slot="start">{t('DC_L10')}</IonText>
        <IonText slot="end">
          {formatIsoDate(appoinmentUpdate.start_date, 'DDD')}
        </IonText>
      </IonItem>
      <IonItem>
        <IonText slot="start">{t('startAt')}</IonText>
        <IonText slot="end">
          {formatIsoDate(appoinmentUpdate.start_date, 't')}
        </IonText>
      </IonItem>
      <IonItem>
        <IonText slot="start">{t('endAt')}</IonText>
        <IonText slot="end">
          {formatIsoDate(appoinmentUpdate.end_date, 't')}
        </IonText>
      </IonItem>
      {
      appoinmentUpdate.is_reschedule
      && (
        <>
          <IonListHeader>
            <h4 style={{ margin: 'auto' }}>{t('rescheduleTitle')}</h4>
          </IonListHeader>
          <IonItem>
            <IonText slot="start">{t('reschedule_date')}</IonText>
            <IonText slot="end">
              {formatIsoDate(appoinmentUpdate.reschedule_date, 'DDD')}
            </IonText>
          </IonItem>
          <IonItem>
            <IonText slot="start">{t('reschedule_hour')}</IonText>
            <IonText slot="end">
              {formatIsoDate(appoinmentUpdate.reschedule_date, 'hh:mm a')}
            </IonText>
          </IonItem>
        </>
      )
    }
    </IonList>
  );

  const appointmentDetails = () => (
    <IonRow>
      <IonCol size="12">
        <h3>{t('DC_L7', { message: t(statusMessage) })}</h3>
        {AppointmetDetailBody()}
        <IonButton onClick={() => closeModal()}>{t('Continue')}</IonButton>
      </IonCol>
    </IonRow>
  );

  const acceptForm = () => (
    <form
      style={{ width: '100%' }}
      data-testid="test-reject-form"
      className="stepComponent ion-padding"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h3 style={{ maxWidth: '400px', margin: 'auto' }}>
        {t('appAcceptTitle')}
      </h3>
      {AppointmetDetailBody()}
      <IonButton
        id="updateAboutInformation"
        type="submit"
        fill="outline"
        shape="round"
        size="small"
      >
        {t('Continue')}
      </IonButton>
    </form>
  );

  const cancelForm = () => (
    <form
      style={{ width: '100%' }}
      data-testid="test-reject-form"
      className="stepComponent ion-padding"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h3 style={{ maxWidth: '400px', margin: 'auto' }}>
        {t('appCancelTitle')}
      </h3>
      <p>
        <IonText color="warning">{t('appCancelReschedule')}</IonText>
      </p>
      <IonButton
        type="button"
        shape="round"
        size="small"
        onClick={() => setEventType('reschedule')}
      >
        {t('rescheduleBtn')}
      </IonButton>
      {AppointmetDetailBody()}
      <div className="form__group field ion-margin-top">
        <textarea
          data-testid="test-reject-area"
          className={`form__field ${errors.cancel_reason ? ' invalid ' : ''}`}
          rows={8}
          defaultValue={appoinmentUpdate.cancel_reason || ''}
          {...register('cancel_reason', { required: true })}
        />
        <label htmlFor="cancel_reason" className="form__label">
          {t('DC_L12')}
        </label>
        <div className="text-right">
          {errors.cancel_reason && errors.cancel_reason.type === 'required' && (
            <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
          )}
        </div>
      </div>
      <IonButton
        id="updateAboutInformation"
        type="submit"
        fill="outline"
        shape="round"
        size="small"
      >
        {t('Continue')}
      </IonButton>
    </form>
  );

  const rescheduleForm = () => (
    <form
      style={{ width: '100%' }}
      data-testid="test-reject-form"
      className="stepComponent ion-padding"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h3 style={{ maxWidth: '400px', margin: 'auto' }}>
        {t('rescheduleTitle')}
      </h3>
      <IonRow>
        {appoinmentUpdate.is_reschedule && (
          <IonCol
            size="12"
            sizeLg={appoinmentUpdate.is_reschedule ? '6' : '12'}
          >
            <p className="h5">{t('rescheduleDataTitle')}</p>
            <p className="message">
              <span className="label">{t('reschedule_motive')}</span>
              <span>{appoinmentUpdate.reschedule_motive}</span>
            </p>
            <p className="message">
              <span className="label">{t('reschedule_date')}</span>
              <span>{formatIsoDate(appoinmentUpdate.reschedule_date)}</span>
            </p>
          </IonCol>
        )}
        <IonCol size="12" sizeLg={appoinmentUpdate.is_reschedule ? '6' : '12'}>
          <IonList lines="none">
            <IonListHeader>
              <h4 style={{ margin: 'auto' }}>{t('DC_L8')}</h4>
            </IonListHeader>
            <IonItem>
              <IonText slot="start">{t('DC_L20')}</IonText>
              <IonText slot="end">{appoinmentUpdate.client.first_name}</IonText>
            </IonItem>
            <IonItem>
              <IonText slot="start">{t('DC_L10')}</IonText>
              <IonText slot="end">
                {formatIsoDate(appoinmentUpdate.start_date)}
              </IonText>
            </IonItem>
          </IonList>
          <div className="form__group field ion-margin-top">
            <textarea
              data-testid="test-reschedule-area"
              className={`form__field ${
                errors.reschedule_motive ? ' invalid ' : ''
              }`}
              rows={4}
              placeholder={t('reschedule_motive_placeholder')}
              {...register('reschedule_motive', { required: true })}
            />
            <label htmlFor="reschedule_motive" className="form__label">
              {t('reschedule_motive')}
            </label>
            <div className="text-right">
              {errors.reschedule_motive
                && errors.reschedule_motive.type === 'required' && (
                  <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
              )}
            </div>
          </div>
          <div className="form__group field ion-margin-top">
            <input
              type="text"
              className={`form__field ${
                errors.reschedule_date ? ' invalid ' : ''
              }`}
              {...register('reschedule_date', {
                required: true,
              })}
              value={formatIsoDate(rescheduleDate, 'DDD')}
              onFocus={() => setShowCalendar(true)}
            />
            {showCalendar && (
              <Calendar
                minDate={new Date()}
                locale={language}
                maxDetail="month"
                onChange={(e: any) => {
                  setRescheduleDate(new Date(e).toISOString());
                  setShowCalendar(false);
                }}
                defaultValue={
                  appoinmentUpdate.reschedule_date
                    ? new Date(appoinmentUpdate.reschedule_date)
                    : new Date()
                }
              />
            )}
            <label className="form__label" htmlFor="reschedule_date">
              {t('reschedule_date')}
            </label>
            {errors.reschedule_date
              && errors.reschedule_date.type === 'required' && (
                <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
            )}
          </div>
          <div className="form__group field ion-margin-top">
            <input
              type="text"
              className={`form__field ${
                errors.newHour ? ' invalid ' : ''
              }`}
              {...register('newHour', {
                required: true,
              })}
              value={formatIsoDate(rescheduleDate, 'hh:mm a', 'en')}
              onFocus={() => setShowTimePeeker(true)}
            />
            {showTimePeeker && (
              <TimeKeeper
                time={formatIsoDate(rescheduleDate, 'hh:mm a', 'en')}
                onChange={(e) => {
                  const hour = e.formatted24.split(':');
                  const newHour = DateTime.fromISO(
                    new Date(rescheduleDate).toISOString(),
                  ).set({
                    hour: parseInt(hour[0], 10),
                    minute: parseInt(hour[1], 10),
                  });
                  setRescheduleDate(newHour.toString());
                }}
                onDoneClick={() => {
                  setShowTimePeeker(false);
                }}
              />
            )}
            <label className="form__label" htmlFor="newHour">
              {t('rescheduleTimeZone')}
            </label>
            {errors.newHour
              && errors.newHour.type === 'required' && (
                <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
            )}
          </div>
          <IonButton
            id="updateAboutInformation"
            type="submit"
            fill="outline"
            shape="round"
            size="small"
          >
            {t('Continue')}
          </IonButton>
        </IonCol>
      </IonRow>
    </form>
  );

  const rescheduleClientForm = () => (
    <form
      style={{ width: '100%' }}
      data-testid="test-reject-form"
      className="stepComponent"
      onSubmit={handleSubmit(onSubmit)}
    >
      {
          appoinmentUpdate.is_reschedule
          && (
            <IonSegment
              scrollable
              color="secondary"
              value={activeSegment}
              onIonChange={(e) => setActiveSegment(e.detail.value)}
            >
              <IonSegmentButton
                data-testid="acceptReschedule"
                value="acceptReschedule"
              >
                {t('aceptExpert_service')}
              </IonSegmentButton>
              <IonSegmentButton
                data-testid="newReschedule"
                value="newReschedule"
              >
                {t('newDate_service')}
              </IonSegmentButton>
            </IonSegment>
          )
        }
      {
          (activeSegment === 'acceptReschedule' && appoinmentUpdate.is_reschedule)
            && (
              <>
                <IonCard>
                  <IonCardHeader>
                    <IonCardSubtitle>
                      {t('accept_expert_date')}
                    </IonCardSubtitle>
                    <IonCardTitle>
                      {formatIsoDate(appoinmentUpdate.reschedule_date)}
                    </IonCardTitle>
                  </IonCardHeader>
                </IonCard>
                <IonButton
                  id="updateAboutInformation"
                  type="button"
                  fill="outline"
                  shape="round"
                  size="small"
                  className="ion-margin-top"
                  onClick={() => clientAcceptReschedule()}
                >
                  {t('accept')}
                </IonButton>
              </>
            )
        }
      {
          (activeSegment === 'newReschedule' && appoinmentUpdate.is_reschedule)
            && (
              <>
                <div className="form__group field ion-margin-top">
                  <textarea
                    data-testid="test-reschedule-area"
                    className={`form__field ${
                      errors.reschedule_motive ? ' invalid ' : ''
                    }`}
                    rows={4}
                    placeholder={t('reschedule_motive_placeholder')}
                    {...register('reschedule_motive', { required: true })}
                    onInput={(e: any) => setNewRescheduleMotive(e.target.value)}
                  />
                  <label htmlFor="reschedule_motive" className="form__label">
                    {t('reschedule_motive')}
                  </label>
                  <div className="text-right">
                    {errors.reschedule_motive
                && errors.reschedule_motive.type === 'required' && (
                  <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
                    )}
                  </div>
                </div>
                {
                  clientRescheduleDate
                  && (
                  <div className="form__group field ion-margin-top">
                    <input
                      type="text"
                      className={`form__field ${
                        errors.new_reschedule ? ' invalid ' : ''
                      }`}
                      {...register('new_reschedule', {
                        required: true,
                      })}
                      disabled
                    />
                    <label className="form__label" htmlFor="new_reschedule">
                      {t('reschedule_date')}
                    </label>
                  </div>
                  )
                  }
                <RequestExpertAvailabilities
                  eventExpert={appoinmentUpdate.expert_id}
                  selectedAvailabilitie={(date) => {
                    setClientRescheduleDate(date);
                    setRescheduleDate(date);
                  }}
                />
                <IonButton
                  id="updateAboutInformation"
                  type="button"
                  fill="outline"
                  shape="round"
                  size="small"
                  className="ion-margin-top"
                  disabled={!canSetNewReschedule}
                  onClick={() => clientSetNewReschedule(newRescheduleMotive)}
                >
                  {t('Continue')}
                </IonButton>
              </>
            )
        }
      {
          !appoinmentUpdate.is_reschedule
          && (
            <>
              <div className="form__group field ion-margin-top">
                <textarea
                  data-testid="test-reschedule-area"
                  className={`form__field ${
                    errors.reschedule_motive ? ' invalid ' : ''
                  }`}
                  rows={4}
                  placeholder={t('reschedule_motive_placeholder')}
                  {...register('reschedule_motive', { required: true })}
                  onInput={(e: any) => setNewRescheduleMotive(e.target.value)}
                />
                <label htmlFor="reschedule_motive" className="form__label">
                  {t('reschedule_motive')}
                </label>
                <div className="text-right">
                  {errors.reschedule_motive
                && errors.reschedule_motive.type === 'required' && (
                  <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
                  )}
                </div>
              </div>
              <div className="form__group field ion-margin-top">
                <input
                  type="text"
                  className={`form__field ${
                    errors.new_reschedule ? ' invalid ' : ''
                  }`}
                  {...register('new_reschedule', {
                    required: true,
                  })}
                  disabled
                />
                <label className="form__label" htmlFor="new_reschedule">
                  {t('reschedule_date')}
                </label>
              </div>
              <RequestExpertAvailabilities
                eventExpert={appoinmentUpdate.expert_id}
                selectedAvailabilitie={(date) => {
                  setClientRescheduleDate(date);
                  setRescheduleDate(date);
                }}
              />
              <IonButton
                id="updateAboutInformation"
                type="button"
                fill="outline"
                shape="round"
                size="small"
                className="ion-margin-top"
                disabled={!canSetNewReschedule}
                onClick={() => clientSetNewReschedule(newRescheduleMotive)}
              >
                {t('Continue')}
              </IonButton>
            </>
          )
        }
    </form>
  );

  const clientRescheduleBody = () => (
    <>
      <h3 style={{ maxWidth: '400px', margin: 'auto' }}>
        {t('rescheduleTitle')}
      </h3>
      <IonRow>
        {appoinmentUpdate.is_reschedule && (
          <IonCol
            size="12"
            sizeLg={appoinmentUpdate.is_reschedule ? '4' : '12'}
          >
            <p className="h5">{t('rescheduleDataTitle')}</p>
            <p className="message">
              <span className="label">{t('reschedule_motive')}</span>
              <span>{appoinmentUpdate.reschedule_motive}</span>
            </p>
            <p className="message">
              <span className="label">{t('reschedule_date')}</span>
              <span>{formatIsoDate(appoinmentUpdate.reschedule_date)}</span>
            </p>
            {
            appoinmentUpdate.reschedule_date === appoinmentUpdate.start_date
            && (
              <IonChip color="success ion-margin-top">
                {t('clientHasAccept')}
              </IonChip>
            )
          }
          </IonCol>
        )}
        <IonCol size="12" sizeLg={appoinmentUpdate.is_reschedule ? '8' : '12'}>
          <IonList lines="none">
            <IonListHeader>
              <h4 style={{ margin: 'auto' }}>{t('DC_L8')}</h4>
            </IonListHeader>
            <IonItem>
              <IonText slot="start">{t('DC_L20')}</IonText>
              <IonText slot="end">{appoinmentUpdate.client.first_name}</IonText>
            </IonItem>
            <IonItem>
              <IonText slot="start">{t('DC_L10')}</IonText>
              <IonText slot="end">
                {formatIsoDate(appoinmentUpdate.start_date)}
              </IonText>
            </IonItem>
          </IonList>
          {rescheduleClientForm()}
        </IonCol>
      </IonRow>
    </>
  );

  const returnSuccessMessage = () => (
    <>
      <IonIcon className="successModal--Icon" icon={checkmarkCircleOutline} />
      <p className="main-message">{t('globalSuccess')}</p>
      {appointmentDetails()}
    </>
  );

  const returnErrorMessage = () => (
    <>
      <IonIcon className="ErrorModal--Icon" icon={closeCircleOutline} />
      <h3>Error</h3>
      <p>{t('globalError')}</p>
      <IonButton onClick={() => closeModal()}>{t('Continue')}</IonButton>
    </>
  );

  return (
    <>
      {eventType === 'accept'
        && !updateError
        && !updateSuccess
        && acceptForm()}
      {eventType === 'reject'
        && !updateError
        && !updateSuccess
        && cancelForm()}
      {eventType === 'reschedule'
        && !updateError
        && !updateSuccess
        && user_profile.type !== 'Client'
        && rescheduleForm()}
      {eventType === 'reschedule'
        && !updateError
        && !updateSuccess
        && user_profile.type === 'Client'
        && clientRescheduleBody()}
      {updateSuccess && returnSuccessMessage()}
      {updateError && returnErrorMessage()}
    </>
  );
};

export default UpdateAppointment;
