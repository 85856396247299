/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trashOutline } from 'ionicons/icons';
import {
  FunctionsBase,
  IndustriesBase,
  ProfileSkills,
} from '../../../interfaces';
import { AppContextState } from '../../../state/AppContext';
import { capitalizeString } from '../../../utils';
import useUpdateUser from '../../../hooks/useUpdateUser';

interface ComponentProps {
  edition: number | undefined;
  showModal: () => void
}

const SkillsModal: React.FC<ComponentProps> = ({ edition, showModal }) => {
  const { t } = useTranslation();
  const contextState = useContext(AppContextState);

  const { profile, industries, functions } = contextState;
  const { user_profile } = profile;
  const { skills } = user_profile;
  const { status, updateClient } = useUpdateUser();

  const indusBase: IndustriesBase[] = [];
  const funcBase: FunctionsBase[] = [];
  const updateSkill: boolean = false;
  const funObjBase: ProfileSkills[] = [];
  const [state, setState] = useState({
    updateSkill,
    job_functions: [''],
    functionObject: funObjBase,
    industries: indusBase,
    functions: funcBase,
    enable_button: true,
  });

  useEffect(() => {
    setBaseInformation();
  }, [industries, functions]);

  const setBaseInformation = () => {
    const fatherIndustries = industries && industries.length > 0;
    const fatherIFunctions = functions && functions.length > 0;
    if (
      state.industries
      && state.industries.length <= 0
      && state.functions.length <= 0
      && fatherIFunctions
      && fatherIndustries
      && industries
      && functions
    ) {
      setState({ ...state, industries, functions });
    }
  };

  useEffect(() => {
    if (state.functions.length > 0 && state.industries.length > 0) {
      validateEdit();
    }
    // eslint-disable-next-line
  }, [state.functions, state.industries]);

  const validateEdit = () => {
    if (state.industries && state.functions) {
      if (edition && skills) {
        const editData = [];
        editData.push(skills[edition - 1]);
        setState({
          ...state,
          updateSkill: !!edition,
          functionObject: editData,
        });
        renderSkill();
      }
    }
  };

  const renderIndustrieFieldsOptions = () => {
    const option = [];
    const current = state.functionObject.length > 0
      ? parseInt(state.functionObject[0].industry, 10)
      : undefined;
    option.push(
      `<option value="DEFAULT" default disabled>${t('selectDefault')}</option>`,
    );
    state.industries.forEach((element, index) => {
      const currentOption = `
      <option class="" ${
  current !== undefined && current === element.id
    ? 'selected'
    : index === 0
      ? 'selected'
      : ''
} value="${element.id}">
      ${capitalizeString(element.name)}
      </option>`;
      option.push(currentOption);
    });
    return (
      <select
        data-testid="test-industrie-selector"
        className="custom-select"
        onChange={() => validateFunction()}
        name="industry_field"
        id="industry_field"
        disabled={state.updateSkill}
        dangerouslySetInnerHTML={{ __html: option.join('') }}
      />
    );
  };

  const validateFunction = () => {
    const current = state.functionObject;
    const industry = (
      document.getElementById('industry_field') as HTMLInputElement
    ).value;
    if (current.length > 0) {
      current.forEach((element) => {
        try {
          if (element.industry === industry) {
            (document.getElementById('skills') as HTMLInputElement).disabled = false;
            (document.getElementById('functions') as HTMLInputElement).disabled = false;
          } else {
            (document.getElementById('skills') as HTMLInputElement).disabled = true;
            (document.getElementById('functions') as HTMLInputElement).disabled = true;
          }
        } catch (error) { console.log(error); }
      });
    }
  };

  const renderFunctionsOptions = () => {
    const option = [];
    option.push(
      `<option value="DEFAULT" default>${t('selectDefault')}</option>`,
    );
    functions?.forEach((element) => {
      const currentOption = `
      <option class="" value="${element.id}">
      ${capitalizeString(element.name)}
      </option>`;
      option.push(currentOption);
    });
    return (
      <select
        data-testid="test-function-selector"
        className="custom-select"
        onChange={(e) => setState({ ...state, job_functions: [e.target.value] })}
        name="functions"
        id="functions"
        dangerouslySetInnerHTML={{ __html: option.join('') }}
      />
    );
  };

  const renderSkillsOptions = () => {
    const option = [];
    const flag = state.job_functions
      ? state.functions.find(
        (o) => o.id === parseInt(state.job_functions[0], 10),
      )
      : undefined;
    const skillsValid = flag ? flag.skills : 0;
    if (skillsValid) {
      option.push(
        `<option value="DEFAULT" default disabled>${t(
          'selectDefault',
        )}</option>`,
      );
      skillsValid.reverse().forEach((element) => {
        const currentOption = `
        <option class="" value="${element.id}">
        ${capitalizeString(element.name)}
        </option>`;
        option.push(currentOption);
      });
      return (
        <select
          data-testid="test-skills-selector"
          className="custom-select"
          onChange={(event) => updateSkils(event)}
          name="skills"
          id="skills"
          dangerouslySetInnerHTML={{ __html: option.join('') }}
        />
      );
    }
    return null;
  };

  const updateSkils = (event: any) => {
    const allFunctions = state.functionObject;
    const industry = (
      document.getElementById('industry_field') as HTMLInputElement
    ).value;
    const job_functions = (
      document.getElementById('functions') as HTMLInputElement
    ).value;
    const skill = event.target.value;
    const newSkills: string[] = [];
    newSkills?.push(skill);
    const newFunctionObject: ProfileSkills = {
      industry,
      function: [
        {
          job_functions,
          skills: newSkills,
        },
      ],
    };
    if (allFunctions.length > 0) {
      allFunctions.forEach((arrayElement: any, pos) => {
        const element = allFunctions[pos];
        let flag = false;
        if (element.industry === newFunctionObject.industry) {
          if (
            element.function.some(
              (data) => data.job_functions
                === newFunctionObject.function[0].job_functions,
            )
          ) {
            element.function.forEach((functionArray, superIndex) => {
              if (
                functionArray.job_functions.indexOf(
                  newFunctionObject.function[0].job_functions,
                ) > -1
              ) {
                if (functionArray.skills.indexOf(skill) > -1) {
                  flag = false;
                  const elementArray = functionArray.skills;
                  const index = elementArray.indexOf(skill);
                  elementArray.splice(index, 1);
                  functionArray.skills = elementArray;
                  setState({ ...state, ...element });
                  if (functionArray.skills.length === 0) {
                    delete element.function[superIndex];
                  }
                } else {
                  flag = false;
                  functionArray.skills.push(skill);
                  setState({ ...state, ...element });
                }
              }
            });
          } else {
            flag = true;
          }
          if (flag) {
            element.function.push(newFunctionObject.function[0]);
            setState({ ...state, ...element });
          }
        } else {
          allFunctions.push(newFunctionObject);
          setState({ ...state, functionObject: allFunctions });
        }
      });
      canUpdate();
    } else {
      allFunctions.push(newFunctionObject);
      setState({ ...state, functionObject: allFunctions });
      canUpdate();
    }
  };

  /* istanbul ignore next */
  const deleteSkill = (array: any, element: any) => {
    const skillsArray = state.functionObject[0].function[array].skills;
    const stringId = element.toString();
    const index = skillsArray.indexOf(stringId);
    if (index >= 0) {
      skillsArray.splice(index, 1);
      const skillsState = { ...state };
      skillsState.job_functions = skillsArray;
      setState({ ...state, ...skillsState });
      canUpdate();
    }
  };

  const renderSkill = () => {
    const current = state.functionObject;
    const industry = state.industries;
    let industryName: any;
    let currentOption;
    let currentbody: any;
    if (
      current.length > 0
      && industry.length > 0
      && functions
      && functions.length > 0
    ) {
      currentOption = current.map((element) => {
        industryName = industry.find(
          (o) => o.id === parseInt(element.industry, 10),
        );
        if (element.function) {
          currentbody = element.function.map((userFunction, index) => {
            const object = functions.find(
              (o) => o.id === parseInt(userFunction.job_functions, 10),
            );
            const skillsArray = userFunction.skills.map((skill) => {
              const childSkill = object?.skills.find(
                (o) => o.id === parseInt(skill, 10),
              );
              return (
                <IonItem key={childSkill?.id}>
                  <IonLabel>
                    {childSkill?.name}
                  </IonLabel>
                  <IonIcon
                    style={{ cursor: 'pointer' }}
                    slot="end"
                    icon={trashOutline}
                    onClick={() => deleteSkill(index, childSkill?.id)}
                  />
                </IonItem>
              );
            });
            return (
              <IonList key={userFunction.job_functions}>
                <IonListHeader>
                  <IonLabel>
                    {object?.name}
                  </IonLabel>
                </IonListHeader>
                {skillsArray}
              </IonList>
            );
          });
        }
        return currentbody;
      });
      return (
        <IonCol size="12" sizeLg="4">
          <IonCard>
            <IonCardHeader>
              <IonCardTitle>
                <h2>{capitalizeString(industryName?.name || '')}</h2>
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              {currentOption}
            </IonCardContent>
          </IonCard>
        </IonCol>
      );
    }
    return <div />;
  };

  const canUpdate = () => {
    const currentData = state.functionObject;
    if (
      currentData
      && currentData[0]
      && currentData[0].function.length > 0
      && currentData[0].function[0]
      && currentData[0].function[0].skills.length > 0
    ) {
      setState({ ...state, enable_button: false });
    } else setState({ ...state, enable_button: true });
  };

  /* istanbul ignore next */
  const updateSkills = () => {
    const newState = state.functionObject[0];
    const newProfile = { ...user_profile };
    const newJobs = newProfile.skills ? [...newProfile.skills] : [];
    if (state.updateSkill !== undefined && edition) newJobs[edition - 1] = { ...newState };
    else newJobs.push(newState);
    newProfile.skills = newJobs;
    updateClient(newProfile);
  };

  useEffect(() => {
    if (status) showModal();
    if (status === false) showModal();
  }, [status]);

  return (
    <IonContent>
      <div className="stepComponent ion-padding">
        <h2>
          {t('profileFunctions')}
          {' '}
          <span>
            (
            {t('profileFunctionsSmallText')}
            )
          </span>
        </h2>
        <IonRow>
          <IonCol size="12" sizeLg="4">
            <h4>{t('Industry')}</h4>
            <div className="form-group">{renderIndustrieFieldsOptions()}</div>
          </IonCol>
          <IonCol size="12" sizeLg="4">
            <h4>{t('Function')}</h4>
            <div className="form-group">{renderFunctionsOptions()}</div>
          </IonCol>
          <IonCol size="12" sizeLg="4">
            <h4>{t('Skills')}</h4>
            <div className="form-group">{renderSkillsOptions()}</div>
          </IonCol>
        </IonRow>
        <IonRow>
          {renderSkill()}
        </IonRow>
        <IonButton
          className="ion-margin-vertical"
          fill="outline"
          shape="round"
          disabled={state.enable_button}
          onClick={() => updateSkills()}
        >
          {t('stepSave')}
        </IonButton>
      </div>
    </IonContent>
  );
};

export default SkillsModal;
