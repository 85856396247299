import {
  IonCol, IonGrid, IonRow, IonText,
} from '@ionic/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useBaseInformation from '../../hooks/useBaseInformation';
import About from './components/About';
import Billing from './components/Billing';
import ClientContactInfo from './components/ClientContactInfo';
import Languages from './components/Languages';
import ProfileHeader from './components/ProfileHeader';
import Services from './components/Services';
import WorkExperience from './components/WorkExperience';
import './profile.scss';
import './signupForm.scss';

const ClientProfile: React.FC = () => {
  const { t } = useTranslation();

  const [validateBaseInformation] = useBaseInformation();

  useEffect(() => {
    validateBaseInformation();
  }, []);

  return (
    <div id="dashboard">
      <h1>{t('profileWelcome')}</h1>
      <ProfileHeader />
      <IonText color="primary">{t('clientExcerpt')}</IonText>
      <ClientContactInfo />
      <IonGrid>
        <IonRow>
          <IonCol size="12" sizeLg="5">
            <About />
          </IonCol>
          <IonCol size="12" sizeLg="7">
            <Services />
          </IonCol>
          <IonCol size="12">
            <Languages />
          </IonCol>
          <IonCol size="12">
            <WorkExperience />
          </IonCol>
          <IonCol size="12" sizeLg="6">
            <Billing />
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ClientProfile;
