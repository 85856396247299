/* eslint-disable camelcase */
import {
  IonCard,
  IonCardHeader,
  IonButton,
  IonIcon,
  IonCardContent,
  IonRow,
  IonCol,
  IonCardTitle,
  IonButtons,
  IonCardSubtitle,
  IonAlert,
} from '@ionic/react';
import { createOutline, trashOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../../../components/modalWrapper/ModalWrapper';
import useUpdateUser from '../../../hooks/useUpdateUser';
import { AppContextState } from '../../../state/AppContext';
import WorkExperienceModal from './WorkExperienceModal';

const WorkExperience: React.FC = () => {
  const { t } = useTranslation();

  const contextState = useContext(AppContextState);

  const { user_profile } = contextState.profile;
  const { work_histories } = user_profile;

  const { updateClient } = useUpdateUser();

  const [showModal, setshowModal] = useState<boolean>(false);
  const [workEdition, setWorkEdition] = useState<number | undefined>(undefined);
  const [showAlert, setShowAlert] = useState<string | undefined>(undefined);

  /* istanbul ignore next */
  const closeModal = () => {
    setshowModal(false);
    setWorkEdition(undefined);
  };

  const editIndustrieFunction = (event: any) => {
    setWorkEdition(event.currentTarget.dataset.industrie);
    setshowModal(true);
  };

  /* istanbul ignore next */
  const deleteIndustrie = (event: string) => {
    const newProfile = { ...user_profile };
    const newHistories = [...newProfile.work_histories];
    newHistories.splice(parseInt(event, 10), 1);
    newProfile.work_histories = newHistories;
    updateClient(newProfile);
  };

  const renderWorksCards = () => (
    Object.entries(work_histories).map((industrie) => {
      const from = new Date(industrie[1].from);
      const fromYear = from.getFullYear();
      const toYear = industrie[1].to !== 'present' ? new Date(industrie[1].to).getFullYear() : industrie[1].to;
      return (
        <IonCol size="12" sizeLg="4" className="industrieCard" key={industrie[0]}>
          <IonCard className="ion-no-margin">
            <IonCardHeader>
              <IonCardSubtitle>
                <IonButtons className="ion-justify-content-end ion-no-padding">
                  <IonButton
                    data-industrie={industrie[0]}
                    onClick={(event) => editIndustrieFunction(event)}
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={createOutline}
                    />
                  </IonButton>
                  <IonButton
                    data-industrie={industrie[0]}
                    onClick={() => setShowAlert(industrie[0])}
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={trashOutline}
                    />
                  </IonButton>
                </IonButtons>
              </IonCardSubtitle>
              <IonCardTitle>
                {industrie[1].job_title}
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent className="card-body">
              <h5>{industrie[1].company}</h5>
              <p className="company">{industrie[1].location}</p>
              <p className="timeLapse">
                {fromYear}
                &nbsp; - &nbsp;
                {toYear}
              </p>
              <p className="field">{industrie[1].industry_field}</p>
              <p className="website"><a href={`https://${industrie[1].company_website}`} rel="noopener noreferrer" target="_blank">{industrie[1].company_website}</a></p>
            </IonCardContent>
          </IonCard>
        </IonCol>
      );
    })
  );

  return (
    <>
      <div className="section-title text-center ion-margin-top">
        <h2>{t(user_profile.type === 'Client' ? 'profileIndustries' : 'profileWorkExperience')}</h2>
        {user_profile.type === 'Client' && <span className="headerSubtitle">{t('profileIndustriesSubtitle')}</span>}
        <span className="title-border" />
      </div>
      <IonCard className="ion-no-margin">
        <IonCardHeader style={{ textAlign: 'right', paddingBottom: '0' }}>
          <IonButton
            data-testid="test-about-show-modal"
            onClick={() => setshowModal(true)}
            fill="clear"
            shape="round"
          >
            <IonIcon slot="icon-only" icon={createOutline} />
          </IonButton>
        </IonCardHeader>
        <IonCardContent>
          <IonRow>
            {work_histories && (renderWorksCards())}
          </IonRow>
        </IonCardContent>
      </IonCard>
      <IonAlert
        isOpen={!!showAlert}
        onDidDismiss={() => setShowAlert(undefined)}
        mode="ios"
        header={t('confirm_delete')}
        message={t('confirm_delete_body')}
        buttons={[
          {
            text: t('cancel'),
            role: 'cancel',
            handler: () => setShowAlert(undefined),
          },
          {
            text: t('accept'),
            cssClass: 'secondary',
            handler: () => deleteIndustrie(showAlert || ''),
          },
        ]}
      />
      {showModal && (
        <ModalWrapper wrapperClass="bigModal" isOpen={showModal} setIsOpen={() => closeModal()}>
          <WorkExperienceModal edition={workEdition} showModal={() => closeModal()} />
        </ModalWrapper>
      )}
    </>
  );
};

export default WorkExperience;
