/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonText,
  IonButton,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useUpdateUser from '../../../hooks/useUpdateUser';
import { AppContextState } from '../../../state/AppContext';

interface ComponentProps {
  showModal: () => void
}

type FormData = {
  aboutBody: string;
};

const AboutModal: React.FC<ComponentProps> = ({ showModal }) => {
  const { t } = useTranslation();
  const { status, updateClient } = useUpdateUser();
  const contextState = useContext(AppContextState);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const { user_profile } = contextState.profile;
  const { about } = user_profile;

  const [user, setUser] = useState({
    aboutBody: '',
    textLenght: 0,
  });
  const [canupdate, setCanUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (canupdate) {
      const newProfile = { ...user_profile };
      const newAbout = { ...about };
      newAbout.aboutBody = user.aboutBody;
      newProfile.about = newAbout;
      updateClient(newProfile);
    }
  }, [canupdate]);

  useEffect(() => {
    if (status) showModal();
    if (status === false) showModal();
  }, [status]);

  useEffect(() => {
    setUser({
      aboutBody: about.aboutBody ? about.aboutBody : '',
      textLenght: about.aboutBody ? about.aboutBody.length : 0,
    });
  }, [about]);

  const onSubmit = (data: FormData) => {
    setUser({
      ...user,
      aboutBody: data.aboutBody,
    });
    setCanUpdate(true);
  };

  return (
    <IonCard className="ion-padding" style={{ width: '100%' }}>
      <IonCardHeader>
        <IonCardTitle>
          <h2 className="ion-no-margin">{t('profileAbout')}</h2>
        </IonCardTitle>
      </IonCardHeader>
      <form data-testid="test-about-form" className="stepComponent" onSubmit={handleSubmit(onSubmit)}>
        <div className="form__group field">
          <textarea
            data-testid="test-about-area"
            className={`form__field ${errors.aboutBody ? ' invalid ' : ''}`}
            rows={8}
            maxLength={500}
            defaultValue={user.aboutBody}
            {...register('aboutBody', { required: true, minLength: 250 })}
            onInput={(e: any) => setUser({ ...user, textLenght: e.target.value.length })}
          />
          <label htmlFor="aboutBody" className="form__label">
            {t('step1Text')}
          </label>
          <div className="text-right">
            {errors.aboutBody && errors.aboutBody.type === 'required' && (
              <IonText color="danger">
                {t('FORM_ERROR_1')}
              </IonText>
            )}
            {errors.aboutBody && errors.aboutBody.type === 'minLength' && (
              <IonText color="danger">
                250 &nbsp;
                {t('minimunChars')}
              </IonText>
            )}
          </div>
          <p className="text-right">
            <IonText color="primary">
              250
              {t('minimunChars')}
            </IonText>
            &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
            <IonText color="tertiary">
              {user.textLenght}
              &nbsp;
              {t('charsOf')}
              &nbsp; 500
            </IonText>
          </p>
        </div>
        <IonButton
          data-testid="test-about-submit"
          id="updateAboutInformation"
          type="submit"
          fill="outline"
          shape="round"
          size="small"
        >
          {t('stepSave')}
        </IonButton>
      </form>
    </IonCard>
  );
};

export default AboutModal;
