/* eslint-disable camelcase */
import React, { useContext, useState } from 'react';
import {
  IonButton, IonCol, IonIcon, IonItem, IonLabel, IonRow, IonText,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { closeCircleOutline, checkmarkCircleOutline } from 'ionicons/icons';
import { updateUserResume } from '../../../services/expert';
import { AppContextDispatch, AppContextState } from '../../../state/AppContext';
import { UPDATE_USER_PROFILE, LOADER } from '../../../constants/constants';
import { Userprofile, General } from '../../../interfaces';

const Resume: React.FC = () => {
  const { t } = useTranslation();
  const contextState = useContext(AppContextState);
  const contextDispatch = useContext(AppContextDispatch);
  const { profile } = contextState;
  const { user_profile } = profile;

  const [canUpload, setCanUpload] = useState<boolean>(false);
  const [fileStatus, setfileStatus] = useState<boolean>(false);
  const [cvFile, setcvFile] = useState<FormData | undefined>(undefined);
  const [fileCVname, setfileCVname] = useState<string>('cvSelect');
  const [fileSize, setfileSize] = useState<string>('0 MB');

  const dispatchFunction = (value: Userprofile) => {
    const payload = {
      profile: {
        user_profile: value,
      },
    };
    contextDispatch({
      type: UPDATE_USER_PROFILE,
      payload,
    });
  };

  const dispatchLoader = (value: boolean) => {
    const payload: General = {
      loader: value,
    };
    contextDispatch({
      type: LOADER,
      payload,
    });
  };

  const getResume = () => {
    const resumeInput = document.getElementById('resumeInput') as HTMLInputElement;
    resumeInput.click();
  };

  const clearFileInput = () => {
    const resumeInput = document.getElementById('resumeInput') as HTMLInputElement;
    resumeInput.value = '';
    setfileCVname('cvSelect');
    setcvFile(undefined);
    setfileStatus(false);
    setfileSize('0 MB');
    setCanUpload(false);
  };

  const bytesToSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return;
    const calc = Math.floor(Math.log(bytes) / Math.log(1024));
    const i = parseInt(calc.toString(2), 2);
    const convertedSize = `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
    setfileSize(convertedSize);
  };

  const validateFile = (data: any) => {
    if (data.length > 0) {
      const file = data[0].name;
      const { size } = data[0];
      const dataFile = new FormData();
      dataFile.append('resume', data[0]);
      bytesToSize(size);
      setfileCVname(file);
      setcvFile(dataFile);
      setfileStatus(true);
      setCanUpload(true);
    } else {
      setfileCVname('cvSelect');
      setcvFile(undefined);
      setfileStatus(false);
      setfileSize('0 MB');
      setCanUpload(false);
    }
  };

  const updateCvFile = async (event: any) => {
    event.stopPropagation();
    dispatchLoader(true);
    const jwtToken = sessionStorage.getItem('jwt') || '';
    if (cvFile) {
      await updateUserResume(cvFile, jwtToken)
        .then(({ data }) => {
          dispatchFunction(data);
          dispatchLoader(false);
          setCanUpload(false);
          clearFileInput();
        })
        .catch((error) => {
          console.log(error);
          dispatchLoader(false);
          setCanUpload(false);
          clearFileInput();
        });
    }
  };

  const getUserResume = () => {
    const url = user_profile.resume_url;
    if (url) {
      const final_url = url.split('expert/resume');
      return (
        <IonButton
          data-testid="test-view-resume"
          color="light"
          shape="round"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => viewExpertResume(
            e,
            `https://s3.us-east-2.amazonaws.com/${process.env.REACT_APP_S3_BUCKET}/uploads/expert/resume${final_url[1]}`,
          )}
        >
          <i className="fa fa-user-circle" aria-hidden="true" />
          {t('expert_resume')}
        </IonButton>
      );
    }

    return null;
  };

  const viewExpertResume = (event: any, url: any) => {
    event.stopPropagation();
    const link = document.createElement('a');
    link.download = 'Expert Resume';
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openFileSelector = (event: any) => {
    event.stopPropagation();
    const input_file = document.getElementById('resumeInput');
    input_file?.click();
  };

  return (
    <IonCol size="12" className="resumeTour">
      <IonRow data-testid="test-get-resume" id="resumeButton" onClick={() => getResume()} className="ion-margin-vertical">
        <IonCol size="12" sizeLg="4" className="fileNameContainer">
          <IonItem color="primary">
            {(user_profile.resume_url && !canUpload) && getUserResume()}
            {(!user_profile.resume_url || canUpload) && (
              <>
                <IonIcon
                  className="ion-margin-end"
                  icon={fileStatus ? checkmarkCircleOutline : closeCircleOutline}
                />
                <IonText>{t(fileCVname)}</IonText>
              </>
            )}
          </IonItem>
        </IonCol>
        <IonCol size="12" sizeLg="8" className="resumeStatus">
          <IonRow className={`ion-justify-content-${!canUpload ? 'end' : 'between'}`}>
            {(!user_profile.resume_url || canUpload) && (
              <IonItem color="primary" className="resumeStatus">
                <IonLabel>
                  {t('supportedFormats')}
                  &nbsp;&nbsp;&nbsp;
                  {fileSize}
                </IonLabel>
              </IonItem>
            )}
            {canUpload && (
              <IonButton
                data-testid="test-button-update"
                className="uploadResume"
                fill="outline"
                shape="round"
                color="light"
                disabled={!canUpload}
                onClick={(e) => updateCvFile(e)}
              >
                <i className="fa fa-cloud-upload" aria-hidden="true" />
                {' '}
                {t('cvUpdateBtn')}
              </IonButton>
            )}
            {!canUpload && (
              <IonButton
                data-testid="test-button-upload"
                className="uploadResume"
                fill="outline"
                shape="round"
                color="light"
                onClick={(e) => openFileSelector(e)}
              >
                <i className="fa fa-cloud-upload" aria-hidden="true" />
                {' '}
                {user_profile.resume_url ? t('cvUpdateBtn') : t('cvSelectBtn')}
              </IonButton>
            )}
          </IonRow>
        </IonCol>
        <input
          data-testid="test-file-input"
          type="file"
          className="ion-hide"
          name="resumeInput"
          id="resumeInput"
          placeholder=""
          onChange={(e) => validateFile(e.target.files)}
          accept=".pdf,.doc,.docx"
        />
      </IonRow>
    </IonCol>
  );
};

export default Resume;
