/* eslint-disable camelcase */
import { useContext } from 'react';
import {
  getExpertBalance,
  getStripeDashboard,
  getUserRates,
} from '../services/expert';
import { AppContextState, AppContextDispatch } from '../state/AppContext';

const useExpertDashboard = () => {
  const contextState = useContext(AppContextState);
  const contextDispatch = useContext(AppContextDispatch);

  const {
    profile,
    clientPayments,
    commentsAndRates,
    expertUrlStripe,
  } = contextState;
  const { user_profile } = profile;

  const dispatchFunction = (type: string, value: boolean) => {
    const payload: object = {
      [type]: value,
    };
    contextDispatch({
      type: `UPDATE_${type.toUpperCase()}`,
      payload,
    });
  };

  const validateExpertDashboard = async () => {
    const jwt: string = sessionStorage.getItem('jwt') || '';
    try {
      if (!clientPayments) { await getExpertBalance(jwt, user_profile.id).then(({ data }) => dispatchFunction('balances', data)); }
      if (!commentsAndRates) { await getUserRates(jwt).then(({ data }) => dispatchFunction('commentsAndRates', data)); }
      if (!expertUrlStripe) { await getStripeDashboard(jwt, user_profile.id).then(({ data }) => dispatchFunction('expertUrlStripe', data)); }
    // eslint-disable-next-line no-empty
    } catch (error: any) {}
  };

  return [validateExpertDashboard];
};

export default useExpertDashboard;
