/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import {
  IonCard,
  IonCardHeader,
  IonButton,
  IonIcon,
  IonCardContent,
  IonCardTitle,
  IonRow,
  IonCol,
  IonContent,
  IonItem,
  IonList,
} from '@ionic/react';
import { createOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../../../components/modalWrapper/ModalWrapper';
import { AppContextState } from '../../../state/AppContext';
import LanguagesModal from './LanguagesModal';

const Languages: React.FC = () => {
  const { t } = useTranslation();

  const [showModal, setshowModal] = useState<boolean>(false);
  const contextState = useContext(AppContextState);
  const { profile } = contextState;
  const { user_profile } = profile;
  const { languages_skill } = user_profile;

  const languagesBody = () => (
    Object.entries(languages_skill.languages).map((level) => (
      <IonCol key={level[0]} size="12" sizeLg="4">
        <IonCard className="ion-no-margin">
          <IonCardHeader>
            <IonCardTitle>{t(`${level[0]}Header`)}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent data-testid={`test-lang-container-${level[0]}`}>
            <IonList lines="none">
              {level[1].map((language: string) => (
                <IonItem key={language}>
                  {language}
                  <br />
                </IonItem>
              ))}
            </IonList>
          </IonCardContent>
        </IonCard>
      </IonCol>
    )));

  return (
    <>
      <div className="section-title">
        <h2>{t('profileLenguages')}</h2>
        <span className="title-border" />
      </div>
      <IonCard className="ion-no-margin">
        <IonCardHeader style={{ textAlign: 'right', paddingBottom: '0' }}>
          <IonButton
            data-testid="test-lang-show-modal"
            onClick={() => setshowModal(true)}
            fill="clear"
            shape="round"
          >
            <IonIcon slot="icon-only" icon={createOutline} />
          </IonButton>
        </IonCardHeader>
        <IonCardContent>
          <IonRow>{languagesBody()}</IonRow>
        </IonCardContent>
      </IonCard>
      {
        showModal
        && (
          <ModalWrapper
            wrapperClass="bigModal"
            isOpen={showModal}
            setIsOpen={() => setshowModal(false)}
          >
            <IonContent>
              <LanguagesModal showModal={() => setshowModal(false)} />
            </IonContent>
          </ModalWrapper>
        )
      }
    </>
  );
};

export default Languages;
