/* istanbul ignore file */
import React from 'react';
import { ExpertDetail } from '../../interfaces/clientInterfaces';
import ServiceCard from './ServiceCard';

interface ComponentProps {
    expert: ExpertDetail | undefined;
    // eslint-disable-next-line no-unused-vars
    selected: (value: any) => void;

}

const ExpertServicesSelect: React.FC<ComponentProps> = ({ expert, selected }) => (
  <ServiceCard expertServices={expert?.services} selected={(value) => selected(value)} />
);

export default ExpertServicesSelect;
