/* eslint-disable camelcase */
import {
  IonGrid, IonRow, IonCol, IonChip, IonButton,
} from '@ionic/react';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ExpertBalances from '../../components/userProfile/ExpertBalances';
import PaymentsTable from '../../components/userProfile/PaymentsTable';
import useBaseInformation from '../../hooks/useBaseInformation';
import useClientDashboard from '../../hooks/useClientDashboard';
import useExpertDashboard from '../../hooks/useExpertDashboard';
import { AppContextState } from '../../state/AppContext';

const Statistics = () => {
  const { t } = useTranslation();
  const contextState = useContext(AppContextState);

  const { user_profile } = contextState.profile;
  const userType = user_profile.type;

  const [validateBaseInformation] = useBaseInformation();
  const [validateClientDashboard] = useClientDashboard();
  const [validateExpertDashboard] = useExpertDashboard();
  const history = useHistory();

  useEffect(() => {
    validateBaseInformation();
    if (userType === 'Client') validateClientDashboard();
    else validateExpertDashboard();
  }, []);
  return (
    <div id="dashboard">
      <h1>{t('clTitle')}</h1>
      {
        userType === 'Client'
        && (
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="filtersContainer ion-padding">
                <IonChip
                  data-testid="test-redirect"
                  className="header"
                  onClick={() => history.push('./search')}
                >
                  <h2 className="my-3">{t('findExpert')}</h2>
                  <IonButton
                    data-testid="to-search-btn"
                    color="light"
                    shape="round"
                    onClick={() => history.push('./search')}
                  >
                    {t('newSearch')}
                  </IonButton>
                  <br />
                </IonChip>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        )
      }
      {userType === 'Expert' ? <ExpertBalances /> : <PaymentsTable />}
    </div>
  );
};

export default Statistics;
