/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable camelcase */
import {
  IonChip, IonCol, IonGrid, IonRow,
} from '@ionic/react';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router';
import gfm from 'remark-gfm';
import useBlogFilter from '../../hooks/useBlogFilter';
import {
  Categories,
  HomePage,
  PostContent,
  ServicesExperts,
} from '../../interfaces/strapiInterfaces';
import {
  retiveGenerals,
  retiveContentByUser,
  retivePageById,
  retiveCategories,
  retriveServices,
} from '../../services/strapi';
import { AppContextState } from '../../state/AppContext';
import ContentViewer from './components/ContentViewer';
import ServicesCards from './components/ServicesCard';
import './library.scss';

interface UserData {
  userId: string | number;
  userType: string;
}

const Library: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const location = useLocation();

  const contextState = useContext(AppContextState);

  const [userdata, setUserData] = useState<UserData | undefined>(undefined);
  const [strapigenerals, setStrapiGenerals] = useState<PostContent[]>([]);
  const [strapibyuser, setStrapiByUser] = useState<PostContent[]>([]);
  const [strapihome, setHome] = useState<HomePage | undefined>(undefined);
  const [allstrapi, setAllStrapi] = useState<PostContent[]>([]);
  const [allstrapichange, setAllStrapiChange] = useState(false);
  const [categories, setCategories] = useState<Categories[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const { FilterUI, selected } = useBlogFilter(categories);
  const [filtered, setFiltered] = useState(allstrapi);
  const [services, setServices] = useState<ServicesExperts[]>([]);

  const { user_profile } = contextState.profile;

  const validateUser = () => {
    if (user_profile.id) {
      setUserData({
        userId: user_profile.id,
        userType: user_profile.type === 'Client' ? 'minds' : 'experts',
      });
    }
  };

  const handleError = (errorResponse: AxiosError) => {
    if (errorResponse.response) {
      console.log(errorResponse.response.data, errorResponse.response.status);
      setError(errorResponse.response.data);
    } else if (errorResponse.request) {
      console.log(errorResponse.request.data, errorResponse.request.status);
      setError(errorResponse.request.data);
    } else {
      console.log('An internal error occurred', 500);
      setError('An internal error occurred');
    }
  };

  const retriveInformation = () => {
    if (userdata?.userId) {
      retiveGenerals()
        .then(({ data }) => {
          const newData = [...data];
          data.forEach((post) => (post.type = 'general'));
          setStrapiGenerals(newData);
          setAllStrapi([...newData, ...strapibyuser]);
        })
        .catch((err: AxiosError) => handleError(err));
      retiveContentByUser(userdata.userType)
        .then(({ data }) => {
          const newData = [...data];
          data.forEach((post) => (post.type = userdata.userType));
          setStrapiByUser(newData);
          setAllStrapi([...strapigenerals, ...newData]);
        })
        .catch((err: AxiosError) => handleError(err));
      retivePageById(1)
        .then(({ data }) => {
          setHome(data);
        })
        .catch((err: AxiosError) => handleError(err));
      retiveCategories()
        .then(({ data }) => {
          setCategories(data);
        })
        .catch((err: AxiosError) => handleError(err));
    }
  };

  const setBaseData = () => {
    if (strapigenerals.length > 0 && strapibyuser.length > 0) {
      setFiltered([...strapigenerals, ...strapibyuser]);
      setAllStrapiChange(true);
    }
  };

  const validateRender = () => {
    if (selected !== '') {
      const data = [...strapibyuser, ...strapigenerals];
      const filters = data.filter((element) => {
        const newData = element.categories.find((o) => o.name === selected);
        return newData;
      });
      setFiltered(filters);
    } else {
      setFiltered([...strapibyuser, ...strapigenerals]);
    }
  };

  const retriveServicesFunction = () => {
    retriveServices()
      .then(({ data }) => {
        setServices(data);
      })
      .catch((err: AxiosError) => handleError(err));
  };

  useEffect(() => {
    validateUser();
  }, [user_profile]);

  useEffect(() => {
    if (userdata && userdata.userType === 'experts' && services.length <= 0) {
      retriveServicesFunction();
    }
  }, [userdata]);

  useEffect(() => {
    if (!allstrapichange) {
      retriveInformation();
      setBaseData();
    }
    validateRender();
  }, [location, userdata, selected, allstrapichange, allstrapi]);

  if (!userdata?.userId) return <h1>{t('DC_L22')}</h1>;
  if (!strapihome) return <h1>{t('DC_L22')}</h1>;

  const {
    Title_en, Title_es, Description_en, Description_es, Main_image,
  } = strapihome;
  const title = language === 'es' ? Title_es : Title_en;
  const description = language === 'es' ? Description_es : Description_en;
  const image = Main_image || undefined;

  return (
    <div className="blog-container">
      {error && error}
      <div className="main-content">
        {image && (
          <div
            className="blogHero"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_STRAPI_BASE + image.url})`,
            }}
          >
            <h1 className="title text-center">{title}</h1>
          </div>
        )}
        {!image && (
          <div className="noBlogHero">
            <h1 className="title text-center">{title}</h1>
          </div>
        )}
        <ReactMarkdown
          remarkPlugins={[gfm]}
          className="rich-text-content"
          transformImageUri={(src) => process.env.REACT_APP_STRAPI_BASE + src}
        >
          {description}
        </ReactMarkdown>
        <IonGrid className="service-cards">
          <IonRow className="ion-justify-content-center ion-align-items-center">
            {services && services.length > 0
              ? services.map((content) => (
                <IonCol
                  key={content.id + content.created_at}
                  size="12"
                  sizeLg="4"
                >
                  <ServicesCards content={content} />
                </IonCol>
              ))
              : null}
          </IonRow>
          <IonRow className="ion-justify-content-center ion-align-items-center ion-margin-top">
            {FilterUI()}
          </IonRow>
          <IonRow className="ion-justify-content-center ion-align-items-center cards">
            {filtered && filtered.length > 0 ? (
              filtered.map((content) => (
                <IonCol
                  key={content.id + content.created_at}
                  size="12"
                  sizeLg="4"
                >
                  <ContentViewer
                    type={content.type || ''}
                    content={content}
                  />
                </IonCol>
              ))
            ) : (
              <IonChip color="danger">{t('blog_3')}</IonChip>
            )}
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default Library;
