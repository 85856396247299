import React, { useEffect, useState } from 'react';
import { menuController } from '@ionic/core';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonMenuToggle,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { menu } from 'ionicons/icons';
import './Header.scss';
import { NavLink } from 'react-router-dom';
import LanguageSelector from '../languageSelector/LanguageSelector';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [showSelector, setShowSelector] = useState(false);

  useEffect(() => {
    menuController.enable(true, 'first');
    return () => {
      menuController.enable(true, 'first');
    };
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <>
      <IonHeader id="application-header" className="ion-no-border">
        <IonToolbar>
          <NavLink
            activeClassName="active"
            to="/user/dashboard"
          >
            <IonImg
              slot="start"
              className="svg-logo ion-margin-start"
              src="assets/images/logo_horizontal.svg"
            />
          </NavLink>
          <IonButtons slot="end">
            <IonButton
              data-testid="language-button"
              className="ion-padding-end"
              slot="end"
              fill="clear"
              onClick={() => setShowSelector(!showSelector)}
            >
              <IonIcon
                slot="start"
                className="flag"
                src={`./assets/images/${lang === 'es' ? 'mx' : 'ca'}.svg`}
              />
              {t(lang === 'es' ? 'ChangeLngEs' : 'ChangeLngEn')}
            </IonButton>
            <IonMenuToggle menu="first" autoHide>
              <IonMenuButton>
                <IonIcon icon={menu} color="primary" slot="end" />
              </IonMenuButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>
        <LanguageSelector
          isVisible={showSelector}
          langSelected={(newLang) => {
            setLang(newLang);
            setShowSelector(false);
          }}
        />
      </IonHeader>
    </>
  );
};

export default Header;
