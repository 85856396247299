import { IonButton } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import ImageHandler from '../../../components/ImageHandler/ImageHandler';
import SocialNetworks from '../../../components/socialNetworks/SocialNetworks';

interface ComponentProps {
  name: string;
}

const SubmitModal: React.FC<ComponentProps> = ({ name }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div id="completed_modal">
      {ImageHandler({
        imageName: 'logo_vertical', extension: 'svg', slot: 'icon-only', classN: 'submitLogo',
      })}
      <p className="h3">
        {t('expertSubmitTitle')}
        &nbsp;
        {name}
        !
      </p>
      <p className="message">
        {t('expertSubmitDescription')}
        <br />
        {t('expertSubmitAvailabilities')}
      </p>
      <SocialNetworks />
      <IonButton
        data-testid="test-redirect"
        style={{ marginTop: '2rem' }}
        shape="round"
        fill="outline"
        onClick={() => history.push('/user/availabilities')}
      >
        {t('toAvailabilities')}
      </IonButton>
    </div>
  );
};

export default SubmitModal;
