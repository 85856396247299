/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import { useContext, useState } from 'react';
import { Sethistorysearch } from '../interfaces';
import { setAppointment, setClientPayment } from '../services/client';
import { AppContextState } from '../state/AppContext';
import useLoader from './useLoader';

type FormData = {
  coachType?: string;
  searchCoaching?: string;
  coachChanges?: string;
  coachAchives?: string;
  searchExpressAdvice?: string;
  searchMentory?: string;
  needMentory?: string;
};

interface ServicesRequest {
  start_date: string;
  end_date: string;
  type: string;
}

type allowedServices = 'expressCheck' | 'mentoringCheck' | 'coachingCheck';

const useServicePayment = () => {
  const jwt: string = sessionStorage.getItem('jwt') || '';
  const contextState = useContext(AppContextState);
  const { setLoader } = useLoader();
  const [error, setError] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);

  const { services, profile } = contextState;

  const { user_profile } = profile;

  const setAndRequestService = (
    history: Sethistorysearch,
    token: string,
    formResponse: FormData,
    id: number,
    servicesArray: ServicesRequest[],
    selected: allowedServices,
  ) => {
    setLoader(true);
    let request_object = {};
    const appoinmentsArray: object[] = [];
    const userServiceSearch = selected?.includes('xpress') ? 'ExpressAdvice'
      : selected?.includes('oachin') ? 'Coaching' : 'Mentoring';
    const service = services?.find((o) => o.type === userServiceSearch);
    if (userServiceSearch === 'ExpressAdvice') {
      request_object = {
        searchExpressAdvice: formResponse?.searchExpressAdvice,
      };
    } else if (userServiceSearch === 'Mentoring') {
      request_object = {
        needMentory: formResponse?.needMentory,
        searchMentory: formResponse?.searchMentory,
      };
    } else if (userServiceSearch === 'Coaching') {
      request_object = {
        coachAchives: formResponse?.coachAchives,
        coachChanges: formResponse?.coachChanges,
        coachType: formResponse?.coachType,
        searchCoaching: formResponse?.coachChanges,
      };
    }
    if (service && history.id) {
      servicesArray.forEach((appointment) => {
        const newAppointment: object = {
          user_id: user_profile.id,
          expert_id: id,
          type: appointment.type,
          service_id: service.id,
          start_date: appointment.start_date,
          end_date: appointment.end_date,
          service_request: request_object,
          search_history_id: history.id || 0,
          price: service.price,
          // taxes: {},
        };
        appoinmentsArray.push(newAppointment);
      });
      if (appoinmentsArray.length === 1) requireSingleService(appoinmentsArray, token, history.id);
      else requirePackageService(appoinmentsArray, token, history.id);
    }
  };

  const requireSingleService = (servicesArray: object[], token: string, hsID: number) => {
    const paymentBody = {
      search_history_id: hsID,
      stripeToken: token,
    };
    setAppointment(jwt, user_profile.id, 'c', servicesArray[0])
      .then((response) => {
        if (response.status === 200) {
          setClientPayment(jwt, paymentBody)
            .then((paymentResponse) => {
              if (paymentResponse.status === 200) setCompleted(true);
              setLoader(false);
            }).catch((() => {
              setCompleted(false);
              setError(true);
              setLoader(false);
            }));
        }
      })
      .catch((() => {
        setCompleted(false);
        setError(true);
        setLoader(false);
      }));
  };

  const requirePackageService = (servicesArray: object[], token: string, hsID: number) => {
    const paymentBody = {
      search_history_id: hsID,
      stripeToken: token,
      sessions_package: servicesArray.length,
      appointments_package: servicesArray,
      taxes: {},
    };
    setClientPayment(jwt, paymentBody)
      .then((paymentResponse) => {
        if (paymentResponse.status === 200) setCompleted(true);
        setLoader(false);
      }).catch((() => {
        setCompleted(false);
        setError(true);
        setLoader(false);
      }));
  };

  const communityRequestService = (appointmentData: object, paymentBody: object) => {
    setLoader(true);
    setAppointment(jwt, user_profile.id, 'c', appointmentData)
      .then((response) => {
        if (response.status === 200) {
          setClientPayment(jwt, paymentBody)
            .then((paymentResponse) => {
              if (paymentResponse.status === 200) setCompleted(true);
              setLoader(false);
            }).catch((() => {
              setCompleted(false);
              setError(true);
              setLoader(false);
            }));
        }
      })
      .catch((() => {
        setCompleted(false);
        setError(true);
        setLoader(false);
      }));
  };

  return {
    setAndRequestService, communityRequestService, error, completed,
  };
};

export default useServicePayment;
