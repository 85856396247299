/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IonChip, IonCol, IonIcon, IonLabel, IonRow,
} from '@ionic/react';
import { timeOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';
import TimeKeeper from 'react-timekeeper';
import Calendar from 'react-calendar';
import { ExpertDetail } from '../../interfaces/clientInterfaces';
import { ServicesBase, Sethistorysearch } from '../../interfaces';
import useFormatDate from '../../hooks/useFormatDate';
import Step3 from '../../pages/requestService.tsx/components/Step3';
import useServicePayment from '../../hooks/useServicePayment';
import { AppContextState } from '../../state/AppContext';
import { EXPRESS_PRICE, MENTORING_PRICE, COACHING_PRICE } from '../../constants/constants';

interface IModalBody {
  expertsResults: ExpertDetail[];
  serviceData: ServicesBase | undefined;
}

type allowedServices = 'expressCheck' | 'mentoringCheck' | 'coachingCheck';

const ModalBody: React.FC<IModalBody> = ({ expertsResults, serviceData }) => {
  const { t, i18n } = useTranslation();
  const { formatIsoDate, convertToUTCDate, convertDate } = useFormatDate();
  // eslint-disable-next-line no-unused-vars
  const { communityRequestService, error, completed } = useServicePayment();

  const contextState = useContext(AppContextState);
  const { user_profile } = contextState.profile;

  const [displayStart, setDisplayStart] = useState<boolean>(false);
  const [needText, setNeedText] = useState<string>();
  const [newEvent, setNewEvent] = useState<string>(new Date().toISOString());
  const [paymentToken, setPaymentToken] = useState<string>();
  const [searchHistory, setSearchHistory] = useState<Sethistorysearch>();
  const [serviceSelected] = useState<allowedServices>('expressCheck');

  const NewStart = new Date(newEvent).toISOString();

  const isOfferPrice = (service: string, price: number) => {
    const listPrice = service.toLowerCase().includes('express')
      ? EXPRESS_PRICE : service.toLowerCase().includes('mentoring')
        ? MENTORING_PRICE : COACHING_PRICE;
    if (price < listPrice) {
      return (
        <p className="offer-price-community">
          {t('offerPrice')}
          {' '}
          <span>{`$${listPrice}.00 USD`}</span>
        </p>
      );
    }
    return null;
  };

  useEffect(() => {
    if (searchHistory && paymentToken && needText && serviceSelected) {
      const expertsIdArray: number[] = [];
      expertsResults.forEach((expert) => {
        expertsIdArray.push(expert.id);
      });
      const endDate = convertDate(newEvent, serviceData?.duration_in_min || 0);
      const newAppointment: any = {
        user_id: user_profile.id,
        expert_id: expertsIdArray[0],
        type: 'OpenAppointment',
        service_id: serviceData?.id,
        start_date: convertToUTCDate(newEvent),
        end_date: convertToUTCDate(endDate),
      };
      const request_object = {
        searchExpressAdvice: needText,
      };
      const payment: any = {};
      newAppointment.service_request = request_object;
      newAppointment.price = serviceData?.price;
      newAppointment.search_history_id = searchHistory.id;
      payment.experts = expertsIdArray;
      payment.search_history_id = searchHistory.id;
      payment.stripeToken = paymentToken;
      communityRequestService(newAppointment, payment);
    }
  }, [searchHistory, paymentToken, needText, serviceSelected]);

  return (
    <div className="communityModalContainer">
      <h5>
        {t('clientMassTitle')}
      </h5>
      <h6>{t('clientMassExpertsList')}</h6>
      <ol>
        {expertsResults && expertsResults.length > 0
          ? expertsResults.map((expert) => (
            <li key={expert.uuid}>
              {expert.first_name}
              {' '}
              {expert.last_name}
            </li>
          ))
          : null}
      </ol>
      <p>{t('clientMassExplanation')}</p>
      <form onSubmit={(e) => e.preventDefault()}>
        <IonRow className="ion-padding-top">
          <IonCol size="12" className="form__group field">
            <textarea
              className="form__field"
              name="communityNeed"
              id="communityNeed"
              value={needText}
              onChange={(e) => setNeedText(e.target.value)}
            />
            <label htmlFor="communityNeed" className="form__label">
              <span className="ion-margin-start">{t('needMentory')}</span>
            </label>
          </IonCol>
          <h6>{t('clientMassSelectDate')}</h6>
          <IonCol size="12" className="form__group field">
            <input
              type="text"
              className="form__field"
              name="newHour"
              id="newHour"
              placeholder={t('rescheduleTimeZone')}
              value={formatIsoDate(NewStart)}
              onClick={() => setDisplayStart(true)}
              readOnly
            />
            <label htmlFor="newHour" className="form__label">
              <IonIcon icon={timeOutline} />
              <span className="ion-margin-start">{t('reschedule_hour')}</span>
            </label>
          </IonCol>
          {displayStart && (
          <IonRow className="fixed-position">
            <Calendar
              minDate={new Date()}
              locale={i18n.language}
              maxDetail="month"
              onChange={(e: any) => {
                const newDate = new Date(e).toISOString();
                setNewEvent(newDate);
              }}
              value={new Date(NewStart)}
            />
            <TimeKeeper
              time={formatIsoDate(NewStart, 't', 'en')}
              onChange={/* istanbul ignore next */(e) => {
                let newState = newEvent;
                const hour = e.formatted24.split(':');
                const newHour = DateTime.fromISO(
                  new Date(newState).toISOString(),
                ).set({
                  hour: parseInt(hour[0], 10),
                  minute: parseInt(hour[1], 10),
                });
                newState = newHour.toISO();
                setNewEvent(newState);
              }}
              onDoneClick={() => {
                setDisplayStart(false);
              }}
            />
          </IonRow>
          )}
        </IonRow>
      </form>
      <div className="confirm_push_information">
        <p>
          {t('service')}
          {' '}
          {t('express_service')}
        </p>
        <p>{t('timeDuration', { minutes: serviceData?.duration_in_min })}</p>
        <p>
          {t('SA_L20')}
          :&nbsp;$
          {serviceData?.price}
          {' '}
          USD
        </p>
        {serviceData && isOfferPrice(serviceData.type, serviceData.price)}
      </div>
      {
        error && (
          <IonChip color="danger">
            <IonLabel color="danger">{t('globalError')}</IonLabel>
          </IonChip>
        )
      }
      {
        completed && (
          <IonChip color="success">
            <IonLabel color="success">{t('globalSuccess')}</IonLabel>
          </IonChip>
        )
      }
      {newEvent && needText
      && (
      <Step3
        payToken={(token: string) => setPaymentToken(token)}
        history={(data: Sethistorysearch) => setSearchHistory(data)}
        clientResponse={undefined}
        service={serviceSelected}
      />
      )}
    </div>
  );
};

export default ModalBody;
