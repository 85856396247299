/* eslint-disable camelcase */
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
} from '@ionic/react';
import { ellipse, ellipseOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useUpdateUser from '../../../hooks/useUpdateUser';
import { AppContextState } from '../../../state/AppContext';

interface ComponentProps {
  showModal: () => void
}

const ServicesModal: React.FC<ComponentProps> = ({ showModal }) => {
  const { t } = useTranslation();
  const contextState = useContext(AppContextState);

  const { user_profile } = contextState.profile;

  const [expertServices, setExpertServices] = useState<any>({
    expressCheck: false,
    mentoringCheck: false,
    coachingCheck: false,
  });

  const { status, updateClient } = useUpdateUser();

  const { handleSubmit } = useForm();

  const onSubmit = () => {
    const finalServices = Object.keys(expertServices).filter(
      (k) => expertServices[k],
    );
    const newProfile = { ...user_profile };
    newProfile.services = finalServices;
    updateClient(newProfile);
  };

  const toggleService = (service: any) => {
    const checkValue = `${service}Check`;
    setExpertServices((prevState: any) => ({
      ...expertServices,
      [checkValue]: !prevState[checkValue],
    }));
  };

  useEffect(() => {
    if (status || status === false) showModal();
  }, [status]);

  useEffect(() => {
    const baseData: any = {};
    if (user_profile.services && user_profile.services.length > 0) {
      user_profile.services.forEach((element: string) => {
        baseData[element] = true;
      });
      setExpertServices({ ...baseData });
    }
  }, [user_profile.services]);

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          <h4 className="ion-no-margin">{t('profileServices')}</h4>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <form className="stepComponent" onSubmit={handleSubmit(onSubmit)}>
          <IonRow>
            <IonCol size="12" sizeLg="4">
              <IonCard
                data-testid="test-express-toggle"
                style={{ cursor: 'pointer' }}
                className="servicesContainer ion-padding"
                onClick={() => toggleService('express')}
              >
                <IonIcon
                  style={{ width: '80px', height: '80px' }}
                  color="secondary"
                  className="services-card-icon"
                  src="./assets/images/express.svg"
                />
                <IonIcon
                  className="serviceStatus"
                  icon={expertServices.expressCheck ? ellipse : ellipseOutline}
                />
                <h4>{t('express_service')}</h4>
                <p className="text-left ion-padding-top">
                  {t('expres_descripton_expert')}
                </p>
              </IonCard>
            </IonCol>
            <IonCol size="12" sizeLg="4">
              <IonCard
                data-testid="test-mentoring-toggle"
                style={{ cursor: 'pointer' }}
                className="servicesContainer customPoitner ion-padding"
                onClick={() => toggleService('mentoring')}
              >
                <IonIcon
                  style={{ width: '80px', height: '80px' }}
                  color="secondary"
                  className="services-card-icon"
                  src="./assets/images/mentoring.svg"
                />
                <IonIcon
                  className="serviceStatus"
                  icon={
                    expertServices.mentoringCheck ? ellipse : ellipseOutline
                  }
                />
                <h4>{t('mentoring_service')}</h4>
                <p className="text-left ion-padding-top">
                  {t('mentoring_description_expert')}
                </p>
              </IonCard>
            </IonCol>
            <IonCol size="12" sizeLg="4">
              <IonCard
                data-testid="test-coaching-toggle"
                style={{ cursor: 'pointer' }}
                className="servicesContainer customPoitner ion-padding"
                onClick={() => toggleService('coaching')}
              >
                <IonIcon
                  style={{ width: '80px', height: '80px' }}
                  color="secondary"
                  className="services-card-icon"
                  src="./assets/images/coaching.svg"
                />
                <IonIcon
                  className="serviceStatus"
                  icon={expertServices.coachingCheck ? ellipse : ellipseOutline}
                />
                <h4>{t('coaching_service')}</h4>
                <p className="text-left ion-padding-top">
                  {t('coaching_description_expert')}
                </p>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonButton data-testid="test-service-submit" type="submit" fill="outline" shape="round" size="small">
            {t('stepSave')}
          </IonButton>
        </form>
      </IonCardContent>
    </IonCard>
  );
};

export default ServicesModal;
