/* eslint-disable no-param-reassign */
import {
  IonGrid, IonRow, IonCol, IonButton,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Calendar, dateFnsLocalizer, View } from 'react-big-calendar';
import { getMonth, getYear } from 'date-fns';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { es, enUS } from 'date-fns/locale';
import useBaseInformation from '../../hooks/useBaseInformation';
import { getAvailabilities } from '../../services/expert';
import { ExpertAvailabilities, NewEventType } from '../../interfaces';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ModalWrapper from '../../components/modalWrapper/ModalWrapper';
import AvailabilitiesModal from './components/AvailabilitiesModal';
import './availabilities.scss';
import useLoader from '../../hooks/useLoader';

const Availabilitites: React.FC = () => {
  const locales = {
    'en-us': enUS,
    es,
  };
  const scrollTo = new Date().setHours(11, 0, 0, 0);
  const { t, i18n } = useTranslation();
  const { setLoader } = useLoader();
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });
  const messages = {
    agenda: t('agenda'),
    allDay: t('allDay'),
    month: t('month'),
    day: t('day'),
    today: t('today'),
    previous: t('previous'),
    next: t('next'),
    date: t('date'),
    noEventsInRange: t('noEventsInRange'),
    time: t('time'),
    tomorrow: t('tomorrow'),
    week: t('week'),
    work_week: t('work_week'),
    yesterday: t('yesterday'),
  };

  const [validateBaseInformation] = useBaseInformation();
  const history = useHistory();
  const [availabilities, setAvailabilities] = useState<ExpertAvailabilities[]>(
    [],
  );
  const [calendarView, setCalendarView] = useState<View>(Math.floor(window.innerWidth) < 500 ? 'day' : 'week');
  const [event, setEvent] = useState<
      | NewEventType
      | ExpertAvailabilities
      |(NewEventType & ExpertAvailabilities)
        | undefined
        >(undefined);
  const [edition, setEdition] = useState<boolean>(false);
  const [calendarDate, setCalendarDate] = useState({
    month: (getMonth(new Date()) + 1).toString(),
    year: getYear(new Date()).toString(),
  });

  window.onresize = () => {
    setCalendarView(Math.floor(window.innerWidth) < 500 ? 'day' : 'week');
  };

  useEffect(() => {
    validateBaseInformation();
  }, []);

  const requestAvailabilities = (
    month: string = calendarDate.month,
    year: string = calendarDate.year,
  ) => {
    setLoader(true);
    const jwt = sessionStorage.getItem('jwt') || '{}';
    try {
      getAvailabilities(jwt, month, year)
        .then(({ data }) => {
          data.forEach((element: any) => {
            element.start = new Date(element.date_time_start);
            element.end = new Date(element.date_time_end);
          });
          setLoader(false);
          setAvailabilities(data);
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  /* istanbul ignore next */
  const editEvent = (data: ExpertAvailabilities) => {
    setEvent(data);
    setEdition(true);
  };

  /* istanbul ignore next */
  const createEvent = (data: NewEventType) => {
    data.frequency = 'weekly';
    setEvent(data);
  };

  /* istanbul ignore next */
  const closeModal = () => {
    setEdition(false);
    setEvent(undefined);
    requestAvailabilities();
  };

  useEffect(() => {
    requestAvailabilities();
  }, []);

  useEffect(() => {
    requestAvailabilities();
  }, [calendarDate]);

  return (
    <div id="calendarView">
      <h1 className="ion-no-margin">{t('expertAvailabilitiesView')}</h1>
      <IonGrid>
        <IonRow className="ion-align-items-center">
          <IonCol size="12" sizeLg="8">
            <h5>{t('experAvailabilitiesTitle')}</h5>
            <p>{t('experAvailabilitiesSubtitle')}</p>
          </IonCol>
          <IonCol size="12" sizeLg="4">
            <IonButton
              data-testid="to-dashboard-btn"
              shape="round"
              expand="block"
              onClick={() => history.push('./dashboard')}
            >
              {t('experAvailabilitiesNavigateDashboard')}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <Calendar
        style={{ width: '100%', height: '80vh' }}
        localizer={localizer}
        culture={i18n.language}
        defaultDate={new Date()}
        view={calendarView}
        events={availabilities}
        selectable
        messages={messages}
        scrollToTime={new Date(scrollTo)}
        onView={(e) => setCalendarView(e)}
        onSelectSlot={(e) => createEvent(e as NewEventType)}
        onSelectEvent={(e) => editEvent(e)}
        onNavigate={(e) => setCalendarDate({
          month: (getMonth(e) + 1).toString(),
          year: getYear(e).toString(),
        })}
      />
      {!!event && (
        <ModalWrapper wrapperClass="bigModal" isOpen={!!event} setIsOpen={() => closeModal()}>
          <AvailabilitiesModal
            edition={edition}
            showModal={() => closeModal()}
            event={event as (NewEventType & ExpertAvailabilities)}
          />
        </ModalWrapper>
      )}
    </div>
  );
};

export default Availabilitites;
