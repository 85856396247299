import {
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonCard,
} from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  sessions: string;
  // eslint-disable-next-line no-unused-vars
  setSessions: (value: string) => void;
}

const SessionsAmmount: React.FC<ComponentProps> = ({ sessions, setSessions }) => {
  const { t } = useTranslation();
  return (
    <>
      <h3>{t('selectPackSessions')}</h3>
      <IonRow className="ion-justify-content-center">
        <IonCol size="12" sizeLg="4">
          <IonCard>
            <IonList>
              <IonItem>
                <IonLabel>
                  1
                  &nbsp;
                  {t('singleSession')}
                </IonLabel>
                <IonCheckbox
                  slot="end"
                  mode="ios"
                  checked={sessions === '1'}
                  onClick={() => setSessions('1')}
                />
              </IonItem>
              <IonItem>
                <IonLabel>
                  5
                  &nbsp;
                  {t('multiSession')}
                </IonLabel>
                <IonCheckbox
                  slot="end"
                  mode="ios"
                  checked={sessions === '5'}
                  onClick={() => setSessions('5')}
                />
              </IonItem>
              <IonItem>
                <IonLabel>
                  8
                  &nbsp;
                  {t('multiSession')}
                </IonLabel>
                <IonCheckbox
                  slot="end"
                  mode="ios"
                  checked={sessions === '8'}
                  onClick={() => setSessions('8')}
                />
              </IonItem>
            </IonList>
          </IonCard>
        </IonCol>
      </IonRow>
    </>
  );
};

export default SessionsAmmount;
