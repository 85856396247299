import { useState } from 'react';

const useNotifications = () => {
  const [canNotify, setCanNotify] = useState<boolean>();
  const validNotifications = () => {
    try {
      if (Notification.permission === 'granted') setCanNotify(true);
      else if (Notification.permission === 'default') {
        Notification.requestPermission().then(/* istanbul ignore next */(permission) => {
          if (permission === 'granted') setCanNotify(true);
          else setCanNotify(false);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { validNotifications, canNotify };
};

export default useNotifications;
