/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonText,
  IonItem,
} from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useUpdateUser from '../../../hooks/useUpdateUser';
import { AppContextState } from '../../../state/AppContext';

interface LangsType {
  fluent: string[];
  learning: string[];
  native: string[];
}

interface ComponentProps {
  showModal: () => void
}

const LanguagesModal: React.FC<ComponentProps> = ({ showModal }) => {
  const { t } = useTranslation();

  const [userLanguages, setLangs] = useState<LangsType>({
    fluent: [],
    learning: [],
    native: [],
  });

  const { status, updateClient } = useUpdateUser();
  const contextState = useContext(AppContextState);
  const { profile, languages } = contextState;
  const { user_profile } = profile;
  const { languages_skill } = user_profile;

  const renderLanguagesOptions = (type: string) => (
    <div className="form__group field">
      <select
        data-testid={`test-lang-select-${type}`}
        className="form__field "
        id={type}
        {...register(type, { required: true })}
        onChange={(e) => userSelectLanguage(e)}
      >
        <option value="DEFAULT" defaultValue={0}>
          {t('selectDefault')}
        </option>
        {languages
          && languages.map((value) => (
            <option key={value.name} value={value.name}>
              {value.name}
            </option>
          ))}
      </select>
    </div>
  );

  const renderUserLaguages = (type: string) => (
    <IonCard className="ion-no-margin">
      <IonCardContent>
        {Object.entries(userLanguages).map((level: any) => {
          if (level[0] === type) {
            return level[1].map((element: string) => (
              <IonItem key={element} className="text-left">
                {element}
                <IonIcon slot="end" data-testid={`test-lang-delete-${level}`} icon={trashOutline} onClick={() => deleteLanguage(level[0], element)} />
              </IonItem>
            ));
          }
          return null;
        })}
      </IonCardContent>
    </IonCard>
  );

  const userSelectLanguage = (event: any) => {
    const currentLanguage = event.target.id.replace('Language', '');
    const selectedLanguage = event.target.value;
    Object.entries(userLanguages).forEach((level) => {
      if (level[0] === currentLanguage) {
        const levelLanguages: any = level[1];
        if (levelLanguages.indexOf(selectedLanguage) !== -1) {
          levelLanguages.splice(levelLanguages.indexOf(selectedLanguage), 1);
        } else levelLanguages.push(selectedLanguage);
        const langObject: any = { ...userLanguages };
        langObject[currentLanguage] = levelLanguages;
        setLangs({ ...langObject });
      }
      return null;
    });
  };

  const deleteLanguage = (level: any, element: string) => {
    Object.entries(userLanguages).forEach((language) => {
      if (language[0] === level) {
        language[1].splice(language[1].indexOf(element), 1);
        const langObject: any = { ...userLanguages };
        setLangs({ ...langObject });
      }
    });
  };

  const onSubmit = () => {
    const newProfile = { ...user_profile };
    const newLanguagesArray = { ...newProfile.languages_skill };
    newLanguagesArray.languages = { ...userLanguages };
    newProfile.languages_skill = newLanguagesArray;
    updateClient(newProfile);
  };

  useEffect(() => {
    if (languages_skill) {
      const cloneLangs = { ...languages_skill.languages };
      setLangs({ ...cloneLangs });
    }
  }, [languages_skill]);

  useEffect(() => {
    if (status) showModal();
    if (status === false) showModal();
  }, [status]);

  const {
    register,
    handleSubmit,
  } = useForm();
  return (
    <IonCard className="ion-padding">
      <IonCardHeader>
        <IonCardTitle className="text-left">
          <h2 className="ion-no-margin">{t('selectLenguages')}</h2>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <form className="stepComponent" onSubmit={handleSubmit(onSubmit)}>
          <IonRow>
            <IonCol size="12" sizeLg="4">
              <h4>{t('nativeHeader')}</h4>
              <div className="form-group">
                {renderLanguagesOptions('nativeLanguage')}
              </div>
              <div className="myLanguages">{renderUserLaguages('native')}</div>
            </IonCol>
            <IonCol size="12" sizeLg="4">
              <h4>{t('fluentHeader')}</h4>
              <div className="form-group">
                {renderLanguagesOptions('fluentLanguage')}
              </div>
              <div className="myLanguages">{renderUserLaguages('fluent')}</div>
            </IonCol>
            <IonCol size="12" sizeLg="4">
              <h4>{t('learningHeader')}</h4>
              <div className="form-group">
                {renderLanguagesOptions('learningLanguage')}
              </div>
              <div className="myLanguages">
                {renderUserLaguages('learning')}
              </div>
            </IonCol>
          </IonRow>
          <IonButton
            data-testid="test-lang-submit"
            className="ion-margin-top"
            type="submit"
            fill="outline"
            size="small"
          >
            {' '}
            {t('stepSave')}
          </IonButton>
        </form>
      </IonCardContent>
    </IonCard>
  );
};

export default LanguagesModal;
