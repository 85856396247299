/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonRow,
  IonText,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Calendar from 'react-calendar';
import { Workhistory } from '../../../interfaces';
import { AppContextState } from '../../../state/AppContext';
import { industrieSizes } from '../../../constants/industries';
import useUpdateUser from '../../../hooks/useUpdateUser';
import useFormatDate from '../../../hooks/useFormatDate';

interface ComponentProps {
  edition: number | undefined;
  showModal: () => void;
}

const WorkExperienceModal: React.FC<ComponentProps> = ({
  edition,
  showModal,
}) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const webSiteRegex = /[(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

  const contextState = useContext(AppContextState);
  const { formatIsoDate } = useFormatDate();

  const { profile, seniority, industries } = contextState;
  const { user_profile } = profile;
  const { work_histories } = user_profile;
  const { status, updateClient } = useUpdateUser();

  const newIndustrieData: Workhistory = {
    title: '',
    company: '',
    company_size: '',
    company_website: '',
    from: '',
    industry_field: '',
    job_title: '',
    location: '',
    seniority: undefined,
    to: '',
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Workhistory>();
  const [editWork, setEditWork] = useState({
    newIndustrie: newIndustrieData,
  });
  const [isEdditing, setisEdditing] = useState<boolean | undefined>(undefined);
  const [presentCheck, setpresentCheck] = useState<boolean>(false);

  /* istanbul ignore next */
  const entryUpdateDate = (event: any, value: 'from' | 'to') => {
    const newState = editWork;
    if (value === 'to') {
      setpresentCheck(false);
    }
    newState.newIndustrie[value] = new Date(event).toISOString();
    setEditWork({ ...newState });
  };

  const renderSeniorityOptions = () => (
    <>
      <option value="">{t('selectDefault')}</option>
      {seniority?.map((element) => (
        <option key={element.name} value={element.name}>
          {element.name}
        </option>
      ))}
    </>
  );

  const renderIndustrieSizeOptions = () => (
    <>
      <option value="">{t('selectDefault')}</option>
      {industrieSizes.map((element) => (
        <option key={element} value={element}>
          {element}
        </option>
      ))}
    </>
  );

  const renderIndustrieFieldsOptions = () => (
    <>
      <option value="">{t('selectDefault')}</option>
      {industries?.map((element) => (
        <option key={element.id} value={element.name}>
          {element.name}
        </option>
      ))}
    </>
  );

  /* istanbul ignore next */
  const onSubmit = (data: any) => {
    setEditWork({ ...editWork, newIndustrie: data });
    const newProfile = { ...user_profile };
    const newJobs = [...newProfile.work_histories];
    data.from = editWork.newIndustrie.from;
    if (!presentCheck) data.to = editWork.newIndustrie.to;
    else data.to = 'present';
    if (isEdditing && edition) newJobs[edition] = { ...data };
    else newJobs.push(data);
    newProfile.work_histories = newJobs;
    updateClient(newProfile);
  };

  useEffect(() => {
    if (edition && work_histories) {
      const workToEdit = work_histories[edition];
      if (workToEdit.to === 'present') setpresentCheck(true);
      setEditWork({ ...editWork, newIndustrie: workToEdit });
      setValue('company', workToEdit.company);
      setValue('company_size', workToEdit.company_size);
      setValue('company_website', workToEdit.company_website);
      setValue('industry_field', workToEdit.industry_field);
      setValue('job_title', workToEdit.job_title);
      setValue('location', workToEdit.location);
      setValue('seniority', workToEdit.seniority);
      setValue('title', workToEdit.title);
      setValue('from', formatIsoDate(workToEdit.from, 'LLLL yyyy'));
      setValue(
        'to',
        workToEdit.to !== 'present'
          ? formatIsoDate(workToEdit.to, 'LLLL yyyy')
          : new Date().toISOString(),
      );
      setisEdditing(true);
    }
  }, [edition, work_histories]);

  useEffect(() => {
    if (status) showModal();
    if (status === false) showModal();
  }, [status]);

  return (
    <IonCard
      className="ion-padding ion-no-margin"
      style={{ width: '100%', height: '100%' }}
    >
      <IonContent>
        <IonCardHeader>
          <IonCardTitle>
            <h2>{t('profileWorkExperience')}</h2>
          </IonCardTitle>
        </IonCardHeader>
        <form
          className="stepComponent"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <IonRow>
            <IonCol size="12" sizeLg="6">
              <div className="form__group field ion-margin-top">
                <select
                  className={`form__field ${
                    errors.seniority ? ' invalid ' : ''
                  }`}
                  {...register('seniority', { required: true })}
                  defaultValue={editWork.newIndustrie.seniority}
                >
                  {renderSeniorityOptions()}
                </select>
                <label className="form__label" htmlFor="seniority">
                  {t('step6seniority')}
                </label>
              </div>
              {errors.seniority && errors.seniority.type === 'required' && (
                <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
              )}
              <div className="form__group field ion-margin-top">
                <input
                  type="text"
                  className={`form__field ${errors.company ? ' invalid ' : ''}`}
                  {...register('company', { required: true })}
                  defaultValue={editWork.newIndustrie.company}
                />
                <label className="form__label" htmlFor="company">
                  {t('step6Company')}
                </label>
              </div>
              {errors.company && errors.company.type === 'required' && (
                <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
              )}
              <div className="form__group field ion-margin-top">
                <input
                  type="text"
                  className={`form__field ${
                    errors.job_title ? ' invalid ' : ''
                  }`}
                  {...register('job_title', { required: true })}
                  defaultValue={editWork.newIndustrie.job_title}
                />
                <label className="form__label" htmlFor="job_title">
                  {t('step6Job')}
                </label>
              </div>
              {errors.job_title && errors.job_title.type === 'required' && (
                <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
              )}
              <IonRow className="ion-justify-content-between">
                <IonCol className="ion-no-padding" size="12" sizeLg="5">
                  <div className="form__group field ion-margin-top">
                    <input
                      type="text"
                      className={`form__field ${
                        errors.from ? ' invalid ' : ''
                      }`}
                      {...register('from', {
                        required: true,
                        minLength: 2,
                      })}
                      value={formatIsoDate(
                        editWork.newIndustrie.from || new Date().toISOString(),
                        'LLLL yyyy',
                      )}
                    />
                    <Calendar
                      maxDate={new Date()}
                      locale={language}
                      maxDetail="month"
                      onChange={(e: any) => entryUpdateDate(e, 'from')}
                      defaultValue={
                        editWork.newIndustrie.from
                          ? new Date(editWork.newIndustrie.from)
                          : new Date()
                      }
                    />
                    <label className="form__label" htmlFor="from">
                      {t('step3From')}
                    </label>
                  </div>
                  {errors.from && errors.from.type === 'required' && (
                    <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
                  )}
                </IonCol>
                <IonCol className="ion-no-padding" size="12" sizeLg="6">
                  <div className="form__group field ion-margin-top">
                    <input
                      type="text"
                      className={`form__field ${errors.to ? ' invalid ' : ''}`}
                      {...register('to', {
                        required: true,
                        minLength: 2,
                      })}
                      value={formatIsoDate(
                        editWork.newIndustrie.to
                          && editWork.newIndustrie.to !== 'present'
                          ? editWork.newIndustrie.to
                          : new Date().toISOString(),
                        'LLLL yyyy',
                      )}
                    />
                    <Calendar
                      maxDate={new Date()}
                      locale={language}
                      maxDetail="month"
                      onChange={(e: any) => entryUpdateDate(e, 'to')}
                      defaultValue={
                        editWork.newIndustrie.to
                        && editWork.newIndustrie.to !== 'present'
                          ? new Date(editWork.newIndustrie.to)
                          : new Date()
                      }
                    />
                    <label className="form__label" htmlFor="to">
                      {t('step3To')}
                    </label>
                  </div>
                </IonCol>
                <IonCol sizeLg="12" size="12">
                  <label
                    className="form__label ion-margin-top"
                    htmlFor="isPresent"
                  >
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={presentCheck}
                      onChange={() => setpresentCheck(!presentCheck)}
                    />
                    &nbsp;&nbsp;
                    {t('presentText')}
                  </label>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size="12" sizeLg="6">
              <div className="form__group field ion-margin-top">
                <select
                  className={`form__field ${
                    errors.industry_field ? ' invalid ' : ''
                  }`}
                  {...register('industry_field', { required: true })}
                  defaultValue={editWork.newIndustrie.industry_field}
                >
                  {renderIndustrieFieldsOptions()}
                </select>
                <label className="form__label" htmlFor="industry_field">
                  {t('step6Industry')}
                </label>
              </div>
              {errors.industry_field
                && errors.industry_field.type === 'required' && (
                  <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
              )}
              <div className="form__group field ion-margin-top">
                <select
                  className={`form__field ${
                    errors.company_size ? ' invalid ' : ''
                  }`}
                  {...register('company_size', { required: true })}
                  defaultValue={editWork.newIndustrie.company_size}
                >
                  {renderIndustrieSizeOptions()}
                </select>
                <label className="form__label" htmlFor="industry_field">
                  {t('step6Size')}
                </label>
              </div>
              {errors.company_size
                && errors.company_size.type === 'required' && (
                  <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
              )}
              <div className="form__group field ion-margin-top">
                <input
                  type="text"
                  className={`form__field ${
                    errors.location ? ' invalid ' : ''
                  }`}
                  {...register('location', { required: true })}
                  defaultValue={editWork.newIndustrie.location}
                />
                <label className="form__label" htmlFor="location">
                  {t('step6location')}
                </label>
              </div>
              {errors.location && errors.location.type === 'required' && (
                <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
              )}
              <div className="form__group field ion-margin-top">
                <input
                  type="text"
                  className={`form__field ${
                    errors.company_website ? ' invalid ' : ''
                  }`}
                  {...register('company_website', {
                    required: true,
                    pattern: webSiteRegex,
                  })}
                  defaultValue={editWork.newIndustrie.company_website}
                />
                <label className="form__label" htmlFor="company_website">
                  {t('step6Website')}
                </label>
              </div>
              {errors.company_website
                && errors.company_website.type === 'required' && (
                  <IonText color="danger">{t('FORM_ERROR_1')}</IonText>
              )}
              {errors.company_website
                && errors.company_website.type === 'pattern' && (
                  <IonText color="danger">{t('FORM_ERROR_2')}</IonText>
              )}
            </IonCol>
          </IonRow>
          <IonButton
            type="submit"
            size="small"
            shape="round"
            data-testid="test-submit"
          >
            {t('stepSave')}
          </IonButton>
        </form>
      </IonContent>
    </IonCard>
  );
};

export default WorkExperienceModal;
