/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { AxiosRequestConfig } from 'axios';
import { Logintype, Userprofile } from '../interfaces';
import AxiosInstance from './axios';

export const retiveExpert = (userData: Logintype) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(userData),
  };
  return AxiosInstance('/api/v1/authentications', fetchInformation);
};

export const getUserToken = async (userData: Logintype) => {
  const auth = {
    provider: userData.provider,
    id: userData.id,
    email: userData.email,
    token: userData.token,
    user_type: userData.user_type,
  };
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(auth),
  };
  return AxiosInstance('/api/v1/user_token', fetchInformation);
};

export const logoutUser = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${JSON.parse(jwt)}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/sessions', fetchInformation);
};

export const userExpertProfile = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/experts', fetchInformation);
};

export const getIndustries = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/industries/', fetchInformation);
};

export const getServices = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/services', fetchInformation);
};

export const getSeniority = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/seniority_levels/', fetchInformation);
};

export const getJobFunctions = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/job_functions/', fetchInformation);
};

export const getLanguages = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/languages/', fetchInformation);
};

export const getTimeZone = async (jwt: string, timeZone: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(`/api/v1/timezones/${timeZone}`, fetchInformation);
};

export const updateUserProfile = async (userData: Userprofile, jwt: string) => {
  if (userData.industries) {
    userData.work_histories = userData.industries;
    delete userData.industries;
  }
  const fetchInformation: AxiosRequestConfig = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(userData),
  };
  return AxiosInstance('/api/v1/experts', fetchInformation);
};

export const updateUserResume = async (userResume: FormData, jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    data: userResume,
  };
  return AxiosInstance('/api/v1/experts/upload_resume', fetchInformation);
};

export const updateUserAvatar = async (userResume: FormData, jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
    data: userResume,
  };
  return AxiosInstance('/api/v1/experts/upload_resume', fetchInformation);
};

export const getAvailabilities = async (
  jwt: string,
  month: string,
  year: string,
) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/availabilities?month=${month}&year=${year}`,
    fetchInformation,
  );
};

export const getExpertAvailabilitiesForAppointments = async (
  jwt: string,
  expert_id: string,
  interval: string,
  date: string,
) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/expert_appointments/?expert_id=${expert_id}&interval=${interval}&date=${date}`,
    fetchInformation,
  );
};

export const createNewAvailability = async (event: any, jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(event),
  };
  return AxiosInstance('/api/v1/availabilities', fetchInformation);
};

export const deleteNewAvailability = async (
  event: any,
  frequency: string,
  jwt: string,
) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ frequency }),
  };
  return AxiosInstance(`/api/v1/availabilities/${event}`, fetchInformation);
};

export const updateAppointment = async (jwt: string, event: any) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(event),
  };
  return AxiosInstance(`/api/v1/appointments/${event.id}`, fetchInformation);
};

export const getAllAppointments = async (
  jwt: string,
  user_id: string | number,
  userType: string,
) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/appointments?by=${userType}&user_id=${user_id}`,
    fetchInformation,
  );
};

export const getSingleAppointment = async (jwt: string, id: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(`/api/v1/appointments/${id}`, fetchInformation);
};

export const getAppointmentsStatus = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/appointments/status_by_user', fetchInformation);
};

export const rateUser = async (jwt: string, review: object) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ review }),
  };
  return AxiosInstance('/api/v1/reviews', fetchInformation);
};

export const getUserRates = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/reviews', fetchInformation);
};

export const validateUserStripe = async (jwt: string, data: object) => {
  const stripe = {
    stripe_express_accound: data,
  };
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(stripe),
  };
  return AxiosInstance('/api/v1/stripe_accounts', fetchInformation);
};

export const getStripeDashboard = async (
  jwt: string,
  uuid: string | number,
) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(`/api/v1/stripe_accounts/${uuid}`, fetchInformation);
};

export const getExpertInvoices = async (jwt: string, id: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/invoices/${id}/invoices_from_expert`,
    fetchInformation,
  );
};

export const getExpertBalance = async (jwt: string, ID: string | number) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(`/api/v1/admin/expert_balances/${ID}`, fetchInformation);
};

export const getClientsByID = async (jwt: string, user_id: string | number) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/clients/client_by_id/${user_id}`,
    fetchInformation,
  );
};

export const requestInvoice = async (jwt: string, user_id: number) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/invoices/${user_id}/generate_invoice`,
    fetchInformation,
  );
};
