/* eslint-disable camelcase */
import { IonRow, IonButton, IonIcon } from '@ionic/react';
import {
  micOutline,
  micOffOutline,
  videocamOutline,
  videocamOffOutline,
} from 'ionicons/icons';
import {
  OTSession, OTPublisher, OTStreams, OTSubscriber,
} from 'opentok-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentDetail } from '../../../interfaces/clientInterfaces';

interface VCOptions {
  error: any;
  audio: boolean;
  suscribeAudio: boolean;
  video: boolean;
  screen: undefined | string;
}

interface ComponentProps {
  activeService: AppointmentDetail;
  VCoptions: VCOptions;
  // eslint-disable-next-line no-unused-vars
  setVCoptions: (options: VCOptions) => void;
}

const VideoChat: React.FC<ComponentProps> = ({
  activeService,
  VCoptions,
  setVCoptions,
}) => {
  const { t } = useTranslation();
  const setAudio = () => {
    setVCoptions({
      ...VCoptions,
      audio: !VCoptions.audio,
    });
  };

  const setVideo = () => {
    setVCoptions({
      ...VCoptions,
      video: !VCoptions.video,
    });
  };

  const screenWindow = () => {
    if (VCoptions.screen === 'screen') {
      setVCoptions({
        ...VCoptions,
        screen: undefined,
      });
    } else {
      setVCoptions({
        ...VCoptions,
        screen: 'screen',
      });
    }
  };

  const onError = (err: any) => {
    setVCoptions({
      ...VCoptions,
      error: `Failed to publish: ${err.message}`,
    });
  };

  const { video_session_token, video_session_uid } = activeService;

  if (!video_session_uid || !video_session_token) return null;

  return (
    <div id="video_chat">
      {/* @ts-ignore */}
      <OTSession
        apiKey={process.env.REACT_APP_OPENTOK_API_KEY || ''}
        sessionId={video_session_uid || ''}
        token={video_session_token || ''}
      >
        {VCoptions.error ? (
          <div className="error-message-styled">{VCoptions.error}</div>
        ) : null}
        {VCoptions.screen !== 'screen' ? (
          <div className="video-share-container">
            {/* @ts-ignore */}
            <OTPublisher
              properties={{
                publishAudio: VCoptions.audio,
                publishVideo: VCoptions.video,
                videoSource: undefined,
                showControls: false,
              }}
              onError={onError}
            />
          </div>
        ) : null}
        {VCoptions.screen === 'screen' ? (
          <>
            <div className="screen-share-container">
              {/* @ts-ignore */}
              <OTPublisher
                properties={{
                  publishAudio: VCoptions.audio,
                  publishVideo: VCoptions.video,
                  videoSource: 'screen',
                  showControls: false,
                }}
                onError={onError}
              />
            </div>
            <div
              style={{
                visibility: 'hidden',
                maxHeight: '1px',
                overflow: 'hidden',
              }}
            >
              {/* @ts-ignore */}
              <OTPublisher
                properties={{
                  publishAudio: VCoptions.audio,
                  videoSource: undefined,
                  showControls: false,
                }}
                onError={onError}
              />
            </div>
          </>
        ) : null}
        <div className="screen-subscriber-container">
          {/* @ts-ignore */}
          <OTStreams>
            {/* @ts-ignore */}
            <OTSubscriber
              properties={{
                subscribeToAudio: VCoptions.suscribeAudio,
              }}
            />
          </OTStreams>
        </div>
        <IonRow className="chat-banner ion-justify-content-center ion-align-items-center">
          <IonButton
            shape="round"
            color="secondary"
            className="ion-hide-lg-down"
            onClick={() => screenWindow()}
          >
            {VCoptions.screen === 'screen'
              ? t('share_camera')
              : t('share_screen')}
          </IonButton>
          <IonButton
            className="ion-no-padding  action-button"
            expand="block"
            fill={VCoptions.audio ? 'solid' : 'outline'}
            color={VCoptions.audio ? 'secondary' : 'tertiary'}
            shape="round"
            onClick={() => setAudio()}
          >
            <IonIcon
              slot="icon-only"
              icon={VCoptions.audio ? micOutline : micOffOutline}
            />
          </IonButton>
          <IonButton
            className="ion-no-padding  action-button"
            expand="block"
            fill={VCoptions.video ? 'solid' : 'outline'}
            color={VCoptions.video ? 'secondary' : 'tertiary'}
            shape="round"
            onClick={() => setVideo()}
          >
            <IonIcon
              slot="icon-only"
              icon={VCoptions.video ? videocamOutline : videocamOffOutline}
            />
          </IonButton>
        </IonRow>
      </OTSession>
    </div>
  );
};

export default VideoChat;
