/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import {
  IonRow,
  IonCol,
  IonText,
  IonButton,
  IonChip,
  IonIcon,
} from '@ionic/react';
import { checkmarkCircleOutline, alertCircleOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { LOADER } from '../../../constants/constants';
import useUpdateUser from '../../../hooks/useUpdateUser';
import { Userprofile, General } from '../../../interfaces';
import { getTimeZone } from '../../../services/expert';
import { AppContextState, AppContextDispatch } from '../../../state/AppContext';
import location from '../../../constants/location';
import 'react-phone-input-2/lib/style.css';

interface FormProps {
  first_name: string;
  last_name: string;
  time_zone: string;
  location: string;
  email: string;
  middle_name: string;
  mobile_contact_time: null;
  mobile_phone: string;
  office_phone: string;
  phone_contact_time: null;
  primary_mobile: null;
  primary_phone: string;
  secondary_mobile: null;
  secondary_phone: string;
  time_contact: string;
}

const ExpertContactInfo: React.FC = () => {
  const { t } = useTranslation();

  const contextState = useContext(AppContextState);
  const contextDispatch = useContext(AppContextDispatch);

  const { user_profile } = contextState.profile;

  const { status, updateClient } = useUpdateUser();
  const [newProfile, setNewProfile] = useState<Userprofile>({
    ...user_profile,
  });
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [editedSubmitInfo, setEditedSubmitInfo] = useState<Userprofile | undefined>(undefined);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormProps>();

  const dispatchLoader = (value: boolean) => {
    const payload: General = {
      loader: value,
    };
    contextDispatch({
      type: LOADER,
      payload,
    });
  };

  const requestUserTimeZone = async (event: any) => {
    dispatchLoader(true);
    const jwt = sessionStorage.getItem('jwt') || '';
    await getTimeZone(jwt, event.target.value)
      .then(({ data }) => {
        const newTimeZone = {
          id: data.id,
          continent: data.continent,
          name: data.name,
          utc_difference: data.utc_difference,
        };
        const newProf = { ...newProfile };
        if (newProf.contact_information) newProf.contact_information.time_zone = `UTC${data.utc_difference}`;
        newProf.time_zone = JSON.stringify(newTimeZone);
        newProf.timezone_id = data.id;
        setNewProfile(newProf);
        dispatchLoader(false);
      })
      .catch(() => {
        setNewProfile({
          ...newProfile,
          time_zone: undefined,
          timezone_id: undefined,
        });
        dispatchLoader(false);
      });
  };

  useEffect(() => {
    setNewProfile(user_profile);
    if (newProfile && newProfile.contact_information) {
      setValue(
        'first_name',
        newProfile.contact_information.first_name
          ? newProfile.contact_information.first_name
          : user_profile.first_name,
      );
      setValue(
        'last_name',
        newProfile.contact_information.last_name
          ? newProfile.last_name
          : user_profile.last_name,
      );
      setValue('time_zone', newProfile.contact_information.time_zone || '');
      setValue('location', newProfile.contact_information.location || '');
      setValue('email', newProfile.contact_information.email || '');
      setValue('middle_name', newProfile.contact_information.middle_name || '');
      setValue(
        'mobile_contact_time',
        newProfile.contact_information.mobile_contact_time || '',
      );
      setValue(
        'mobile_phone',
        newProfile.contact_information.mobile_phone || '',
      );
      setValue(
        'office_phone',
        newProfile.contact_information.office_phone || '',
      );
      setValue(
        'phone_contact_time',
        newProfile.contact_information.phone_contact_time || '',
      );
      setValue(
        'primary_mobile',
        newProfile.contact_information.primary_mobile || '',
      );
      setValue(
        'primary_phone',
        newProfile.contact_information.primary_phone || '',
      );
      setValue(
        'secondary_mobile',
        newProfile.contact_information.secondary_mobile || '',
      );
      setValue(
        'secondary_phone',
        newProfile.contact_information.secondary_phone || '',
      );
      setValue(
        'time_contact',
        newProfile.contact_information.time_contact || '',
      );
    }
  }, [user_profile]);

  const letUpdate = () => {
    if (!canUpdate) setCanUpdate(true);
  };

  const updateUserPhones = (
    phone: 'mobile_phone' | 'office_phone',
    number: string,
    country: any,
  ) => {
    const newState = { ...newProfile };
    if (newState.contact_information) {
      const countryCode = phone === 'mobile_phone' ? 'primary_phone' : 'secondary_phone';
      newState.contact_information[phone] = number;
      newState.contact_information[countryCode] = country.countryCode;
      setNewProfile({ ...newState });
    }
  };

  useEffect(() => {
    if (submit && status === undefined && editedSubmitInfo !== undefined) {
      dispatchLoader(true);
      setSuccess(false);
      setError(false);
      updateClient(editedSubmitInfo);
    }
    if (status !== undefined) {
      handleRequestResponse(status);
    }
  }, [submit, status, editedSubmitInfo]);

  const onSubmit = async (formData: FormProps) => {
    const newForm = formData;
    newForm.mobile_phone = newProfile.contact_information?.mobile_phone || formData.mobile_phone;
    newForm.office_phone = newProfile.contact_information?.office_phone || formData.office_phone;
    newForm.primary_phone = newProfile.contact_information?.primary_phone || formData.primary_phone;
    newForm.secondary_phone = newProfile.contact_information?.secondary_phone
      || formData.secondary_phone;
    newForm.time_zone = newProfile.contact_information?.time_zone || '';
    dispatchLoader(true);
    const editedProfile = { ...newProfile };
    editedProfile.contact_information = newForm;
    editedProfile.first_name = newForm.first_name;
    editedProfile.last_name = newForm.last_name;
    setEditedSubmitInfo(editedProfile);
    setSubmit(true);
    dispatchLoader(false);
  };

  const handleRequestResponse = (type: boolean) => {
    setError(type === false);
    setSuccess(type !== false);
    setCanUpdate(false);
    dispatchLoader(false);
    setSubmit(false);
    setEditedSubmitInfo(undefined);
    setTimeout(() => {
      setError(false);
      setSuccess(false);
    }, 6000);
  };

  return (
    <>
      <div className="section-title text-center ion-margin-bottom">
        <h2>{t('contactInformaction')}</h2>
        <span className="title-border" />
      </div>
      <form
        className="ion-margin-vertical"
        id="contact-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <IonRow>
          <IonCol size="12" sizeLg="4">
            <div className="form__group field">
              <input
                className={`form__field ${errors.first_name && 'invalid'}`}
                placeholder="first_name"
                type="text"
                id="first_name"
                {...register('first_name', { required: true })}
                onInput={() => letUpdate()}
              />
              <label htmlFor="first_name" className="form__label">
                {t('ciUsername')}
              </label>
              {errors.first_name && errors.first_name.type === 'required' && (
                <IonText color="danger">{t('required')}</IonText>
              )}
            </div>
          </IonCol>
          <IonCol size="12" sizeLg="4">
            <div className="form__group field">
              <input
                className={`form__field ${errors.last_name && 'invalid'}`}
                placeholder="last_name"
                type="text"
                id="last_name"
                {...register('last_name', { required: true })}
                onInput={() => letUpdate()}
              />
              <label htmlFor="last_name" className="form__label">
                {t('ciUserLast')}
              </label>
              {errors.last_name && errors.last_name.type === 'required' && (
                <IonText color="danger">{t('required')}</IonText>
              )}
            </div>
          </IonCol>
          <IonCol size="12" sizeLg="4">
            <div className="form__group field">
              <input
                className={`form__field ${errors.middle_name && 'invalid'}`}
                placeholder="middle_name"
                type="text"
                id="middle_name"
                {...register('middle_name', { required: true })}
                onInput={() => letUpdate()}
              />
              <label htmlFor="middle_name" className="form__label">
                {t('ciFullName')}
              </label>
              {errors.middle_name && errors.middle_name.type === 'required' && (
                <IonText color="danger">{t('required')}</IonText>
              )}
            </div>
          </IonCol>
          <IonCol size="12" sizeLg="4">
            <div className="form__group field">
              <input
                className={`form__field ${errors.email && 'invalid'}`}
                placeholder="email"
                type="email"
                id="email"
                {...register('email', { required: true })}
                onInput={() => letUpdate()}
              />
              <label htmlFor="email" className="form__label">
                {t('ciUserEmail')}
              </label>
              {errors.email && errors.email.type === 'required' && (
                <IonText color="danger">{t('required')}</IonText>
              )}
            </div>
          </IonCol>
          <IonCol size="12" sizeLg="4">
            <div className="form__group field">
              <select
                data-testid="test-CCI-timezone"
                className={`form__field ${errors.location && 'invalid'}`}
                id="location"
                {...register('location', { required: true })}
                onInput={() => letUpdate()}
                onChange={(e) => requestUserTimeZone(e)}
              >
                {location.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
              <label htmlFor="location" className="form__label">
                {t('ciTimeZone')}
              </label>
              {errors.location && errors.location.type === 'required' && (
                <IonText color="danger">{t('required')}</IonText>
              )}
            </div>
          </IonCol>
          <IonCol size="12" sizeLg="4">
            <div className="form__group field disabled_field">
              <input
                className={`form__field ${errors.time_zone && 'invalid'}`}
                placeholder="time_zone"
                type="text"
                id="time_zone"
                {...register('time_zone')}
                onInput={() => letUpdate()}
                value={newProfile.contact_information?.time_zone}
                disabled
                readOnly
              />
              <label htmlFor="time_zone" className="form__label">
                {t('ciTimeZone')}
              </label>
              {errors.time_zone && errors.time_zone.type === 'required' && (
                <IonText color="danger">{t('required')}</IonText>
              )}
            </div>
          </IonCol>
          <IonCol size="12" sizeLg="4">
            <div className="">
              <PhoneInput
                containerClass="react-tel-input"
                inputProps={{
                  id: 'mobile_phone',
                  style: { width: '100%' },
                }}
                enableAreaCodes
                enableAreaCodeStretch
                country={newProfile.contact_information?.secondary_phone || ''}
                autoFormat
                placeholder={t('ciOfficePhone')}
                value={newProfile.contact_information?.mobile_phone}
                onChange={(e, country) => {
                  updateUserPhones('mobile_phone', e, country);
                  letUpdate();
                }}
              />
              <label htmlFor="mobile_phone" className="form__label">
                {t('ciMobilePhone')}
              </label>
            </div>
          </IonCol>
          <IonCol size="12" sizeLg="4">
            <div className="">
              <PhoneInput
                containerClass="react-tel-input"
                inputProps={{
                  id: 'office_phone',
                  style: { width: '100%' },
                }}
                enableAreaCodes
                enableAreaCodeStretch
                country={newProfile.contact_information?.secondary_phone || ''}
                autoFormat
                placeholder={t('ciOfficePhone')}
                value={newProfile.contact_information?.office_phone}
                onChange={(e, country) => {
                  updateUserPhones('office_phone', e, country);
                  letUpdate();
                }}
              />
              <label htmlFor="office_phone" className="form__label">
                {t('ciOfficePhone')}
              </label>
            </div>
          </IonCol>
          <IonCol size="12" className="ion-no-padding">
            {canUpdate && (
              <IonButton
                data-testid="test-button-submit"
                shape="round"
                fill="outline"
                type="submit"
              >
                {t('ciSaveInfo')}
              </IonButton>
            )}
            {success && (
              <IonChip color="success ion-margin-top">
                <IonIcon
                  icon={checkmarkCircleOutline}
                  className="ion-margin-end"
                />
                {t('globalSuccess')}
              </IonChip>
            )}
            {error && (
              <IonChip color="warning ion-margin-top">
                <IonIcon icon={alertCircleOutline} className="ion-margin-end" />
                {t('globalError')}
              </IonChip>
            )}
          </IonCol>
        </IonRow>
      </form>
    </>
  );
};

export default ExpertContactInfo;
