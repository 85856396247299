import { useContext } from 'react';
import { LOADER } from '../constants/constants';
import { General } from '../interfaces';
import { AppContextDispatch } from '../state/AppContext';

const useLoader = () => {
  const contextDispatch = useContext(AppContextDispatch);

  const setLoader = (value: boolean) => {
    const payload: General = {
      loader: value,
    };
    contextDispatch({
      type: LOADER,
      payload,
    });
  };
  return { setLoader };
};

export default useLoader;
