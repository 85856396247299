import React from 'react';
import { IonContent } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const PrivacyModal: React.FC = () => {
  const { t } = useTranslation();
  return (
    <IonContent>
      <h3>{t('Privacy')}</h3>
      <div className="ion-padding" dangerouslySetInnerHTML={{ __html: t('PrivacyText') }} />
    </IonContent>
  );
};

export default PrivacyModal;
