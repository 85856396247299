/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { IonButton, IonText } from '@ionic/react';
import { Billinginfo, General, Userprofile } from '../../interfaces';
import { AppContextDispatch, AppContextState } from '../../state/AppContext';
import { countries } from '../../constants/countries';
import { updateUserProfile as updateClient } from '../../services/client';
import { updateUserProfile as updateExpert } from '../../services/expert';
import { LOADER, UPDATE_USER_PROFILE } from '../../constants/constants';

interface ComponentProps {
  setError: () => void;
  setSuccess: () => void;
}

const BillingForm: React.FC<ComponentProps> = ({ setError, setSuccess }) => {
  const { t } = useTranslation();
  const contextState = useContext(AppContextState);
  const contextDispatch = useContext(AppContextDispatch);
  const { user_profile } = contextState.profile;
  const { billing_info } = user_profile;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Billinginfo>();

  const dispatchLoader = (value: boolean) => {
    const payload: General = {
      loader: value,
    };
    contextDispatch({
      type: LOADER,
      payload,
    });
  };

  const dispatchProfile = (value: Userprofile) => {
    const payload: any = {
      profile: {
        user_profile: value,
      },
    };
    contextDispatch({
      type: UPDATE_USER_PROFILE,
      payload,
    });
  };

  const onSubmit = async (billingInformation: Billinginfo) => {
    dispatchLoader(true);
    const newBilling = { ...billing_info, ...billingInformation };
    const newUserData: Userprofile = { ...user_profile };
    newUserData.billing_info = newBilling;
    const jwtToken = sessionStorage.getItem('jwt') || '';
    if (newUserData.type === 'Expert') {
      await updateExpert(newUserData, jwtToken).then(({ data }) => {
        validateData(data, billingInformation);
      });
    } else {
      await updateClient(newUserData, jwtToken).then(({ data }) => {
        validateData(data, billingInformation);
      });
    }
  };

  const validateData = (response: any, values: Billinginfo) => {
    const { billing_info: billing } = response;
    delete billing.id;
    delete billing.created_at;
    delete billing.updated_at;
    delete billing.user_id;
    delete values.id;
    const validateChanges = isEquivalent(billing, values);
    dispatchLoader(false);
    if (validateChanges) {
      dispatchProfile(response);
      setSuccess();
    } else setError();
  };

  const isEquivalent = (a: Billinginfo, b: Billinginfo) => {
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
      return false;
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i] as keyof Billinginfo;
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (billing_info) {
      setValue('address_line1', billing_info.address_line1);
      setValue('address_line2', billing_info.address_line2);
      setValue('city', billing_info.city);
      setValue('country', billing_info.country);
      setValue('email', billing_info.email);
      setValue('name', billing_info.name);
      setValue('phone', billing_info.phone);
      setValue('rfc_nas', billing_info.rfc_nas);
      setValue('state', billing_info.state);
      setValue('zip_code', billing_info.zip_code);
      setValue('state', billing_info.state);
    }
  }, [billing_info]);

  return (
    <form id="billing-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form__group field">
        <input
          data-testid="test-input-name"
          className={`form__field ${errors.name && 'invalid'}`}
          placeholder="name"
          type="text"
          id="name"
          {...register('name', { required: true })}
        />
        <label htmlFor="name" className="form__label">
          {t('ciUserName')}
        </label>
        {errors.name && errors.name.type === 'required' && (
          <IonText color="danger">
            {t('required')}
          </IonText>
        )}
      </div>
      <div className="form__group field">
        <input
          className={`form__field ${errors.phone && 'invalid'}`}
          placeholder="phone"
          type="text"
          id="phone"
          {...register('phone', { required: true })}
        />
        <label htmlFor="phone" className="form__label">
          {t('ciPhone')}
        </label>
        {errors.phone && errors.phone.type === 'required' && (
          <IonText color="danger">
            {t('required')}
          </IonText>
        )}
      </div>
      <div className="form__group field">
        <input
          className={`form__field ${errors.email && 'invalid'}`}
          placeholder="email"
          type="text"
          id="email"
          {...register('email', {
            required: true,
            pattern: /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi,
          })}
        />
        <label htmlFor="email" className="form__label">
          {t('ciEmail')}
        </label>
        {errors.email && errors.email.type === 'required' && (
          <IonText color="danger">
            {t('required')}
          </IonText>
        )}
        {errors.email && errors.email.type === 'pattern' && (
          <IonText color="danger">
            {t('enterValidMail')}
          </IonText>
        )}
      </div>
      <div className="form__group field">
        <input
          className={`form__field ${errors.rfc_nas && 'invalid'}`}
          placeholder="rfc_nas"
          type="text"
          id="rfc_nas"
          {...register('rfc_nas', { required: true })}
        />
        <label htmlFor="rfc_nas" className="form__label">
          {t('ciUserNas')}
        </label>
        {errors.rfc_nas && errors.rfc_nas.type === 'required' && (
          <IonText color="danger">
            {t('required')}
          </IonText>
        )}
      </div>
      <div className="form__group field">
        <select
          className={`form__field ${errors.country && 'invalid'}`}
          id="country"
          {...register('country', { required: true })}
        >
          {countries.map((value) => (
            <option key={value.name} value={value.name}>
              {value.name}
            </option>
          ))}
        </select>
        <label htmlFor="country" className="form__label">
          {t('ciUserCountry')}
        </label>
        {errors.state && errors.state.type === 'required' && (
          <IonText color="danger">
            {t('required')}
          </IonText>
        )}
      </div>
      <div className="form__group field">
        <input
          className={`form__field ${errors.state && 'invalid'}`}
          placeholder="state"
          type="text"
          id="state"
          {...register('state', { required: true })}
        />
        <label htmlFor="state" className="form__label">
          {t('ciUserState')}
        </label>
        {errors.state && errors.state.type === 'required' && (
          <IonText color="danger">
            {t('required')}
          </IonText>
        )}
      </div>
      <div className="form__group field">
        <input
          className={`form__field ${errors.city && 'invalid'}`}
          placeholder="city"
          type="text"
          id="city"
          {...register('city', { required: true })}
        />
        <label htmlFor="city" className="form__label">
          {t('ciUserCity')}
        </label>
        {errors.city && errors.city.type === 'required' && (
          <IonText color="danger">
            {t('required')}
          </IonText>
        )}
      </div>
      <div className="form__group field">
        <input
          className={`form__field ${errors.address_line1 && 'invalid'}`}
          placeholder="address_line1"
          type="text"
          id="address_line1"
          {...register('address_line1', { required: true })}
        />
        <label htmlFor="address_line1" className="form__label">
          {t('ciUserAddress')}
        </label>
        {errors.address_line1 && errors.address_line1.type === 'required' && (
          <IonText color="danger">
            {t('required')}
          </IonText>
        )}
      </div>
      <div className="form__group field">
        <input
          className="form__field"
          placeholder="address_line2"
          type="text"
          id="address_line2"
          {...register('address_line2')}
        />
        <label htmlFor="address_line2" className="form__label">
          {t('ciUserAddress2')}
        </label>
      </div>
      <div className="form__group field">
        <input
          className={`form__field ${errors.zip_code && 'invalid'}`}
          placeholder="zip_code"
          type="text"
          id="zip_code"
          {...register('zip_code', { required: true })}
        />
        <label htmlFor="zip_code" className="form__label">
          {t('ciUserZipCode')}
        </label>
        {errors.zip_code && errors.zip_code.type === 'required' && (
          <IonText color="danger">
            {t('required')}
          </IonText>
        )}
      </div>
      <IonButton
        data-testid="test-button-submit"
        className="ion-margin-vertical"
        shape="round"
        fill="outline"
        color="secondary"
        type="submit"
      >
        {t('Update')}
      </IonButton>
    </form>
  );
};

export default BillingForm;
