/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import {
  IonCard,
  IonCardHeader,
  IonButton,
  IonIcon,
  IonCardContent,
  IonCol,
  IonButtons,
  IonCardSubtitle,
  IonCardTitle,
  IonRow,
  IonAlert,
  IonText,
} from '@ionic/react';
import {
  createOutline, trashOutline,
} from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../../../components/modalWrapper/ModalWrapper';
import useUpdateUser from '../../../hooks/useUpdateUser';
import { AppContextState } from '../../../state/AppContext';
import SkillsModal from './SkillsModal';

const Skills: React.FC = () => {
  const { t } = useTranslation();

  const { updateClient } = useUpdateUser();

  const contextState = useContext(AppContextState);
  const { profile, industries, functions } = contextState;
  const { user_profile } = profile;
  const { skills } = user_profile;

  const [showModal, setshowModal] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<string | undefined>(undefined);
  const [skillEdition, setSkillEdition] = useState<number | undefined>(undefined);

  /* istanbul ignore next */
  const closeModal = () => {
    setshowModal(false);
    setSkillEdition(undefined);
  };

  const editSkillFunction = (event: any) => {
    setSkillEdition(event.currentTarget['data-skill'] + 1);
    setshowModal(true);
  };

  /* istanbul ignore next */
  const deleteSkillFunction = (position: string) => {
    const newProfile = { ...user_profile };
    const newSkills = [...newProfile.skills || []];
    newSkills.splice(parseInt(position, 10), 1);
    newProfile.skills = newSkills;
    updateClient(newProfile);
  };

  const renderSkils = () => {
    if (skills) {
      return skills.map((skill, index) => (
        <IonCol size="12" sizeMd="6" sizeLg="4" key={index}>
          <IonCard className="ion-no-margin">
            <IonCardHeader className="ion-no-padding">
              <IonCardSubtitle>
                <IonButtons className="ion-justify-content-end ion-no-padding">
                  <IonButton
                    data-skill={index}
                    data-testid={`test-edit-${index}`}
                    onClick={(event) => editSkillFunction(event)}
                  >
                    <IonIcon slot="icon-only" icon={createOutline} />
                  </IonButton>
                  <IonButton
                    data-testid={`test-delete-${index}`}
                    onClick={() => setShowAlert(index.toString())}
                  >
                    <IonIcon slot="icon-only" icon={trashOutline} />
                  </IonButton>
                </IonButtons>
              </IonCardSubtitle>
              <IonCardTitle color="secondary" className="ion-padding-horizontal ion-no-margin ion-margin-bottom">
                {industries?.find(
                  (industrie) => industrie.id === parseInt(skill.industry, 10),
                )?.name}
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              {skill.function.map((userFunction, pos) => {
                const object = functions?.find(
                  (o) => o.id === parseInt(userFunction.job_functions, 10),
                );
                return (
                  <div key={`${object?.id}-${pos}`}>
                    <IonText color="primary">
                      <h2>{object?.name}</h2>
                    </IonText>
                    {userFunction.skills.map((userSkill, ind) => (
                      <IonText key={`${userSkill}-${ind}`}>
                        {object?.skills.find((o) => o.id === parseInt(userSkill, 10))?.name}
                        <br />
                      </IonText>
                    ))}
                  </div>
                );
              })}
            </IonCardContent>
          </IonCard>
        </IonCol>
      ));
    }
    return (
      <div>
        <p>{t('expertSkills')}</p>
      </div>
    );
  };

  return (
    <>
      <div className="section-title text-center">
        <h2>{t('profileFunctions')}</h2>
        <span className="title-border" />
      </div>
      <IonCard className="skillsCard ion-no-margin">
        <IonCardHeader style={{ textAlign: 'right', paddingBottom: '0' }}>
          <IonButton
            data-testid="test-modal-button"
            onClick={() => setshowModal(true)}
            fill="clear"
            shape="round"
          >
            <IonIcon slot="icon-only" icon={createOutline} />
          </IonButton>
        </IonCardHeader>
        <IonCardContent>
          <IonRow>
            {renderSkils()}
          </IonRow>
        </IonCardContent>
      </IonCard>
      <IonAlert
        isOpen={!!showAlert}
        onDidDismiss={() => setShowAlert(undefined)}
        mode="ios"
        header={t('confirm_delete')}
        message={t('confirm_delete_body')}
        buttons={[
          {
            text: t('cancel'),
            role: 'cancel',
            handler: () => setShowAlert(undefined),
          },
          {
            text: t('accept'),
            cssClass: 'secondary',
            handler: () => deleteSkillFunction(showAlert || ''),
          },
        ]}
      />
      {showModal && (
        <ModalWrapper wrapperClass="bigModal" isOpen={showModal} setIsOpen={() => closeModal()}>
          <SkillsModal edition={skillEdition} showModal={closeModal} />
        </ModalWrapper>
      )}
    </>
  );
};

export default Skills;
