import {
  LOADER,
  BILLING,
  TAXES,
  CLIENT_FIND_DATA,
  SET_HISTORY_SEARCH,
  LOGIN,
  LOGOUT,
  LOGIN_TYPE,
  GET_USER_PROFILE,
  UPDATE_USER_PROFILE,
  UPDATE_INDUSTRIES,
  UPDATE_FUNCTIONS,
  UPDATE_LANGUAGES,
  UPDATE_SENIORITY,
  UPDATE_SERVICES,
  UPDATE_CLIENTCOMMUNITYRESULTS,
  UPDATE_ALLAPPOINTMENTS,
  UPDATE_CLIENTPAYMENTS,
  UPDATE_BALANCES,
  UPDATE_COMMENTSANDRATES,
  UPDATE_EXPERTURLSTRIPE,
  UPDATE_SEARCHSKILLSARRAY,
} from '../constants/constants';
import { ReducerAction, State } from '../interfaces';

const reducers = (state: State, action: ReducerAction<any>): State => {
  switch (action.type) {
    case LOADER:
      return { ...state, general: action.payload };

    case LOGIN:
      return {
        ...state,
        login: { ...action.payload },
      };

    case LOGOUT:
      return { ...state, login: { ...state.login, login: action.payload } };

    case LOGIN_TYPE:
      return {
        ...state,
        login: { ...state.login, login_type: action.payload.login_type },
      };

    case BILLING:
      return { ...state, billing: action.payload };

    case TAXES:
      return { ...state, taxes: action.payload };

    case CLIENT_FIND_DATA:
      return {
        ...state,
        client: {
          ...state.client,
          client_find_data: action.payload.client_find_data,
        },
      };

    case SET_HISTORY_SEARCH:
      return {
        ...state,
        client: {
          ...state.client,
          set_history_search: action.payload,
        },
      };

    case GET_USER_PROFILE:
      return { ...state, profile: action.payload };

    case UPDATE_USER_PROFILE:
      return { ...state, profile: action.payload.profile };

    case UPDATE_INDUSTRIES:
      return { ...state, industries: action.payload.industries };

    case UPDATE_SENIORITY:
      return { ...state, seniority: action.payload.seniority };

    case UPDATE_FUNCTIONS:
      return { ...state, functions: action.payload.functions };

    case UPDATE_LANGUAGES:
      return { ...state, languages: action.payload.languages };

    case UPDATE_SERVICES:
      return { ...state, services: action.payload.services };

    case UPDATE_CLIENTCOMMUNITYRESULTS:
      return { ...state, clientCommunityResults: action.payload.clientCommunityResults };

    case UPDATE_ALLAPPOINTMENTS:
      return { ...state, allAppointments: action.payload.allAppointments };

    case UPDATE_CLIENTPAYMENTS:
      return { ...state, clientPayments: action.payload.clientPayments };

    case UPDATE_BALANCES:
      return { ...state, balances: action.payload.balances };

    case UPDATE_COMMENTSANDRATES:
      return { ...state, commentsAndRates: action.payload.commentsAndRates };

    case UPDATE_EXPERTURLSTRIPE:
      return { ...state, expertUrlStripe: action.payload.expertUrlStripe };

    case UPDATE_SEARCHSKILLSARRAY:
      return { ...state, searchSkillsArray: action.payload.searchSkillsArray };

    default:
      return state;
  }
};

export default reducers;
