import React from 'react';
// Services
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// Components
import NewCheckoutForm from './NewCheckoutForm';

interface ComponentProps {
  // eslint-disable-next-line no-unused-vars
  callBack: (token: string) => void;
}

const StripeContainer: React.FC<ComponentProps> = ({ callBack }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

  return (
    <Elements stripe={stripePromise}>
      <NewCheckoutForm
        clientStripe={(data) => callBack(data.stripeToken)}
      />
    </Elements>
  );
};

export default StripeContainer;
