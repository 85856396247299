/* eslint-disable camelcase */
import {
  IonCard,
  IonCardHeader,
  IonButton,
  IonIcon,
  IonCardContent,
  IonRow,
  IonAlert,
  IonCol,
  IonButtons,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';
import { createOutline, trashOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../../../components/modalWrapper/ModalWrapper';
import useUpdateUser from '../../../hooks/useUpdateUser';
import { Careerhighlight } from '../../../interfaces/clientInterfaces';
import { AppContextState } from '../../../state/AppContext';
import HighLightsModal from './HighLightsModal';

const HighLights: React.FC = () => {
  const { t } = useTranslation();
  const contextState = useContext(AppContextState);
  const { user_profile } = contextState.profile;
  const { career_highlights } = user_profile;

  const { updateClient } = useUpdateUser();

  const [showModal, setshowModal] = useState<boolean>(false);
  const [highlightEdition, setHighlightEdition] = useState<number | undefined>(undefined);
  const [showAlert, setShowAlert] = useState<string | undefined>(undefined);

  /* istanbul ignore next */
  const closeModal = () => {
    setshowModal(false);
    setHighlightEdition(undefined);
  };

  const editHighlightFunction = (position: number) => {
    setHighlightEdition(position);
    setshowModal(true);
  };

  /* istanbul ignore next */
  const deleteHighlight = (position: string) => {
    const newProfile = { ...user_profile };
    let newHighLights: Careerhighlight[] = [];
    if (newProfile.career_highlights) newHighLights = [...newProfile.career_highlights];
    newHighLights.splice(parseInt(position, 10), 1);
    newProfile.career_highlights = newHighLights;
    updateClient(newProfile);
  };

  const renderHighLights = () => {
    if (career_highlights && career_highlights.length > 0) {
      return career_highlights.map((element, index) => (
        <IonCol size="12" sizeLg="4" key={element.date_from}>
          <IonCard className="card careerHighlights ion-no-margin">
            <IonCardHeader className="ion-no-padding">
              <IonCardSubtitle>
                <IonButtons className="ion-justify-content-end ion-no-padding">
                  <IonButton
                    data-testid={`test-edit-${index}`}
                    onClick={() => editHighlightFunction(index)}
                  >
                    <IonIcon slot="icon-only" icon={createOutline} />
                  </IonButton>
                  <IonButton
                    data-testid={`test-delete-${index}`}
                    onClick={() => setShowAlert(index.toString())}
                  >
                    <IonIcon slot="icon-only" icon={trashOutline} />
                  </IonButton>
                </IonButtons>
              </IonCardSubtitle>
              <IonCardTitle className="ion-padding-horizontal ion-no-margin">
                {element.career_header}
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <p>{element.career_description}</p>
            </IonCardContent>
          </IonCard>
        </IonCol>
      ));
    }
    return (
      <div className="col-12">
        <p>{t('expertCH')}</p>
      </div>
    );
  };

  return (
    <>
      <div className="section-title text-center">
        <h2>{t('profileHighlights')}</h2>
        <span className="title-border" />
      </div>
      <IonCard className="ion-no-margin">
        <IonCardHeader style={{ textAlign: 'right', paddingBottom: '0' }}>
          <IonButton
            data-testid="test-lang-show-modal"
            onClick={() => setshowModal(true)}
            fill="clear"
            shape="round"
          >
            <IonIcon slot="icon-only" icon={createOutline} />
          </IonButton>
        </IonCardHeader>
        <IonCardContent>
          <IonRow>{renderHighLights()}</IonRow>
        </IonCardContent>
      </IonCard>
      <IonAlert
        isOpen={!!showAlert}
        onDidDismiss={() => setShowAlert(undefined)}
        mode="ios"
        header={t('confirm_delete')}
        message={t('confirm_delete_body')}
        buttons={[
          {
            text: t('cancel'),
            role: 'cancel',
            handler: () => setShowAlert(undefined),
          },
          {
            text: t('accept'),
            cssClass: 'secondary',
            handler: () => deleteHighlight(showAlert || ''),
          },
        ]}
      />
      {showModal && (
        <ModalWrapper isOpen={showModal} setIsOpen={() => closeModal()}>
          <HighLightsModal edition={highlightEdition} showModal={closeModal} />
        </ModalWrapper>
      )}
    </>
  );
};

export default HighLights;
