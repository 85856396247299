/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  IonButton,
  IonRow,
  IonText,
  IonCol,
} from '@ionic/react';

type FormData = {
  coachType: string;
  searchCoaching: string;
  coachChanges: string;
  coachAchives: string;
};

interface ComponentProps {
  // eslint-disable-next-line no-unused-vars
  response: (form: FormData) => void;
}

const CoachingQuestions: React.FC<ComponentProps> = ({ response }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  return (
    <form onSubmit={handleSubmit(response)} style={{ width: '100%' }}>
      <IonRow>
        <IonCol size="12">
          <h5>{t('coachQT1')}</h5>
          <div className="form__group field">
            <select
              className={`form__field ${errors.coachType && 'invalid'}`}
              id="coachType"
              {...register('coachType', { required: true })}
            >
              <option>{t('coachQT1-4')}</option>
              <option>{t('coachQT1-5')}</option>
              <option>{t('coachQT1-6')}</option>
              <option>{t('coachQT1-7')}</option>
              <option>{t('coachQT1-8')}</option>
              <option>{t('coachQT1-9')}</option>
              <option>{t('coachQT1-10')}</option>
              <option>{t('coachQT1-11')}</option>
              <option>{t('coachQT1-12')}</option>
              <option>{t('coachQT1-13')}</option>
              <option>{t('coachQT1-14')}</option>
            </select>
          </div>
          {errors.coachType && (
            <IonText color="danger">{t('required')}</IonText>
          )}
        </IonCol>
        <IonCol size="12">
          <h5>{t('coachQT2')}</h5>
          <div className="form__group field">
            <textarea
              className={`form__field ${errors.searchCoaching && 'invalid'}`}
              id="searchCoaching"
              rows={3}
              maxLength={1500}
              {...register('searchCoaching', { required: true })}
              placeholder={t('coachQT2_placeholder')}
            />
            {errors.searchCoaching && (
              <IonText color="danger">{t('required')}</IonText>
            )}
          </div>
        </IonCol>
        <IonCol size="12">
          <h5>{t('coachQT3')}</h5>
          <div className="form__group field">
            <textarea
              className={`form__field ${errors.coachChanges && 'invalid'}`}
              id="coachChanges"
              rows={3}
              maxLength={1500}
              {...register('coachChanges', { required: true })}
              placeholder={t('coachQT3_placeholder')}
            />
            {errors.coachChanges && (
              <IonText color="danger">{t('required')}</IonText>
            )}
          </div>
        </IonCol>
        <IonCol size="12">
          <h5>{t('coachQT4')}</h5>
          <div className="form__group field">
            <textarea
              className={`form__field ${errors.coachAchives && 'invalid'}`}
              id="coachAchives"
              rows={3}
              maxLength={1500}
              placeholder={t('coachQT4_placeholder')}
              {...register('coachAchives', { required: true })}
            />
            {errors.coachAchives && (
              <IonText color="danger">{t('required')}</IonText>
            )}
          </div>
        </IonCol>
        <IonButton
          shape="round"
          fill="outline"
          color="secondary"
          type="submit"
          expand="block"
        >
          {t('Continue')}
        </IonButton>
      </IonRow>
    </form>
  );
};

export default CoachingQuestions;
