import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Categories } from '../interfaces/strapiInterfaces';

const useBlogFilter = (categories: Categories[]) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState('');

  const FilterUI = () => (
    <IonSegment
      scrollable
      data-testid="test-segments-container"
      color="secondary"
      value={selected}
      onIonChange={(e) => setSelected(e.detail.value || '')}
    >
      <IonSegmentButton data-testid="test-segments-button-Z" value="">
        <IonLabel>{t('blog_2')}</IonLabel>
      </IonSegmentButton>
      {categories.map((optionFilter, index) => (
        <IonSegmentButton data-testid={`test-segments-button-${index}`} value={optionFilter.name} key={optionFilter.id}>
          <IonLabel>{optionFilter.name}</IonLabel>
        </IonSegmentButton>
      ))}
    </IonSegment>
  );

  return { FilterUI, selected };
};

export default useBlogFilter;
